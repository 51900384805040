import headerHomeCare from '../../assets/img/home-care/header.jpeg';
import headerPatient from '../../assets/img/patients/header.jpeg';
import headerProf from '../../assets/img/professionals/header.jpeg';
import headerDriver from '../../assets/img/drivers/header.png';
import logoHomeCare from '../../assets/img/home-care/logo.png';
import logoProf from '../../assets/img/professionals/logo.png';

export const images: Images = {
  professional: {
    logo: logoProf,
    header: headerProf,
  },
  patient: {
    logo: logoProf,
    header: headerPatient,
  },
  home_care: {
    logo: logoHomeCare,
    header: headerHomeCare,
  },
  driver: {
    logo: logoProf,
    header: headerDriver,
  },
}

export const texts: Text = {
  professional: {
    title: 'label.hello',
    welcome: 'confirm.professionals.welcome',
    readyAccountText: 'confirm.professionals.readyAccount.text',
    readyAccountPass: 'confirm.readyAccount.pass',
    readyAccountStore: 'confirm.readyAccount.store',
    readyAccountBy: 'confirm.readyAccount.by',
  },
  patient: {
    title: 'label.hello',
    welcome: 'confirm.patients.welcome',
    readyAccount: 'confirm.readyAccount',
    readyAccountText: 'confirm.patients.readyAccount.text',
    readyAccountPass: 'confirm.readyAccount.pass',
    readyAccountStore: 'confirm.readyAccount.store',
    readyAccountBy: 'confirm.readyAccount.by',
  },
  home_care: {
    title: 'label.hello',
    welcome: 'confirm.homeCare.welcome',
    readyAccount: 'confirm.readyAccount',
    readyAccountText: 'confirm.homeCare.readyAccount.text',
    readyAccountPass: 'confirm.readyAccount.pass',
    readyAccountStore: 'confirm.readyAccount.store',
    readyAccountBy: 'confirm.readyAccount.by',
  },
  driver: {
    title: 'label.hello',
    welcome: 'confirm.driver.welcome',
    readyAccountText: 'confirm.homeCare.readyAccount.text',
    readyAccountPass: 'confirm.readyAccount.pass',
    readyAccountStore: 'confirm.readyAccount.store',
    readyAccountBy: 'confirm.readyAccount.by',
  },
}


export const storeLinks: StoreLinks = {
  professional: {
    appstore: 'https://apps.apple.com/ar/app/home-medical-management/id1522565212',
    googleplay: 'https://play.google.com/store/apps/details?id=com.itlg.hmm&hl=es_AR&gl=US',
  },
  patient: {
    appstore: 'https://apps.apple.com/ar/app/homecare/id6478124863',
    googleplay: 'https://play.google.com/store/apps/details?id=com.homecareplus&hl=es_AR&gl=US',
  },
  home_care: {
    appstore: 'https://apps.apple.com/ar/app/homecare/id6478124863',
    googleplay: 'https://play.google.com/store/apps/details?id=com.homecareplus&hl=es_AR&gl=US',
  },
  driver: {
    appstore: 'https://apps.apple.com/ar/app/home-medical-management/id1522565212',
    googleplay: 'https://play.google.com/store/apps/details?id=com.hmmmobilityapp&hl=es_419',
  },
}

export interface StoreLinksData {
  appstore: string;
  googleplay: string;
}

interface StoreLinks {
  [key: string]: StoreLinksData;
}

interface Images {
  [key: string]: ImagesData;
}

export interface ImagesData {
  logo: string;
  header: string;
}

interface Text {
  [key: string]: TextsData;
}

export interface TextsData {
  title: string;
  welcome: string;
  readyAccount?: string;
  readyAccountText: string;
  readyAccountPass: string;
  readyAccountStore: string;
  readyAccountBy: string;
}

export enum UserType {
  PROFESSIONAL = 'professional',
  PATIENT = 'patient',
  HOME_CARE = 'home_care',
  DRIVER = 'driver',
}
