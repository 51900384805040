import React, { useState } from 'react'
import { Tooltip, makeStyles } from '@material-ui/core'
import Button from '../CustomButtons/Button'
import { InfoOutlined } from '@material-ui/icons'
import InfoScheduleItemModal from '../NewSchedule/ScheduleDetail/InfoScheduleItemModal'
import PropTypes from 'prop-types'

type Observations = {
  key: number
  label: string
  practice?: string
}
type DataProps = {
  titleModal: string
  label: string
  tooltip: string
  observations: Observations[]
}

interface ComponentProps {
  showInfo: boolean
  data: DataProps
  disableTextWrap?: boolean // Nueva prop opcional para manejar el wrap
}

const useStyles = makeStyles({
  labelWithInfo: {
    textWrap: 'nowrap',
    '& span button.MuiButtonBase-root': {
      padding: '2px 5px 5px',
      width: 'auto',
      height: 'auto',
      minWidth: 20,
    },
  },
  normalTextWrap: {
    textWrap: 'normal',
    wordWrap: 'break-word',
  },
})

const LabelWidthInfo = ({
  data,
  showInfo,
  disableTextWrap,
}: ComponentProps) => {
  const classes = useStyles()

  const [openModal, setOpenModal] = useState<boolean>(false)

  const onCloseInfoModal = (isOpen: boolean) => {
    setOpenModal(isOpen)
  }

  const icon = showInfo ? (
    <Tooltip title={data.tooltip}>
      <span>
        <Button
          simple
          justIcon
          color="warning"
          onClick={() => setOpenModal(true)}
        >
          <InfoOutlined />
        </Button>
      </span>
    </Tooltip>
  ) : (
    ''
  )

  // Condiciona la clase según la prop `disableTextWrap`
  const className = disableTextWrap
    ? classes.normalTextWrap
    : classes.labelWithInfo

  return (
    <>
      <span className={className}>
        <span className="label">{data.label}</span> {icon}
      </span>
      {openModal && (
        <InfoScheduleItemModal
          data={data.observations}
          open={openModal}
          onCloseModal={(isOpen) => onCloseInfoModal(isOpen)}
          title={data.titleModal}
        />
      )}
    </>
  )
}

LabelWidthInfo.propTypes = {
  showInfo: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  disableTextWrap: PropTypes.bool, // Nueva prop definida en PropTypes
}

export default LabelWidthInfo
