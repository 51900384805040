import './DayPicker.css';

import React, { Component } from 'react';

import Button from '../CustomButtons/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class DayPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            days: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
            selected: [],
            originalSelected: [],
        }
    }

    componentDidMount() {
        const { value } = this.props;
        if (value) {
            this.setState({
                originalSelected: value,
                selected: value,
            }, () => {
                value.map((v) => this.markAsSelected(v));
            });
        }
    }

    getSelectedDays(day) {
        const { selected } = this.state;

        let selectedDays = [...selected, day];
        const isDayExist = selected.includes(day);
        if (isDayExist) {
            selectedDays = selected.filter((v) => v !== day);
        }
        return selectedDays;
    }

    checkCountDaysAndError(day) {
        const { t, appointmentAction, checkCounterDays, insideModule } = this.props;
        const { originalSelected } = this.state;

        const isCheckCountDays = appointmentAction === 'assign_agenda' || insideModule || checkCounterDays;
        if (isCheckCountDays) {
            const numberOriginalDays = originalSelected.length;
            const selectedDays = this.getSelectedDays(day);
            const numberSelectedDays = selectedDays.length;

            if (numberSelectedDays > numberOriginalDays) {
                return false;
            }
            if (numberSelectedDays < numberOriginalDays) {
                const errorCountday = t('dayPicker.errorCountday', { countDays: numberOriginalDays });
                this.setState({ invalidCountDay: true, errorCountday });
                this.props.setInvalidNumDays(true)
                return true;
            }
            if (numberOriginalDays === numberSelectedDays) {
                this.setState({ invalidCountDay: false, errorCountday: '' });
                this.props.setInvalidNumDays(false)
                return true;
            }
        }
        return true;
    }

    selectedDay(day) {
        const isValidQuantity = this.checkCountDaysAndError(day);
        if (!isValidQuantity) {
            return;
        }

        const selectedDays = this.getSelectedDays(day);
        this.setState({ selected: selectedDays }, () => {
            this.filterValue(selectedDays);
            this.markAsSelected(day);
        });
    }

    markAsSelected(value) {
        const { indexArrayDays } = this.props;
        const getDayByClassName = document.getElementsByClassName(`day-${value}`)[indexArrayDays];
        if (!getDayByClassName) {
            return;
        }
        const isChecked  = getDayByClassName?.classList.contains('checked');
        if (isChecked) {
            getDayByClassName.classList.remove('checked');
            return;
        }
        getDayByClassName.classList.add('checked');
    }

    filterValue(selected) {
        const { invalidCountDay } = this.state;
        this.setState({ selected }, () => {
            this.props.onChange(selected);
            this.props.invalidSelection(invalidCountDay || false);
        });
    }

    renderDays(days) {
        const { t } = this.props;

        return days.map(d => (
            <Button
                simple
                className={`day-picker-item day-check day-${d}`}
                key={`day-check-${d}`}
                id={`day-check-${d}`}
                onClick={() => this.selectedDay(d)}
            >{t(`dayShort.${d}`)}</Button>
        ));
    }

    render() {
        const { label, invalid, errorText } = this.props;
        const { days, errorCountday, invalidCountDay } = this.state;
        return (
            <GridContainer>
                <GridItem className="day-picker" xs={12}>
                    {label &&
                        <GridItem xs={3} className="no-padding">
                            <span className="picker-label">{label}</span>
                        </GridItem>
                    }
                    <GridItem xs={9}>
                        {days &&
                            <div>{this.renderDays(days)}</div>
                        }
                        {invalid &&
                            <FormHelperText className="helper-error">{errorText}</FormHelperText>
                        }
                        {invalidCountDay &&
                            <FormHelperText className="helper-error">{errorCountday}</FormHelperText>
                        }
                    </GridItem>
                </GridItem>
            </GridContainer>
        )
    }
}

DayPicker.propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.array,
    label: PropTypes.string,
    invalidSelection: PropTypes.func,
    appointmentAction: PropTypes.string,
    checkCounterDays: PropTypes.bool,
    insideModule: PropTypes.bool,
    indexArrayDays: PropTypes.number,
    invalid: PropTypes.bool,
    errorText: PropTypes.string,
    setInvalidNumDays: PropTypes.func,
}

export default withTranslation()(DayPicker);
