import './Modules.css';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React, { Component } from 'react';

import Button from '../../../../CustomButtons/Button';
import CustomDialog from '../../../../CustomDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import PropTypes from 'prop-types';
import SchedulePlan from '../../../../SchedulePlan';
import Snackbar from '../../../../Snackbar/Snackbar';
import TimePickerGCalendar from '../../../../TimePickerGCalendar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ModalFileUpload from '../Plan/ModalFileUpload';

class ModalConfigurePractices extends Component {

    constructor(props) {
        super(props);

        const { t } = this.props;
        const typesState = [{
            id: 1,
            value: t('Practice'),
        }, {
            id: 2,
            value: t('Package'),
        }];

        this.state = {
            open: false,
            types: typesState,
            type: '',
            module: '',
            component: null,
            current: null,
            action: '',
            suggested: [],
            practices: [],
            shifts: [1, 2, 3],
            alertMessageColor: '',
            alertMessageMsg: '',
            alertMessageOpen: false,
            observations: '',
        }
    }

    componentDidMount() {
        this.fillSuggested();
    }

    componentWillReceiveProps(next) {
        if (next.practices) {
            this.setState({ practices: next.practices });
        }
        this.setState({ open: next.open });
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            component: null,
            current: null,
        })
        this.props.onCloseModal();
    }

    handleValue(index, value, state, shift, packageDetailId) {
        const { practices } = this.state;
        if (state === 'recurrence') {
            practices[index]['schedulePlan']['weekRecurrence'] = value.weekRecurrence;
            practices[index]['schedulePlan']['monthRecurrence'] = value.monthRecurrence;
            practices[index]['schedulePlan']['monthDay'] = value.monthRecurrence;
            this.setState({ practices });
            return;
        }
        console.log("index" + index);
        practices[index][state] = value;
        console.log(practices[index]);
        this.setState({ [state]: value, practices });
    }

    handleValueFiles(index, value) {
        const { practices } = this.state;
        practices[index]["file"] = value;
        console.log(practices[index]);
        this.setState({ practices });
    }

    fillSuggested = () => {
        const { module } = this.props;
        if (this.state.suggested.length === 0) {
            let sa = [];
            module.packageDetail.map(m => {
                let shifts = this.fillArray(m);
                shifts.forEach(t => {
                    let row = {};
                    row['packageDetailId'] = m.packageDetailId;
                    row['practiceTypeId'] = m.practiceType.practiceTypeId;
                    let detail = this.findSuggestedFrontItems(m.practiceType.practiceTypeId, t, shifts.length !== 1);
                    // AQUI
                    if (detail) {
                        row['suggestedEmployeeId'] = detail.suggestedEmployeeId;
                        row['filerByZone'] = detail.filerByZone;
                    } else {
                        row['suggestedEmployeeId'] = null;
                        row['filerByZone'] = true;
                    }
                    row['order'] = t;
                    sa.push(row);
                });
            });
            this.setState({ suggested: sa });
        }
    }

    findSuggestedFrontItems = (practice, shift, hasShift) => {
        let item;
        if (this.props.itemRequestDetails) {
            this.props.itemRequestDetails.map(m => {
                if (!hasShift) {
                    if (m.practiceTypeId === practice) {
                        item = m;
                    }
                } else {
                    if (m.practiceTypeId === practice && m.order === shift) {
                        item = m;
                    }
                }
            });
        }
        return item;
    }

    fillArray = (item) => {
        const estimatedDuration = item.practiceType.estimatedDuration;
        const processingScheduleType = item.schedulePlan.processingScheduleType;
        const processingDurationTime = item.schedulePlan.processingDurationTime;
        const tur = processingDurationTime / estimatedDuration;

        const ret = [];
        if (processingScheduleType === 'INTRADAY') {
            for (let idx = 0; idx < tur; idx++) {
                ret.push(idx + 1);
            }
        } else {
            ret.push(1);
        }
        return ret;
    }

    setInvalidPlan = (i, value) => {
    }

    isInvalidate = () => {
        let daysOk = false;
        if (this.state.practices.length == 0) {
            return false;
        }
        this.state.practices.forEach(m => {
            if (m.schedulePlan.weekRecurrence && m.schedulePlan.originaSelected) {
                const weekRecurrence = Array.isArray(m.schedulePlan.weekRecurrence) ?
                    m.schedulePlan.weekRecurrence : m.schedulePlan.weekRecurrence.split(',')
                if ((weekRecurrence.length != m.schedulePlan.originaSelected.split(',').length)) {
                    daysOk = true;
                }

            }
        });
        return daysOk;
    }

    handleChangeExpaned = (i) => {
        const { practices } = this.state;
        practices[i].expanded = !practices[i].expanded;
        this.setState({ practices });
    }

    saveModule = () => {
        const { t } = this.props;

        for (let idx = 0; idx < this.state.practices.length; idx++) {
            const m = this.state.practices[idx];
            if (m.startTime == '' || m.endTime == '' || 
                (m.schedulePlan.recurrenceType === 'MONTH' && !m.schedulePlan.monthRecurrence) ||
                (m.schedulePlan.recurrenceType === 'MONTH' && m.schedulePlan.monthRecurrence < 1) ||
                (m.schedulePlan.recurrenceType === 'MONTH' && m.schedulePlan.monthRecurrence > 31) 
                ) {
                this.openAlert('warning', t('appointment.new.requiredFields')); 
                return;
            }
        }
        this.props.onConfirmModal(this.state.practices);
    }

    openAlert = (color, message) => {
        this.setState({
          alertMessageColor: color,
          alertMessageMsg: message,
          alertMessageOpen: true,
        });

        setTimeout(() => {
          this.setState({ alertMessageOpen: false });
        }, 5000);
    }

    formatPractices() {
        const { t } = this.props;
        const { practices } = this.state;
        let index = -1;
        let indexArray = -1;
        return practices.map((item, i) => {

            let appointmentAction = '';
            const recurrence = item.schedulePlan;
            const selectedDuration = item.practiceType.estimatedDuration;

            if (recurrence.processingScheduleType === 'INTRADAY') {
                recurrence.recurrenceType = 'INTRADAY';
                recurrence.quantityIntraday = 1440 / selectedDuration;

            }
            if (recurrence.processingScheduleType === 'INTRADAY' || recurrence.recurrenceType === 'WEEK') {
                recurrence.originaSelected = item.schedulePlan.weekDays;
                index = index + 1;
            }
            const dates = {};
            const savePlan = false;
            const startTime = item.startTime;
            const endTime = item.endTime;
            const endDate = '';
            const practice_start_time_name = "practice_start_time_" + i;
            const practice_end_time_name = "practice_end_time_" + i;
            const estimatedDuration = `${moment.utc(moment.duration(parseInt(selectedDuration), 'minutes').asMilliseconds()).format('HH:mm')} ${t('estimated_minutes')}`;
            const observations = item.observations;
            const file = item.file;
            let files = this.state.practices.filter(e => e.file != null);
            let disabledFile = false;
            if (files.length > 0) {
                console.log("compare " + files[0].packageDetailId + " " + item.packageDetailId);
                if (files[0].packageDetailId != item.packageDetailId) {
                    disabledFile = true;
                }
            }
            console.log("console log" + i);
            console.log(item.packageDetailId);
            indexArray++;

            return (
                <GridContainer className="ps-child practices modules-form assign-professionals-form" key={item?.practiceId || index+1}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Accordion expanded={item.expanded} onChange={value => this.handleChangeExpaned(i)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography><span className="practice-title-acordion">{item.practiceType.name}</span></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <GridContainer>
                                    <GridItem xs={12} className="base-font availability-label">
                                        <p className="padding-horizontal practice-time-estimated-gray">{t('estimated_label')}: <span className="practice-time-estimated-black">{estimatedDuration}</span></p>
                                    </GridItem>

                                    <GridItem xs={12} className="base-font availability-label">
                                        <h4 className="padding-horizontal">{t('appointment.new.schedule.practice.availability')}</h4>
                                    </GridItem>

                                    <GridItem xs={6} className="start-time modal-config">
                                        <TimePickerGCalendar
                                            name={practice_start_time_name}
                                            text={t('appointment.new.schedule.practice.start_time')}
                                            value={startTime}
                                            onSelectedValue={(value) => this.handleValue(i, value, 'startTime')}
                                            defaultValue="12:00 am"
                                            invalid={!startTime}
                                            errorText={!startTime ? t('error.required') : ''}
                                        />
                                    </GridItem>

                                    <GridItem xs={6} className="end-time modal-config">
                                        <TimePickerGCalendar
                                            name={practice_end_time_name}
                                            text={t('appointment.new.schedule.practice.end_time')}
                                            value={endTime}
                                            onSelectedValue={(value) => this.handleValue(i, value, 'endTime')}
                                            defaultValue="11:30 pm"
                                            invalid={!endTime}
                                            errorText={!endTime ? t('error.required') : ''}
                                        />
                                    </GridItem>
                                    <GridItem xs={6} className="end-time modal-config">
                                    <SchedulePlan
                                        className="schedule-plan-component"
                                        appointmentAction={appointmentAction}
                                        practiceDuration={selectedDuration}
                                        current={recurrence}
                                        observations={observations}
                                        endDate={endDate}
                                        dates={dates}
                                        fullWidth={true}
                                        indexArrayDays={index}
                                        insideModule={true}
                                        onCreatedRecurrence={(value) => this.handleValue(i, value, 'recurrence')}
                                        onCreatedObservations={(value) => this.handleValue(i, value, 'observations')}
                                        savePlan={savePlan}
                                        invalidPlan={(value) => this.setInvalidPlan(i, value)}
                                    />
                                    </GridItem>
                                    {indexArray == 0 &&
                                        <GridItem xs={6} className="end-time modal-config">
                                            <ModalFileUpload
                                                fullWidth={true}
                                                onSelectedValue={(value) => this.handleValueFiles(0, value, 'file')}
                                                file={file}
                                                endDate={endDate}
                                            />
                                        </GridItem>
                                    }
                                </GridContainer>
                                
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                </GridContainer>
            );
        })
    }

    render() {
        const { t, module } = this.props;
        const { alertMessageColor, alertMessageMsg, alertMessageOpen, open } = this.state;
        return (
            <CustomDialog
                className="dialog-module-configure-practices"
                classTitle2="dialog-title-h3"
                maxWidth="md"
                title={t('module.configure.practices.title')}
                open={open}
                onClose={() => this.closeModal()}
            >
                <Snackbar
                    place="tr"
                    color={alertMessageColor}
                    message={alertMessageMsg}
                    open={alertMessageOpen}
                />
                <GridContainer className="ps-child modules-form assign-professionals-form">
                    <GridItem xs={12}>
                        <span className="practice-title-acordion">{module?.name ?? ''}</span>
                    </GridItem>
                    {this.formatPractices()}
                    <GridItem className="schedule-form-submit" xs={12}>
                      
                            <Button id="button-employees" color="primary" simple onClick={() => this.props.onSuggestedProfesionals()}>
                                {t('suggested.assignProfessionals')}
                            </Button>
                        <Button id="button-save" color="primary" disabled={this.isInvalidate()} onClick={() => this.saveModule()}>
                            {t('configure.practiceType.save')}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CustomDialog>
        )
    }
}

ModalConfigurePractices.defaultProps = {
    isOwnLender: false,
}

ModalConfigurePractices.propTypes = {
    t: PropTypes.func,
    onSuggestedProfesionals: PropTypes.func,
    onConfirmModal: PropTypes.func,
    module: PropTypes.object,
    onCloseModal: PropTypes.func,
    isOwnLender: PropTypes.bool,
    itemRequestDetails: PropTypes.array,
}

export default withTranslation()(ModalConfigurePractices);
