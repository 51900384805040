import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import HotelIcon from '@material-ui/icons/Hotel';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import CardBody from '../../Card/CardBody';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import SelectInput from '../../SelectInput';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import '../NewSchedule.css';

class CustomerForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customers: [],
            customer: '',
            customerId: '',
            addresses: [],
            address: '',
            zone: '',
            location: '',
            province: '',
        }
    }

    componentDidMount() {
        this.getCustomers();
        const { value } = this.props;
        if (value) {
            this.handleValue(value.customer, 'customer');
            this.handleValue(value.address, 'address');
        }
    }

    componentWillReceiveProps(next) {
        const { value } = this.props;
        const { customers } = this.state;
        
        if (value?.customer && customers) {
            const customerIndex = customers.findIndex(item => item.id === value.customer);
            
            if (customerIndex !== -1) {
                const customerId = customers[customerIndex].customerId;
                this.setState({ customerId });
            }
        }
    }

    formatCustomers = (customers) => customers.map(c => ({
        id: c.personId,
        value: `${c.lastName} ${c.firstName}`,
        customerId: c.id,
    }));

    formatAddresses = (addresses) => addresses.map(a => ({
        addressName: addresses,
        id: a.addressId,
        value: `${a.street} ${a.number}`,
        zone: a.geographicZone ? a.geographicZone.detail : '',
        geographicZoneId: a.geographicZone  ? a.geographicZone.geographicZoneId : 0,
        location: a.location.name,
        province: a.provinceName,
    }));

    getCustomers() {
       
        PersonApiInvoker.getCustomersActive(true, data => {
            const customers = this.formatCustomers(data);
            this.setState({ customers })
            this.props.onGetCustomers(data)
        }, _ => {
            this.setState({ loading: false })
        })
    }

    propsOnFilledCustomer() {
        const { address, customer, customerId, geographicZoneId, location, province, zone,addressName } = this.state;
        
        this.props.onFilledCustomer({
            customer,
            customerId,
            address,
            zone,
            geographicZoneId,
            location,
            province,
            addressName,
        });
    }

    setAddress() {
        const { addresses } = this.state;
        let address = {
            address: '',
            zone: '',
            location: '',
            province: '',
            geographicZoneId: '',
        };
        if (addresses.length === 1) {
            const selectedAddress = addresses[0];
            address = {
                address: selectedAddress.id,
                zone: selectedAddress.zone,
                location: selectedAddress.location,
                province: selectedAddress.province,
                geographicZoneId: selectedAddress.geographicZoneId,
            };
        } else if (addresses.length > 1) {
            const defaultAddress = addresses.find(item => 
                item.addressName.some(addr => addr.addressDefault) // Busca en el array addressName
            );
            if (defaultAddress) {
                const addressDetails = defaultAddress.addressName.find(addr => addr.addressDefault);
                address = {
                    address: addressDetails.addressId,
                    zone: defaultAddress.zone,
                    location: defaultAddress.location,
                    province: defaultAddress.province,
                    geographicZoneId: defaultAddress.geographicZoneId,
                };
            }
        }  
        this.setState({
            address: address.address,
            zone: address.zone,
            location: address.location,
            province: address.province,
            geographicZoneId: address.geographicZoneId,
        }, () => { this.propsOnFilledCustomer() });
    }

    getAddresses(personId) {
        PersonApiInvoker.getPersonsAddress(personId,false, data => {
            
            if (data?.length) {
                this.setState({ addresses: this.formatAddresses(data)}, () => {
                    this.setAddress();
                });
            } else {
                this.setState({
                    addresses: [],
                    address: '',
                    zone: '',
                    location: '',
                    province: '',
                    geographicZoneId: '',
                }, () => {   
                    this.propsOnFilledCustomer();
                });
            }
        }, null);
    }

    handleValue(value, state) {
        this.setState({ [state]: value });
        const { addresses, customer, customerId, customers } = this.state;
        if (state === 'customer') {
            this.getAddresses(value);
            const customerIndex = customers.findIndex(item => item.id === value);
            if (customerIndex !== -1) {
                this.setState({ customerId: customers[customerIndex].customerId }, () => {
                        this.props.onFilledCustomer({ customer, customerId });
                    }
                )
            }
        }
        if (state === 'address') {
            const addressIndex = addresses.findIndex(item => item.id === value)
            if (addressIndex !== -1) {
                this.setState({
                    address: addresses[addressIndex].id,
                    geographicZoneId: addresses[addressIndex].geographicZoneId,
                    zone: addresses[addressIndex].zone || '',
                    location: addresses[addressIndex].location || '',
                    province: addresses[addressIndex].province || '',
                }, () => {
                    this.propsOnFilledCustomer();
                });
            }
        }
    }

    render() {
        const { customers, customer, customerId, addresses, address, zone, location, province, address_name } = this.state;
        const { disabled, checkInvalid, t } = this.props;
       
        return (
            <Card id="card-customer" className="card-customer-form">
                <CardHeader icon>
                    <CardIcon color="primary">
                        <HotelIcon />
                    </CardIcon>
                    <h3 className="card-icon-title">{t('appointment.customer')}</h3>
                </CardHeader>
                <CardBody>
                    <GridContainer className="card-customer-container">
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <SelectInput
                                id="select-customer"
                                disabled={disabled}
                                label={t('appointment.new.customer.customer')}
                                elements={customers}
                                onSelectedValue={value => this.handleValue(value, 'customer')}
                                value={customer}
                                invalid={!customer && checkInvalid}
                                errorText={!customer && checkInvalid ? t('error.required') : ''}
                            />
                        </GridItem>
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <TextField
                                id="input-id"
                                disabled
                                fullWidth
                                label={t('appointment.new.customer.id')}
                                value={(customer && customerId) || ''}
                            />
                        </GridItem>
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <SelectInput
                                id="select-address"
                                fullWidth
                                disabled={disabled}
                                label={t('appointment.new.customer.address')}
                                elements={addresses}
                                onSelectedValue={value => this.handleValue(value, 'address')}
                                value={customer && address}
                                invalid={!address && checkInvalid}
                                errorText={!address && checkInvalid ? t('error.required') : ''}
                            />
                        </GridItem>
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <TextField
                                id="input-zone"
                                disabled
                                fullWidth
                                label={t('appointment.new.customer.zone')}
                                value={((customer && address) && zone) || ''}
                            />
                        </GridItem>
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <TextField
                                id="input-location"
                                disabled
                                fullWidth
                                label={t('appointment.new.customer.location')}
                                value={((customer && address) && location) || ''}
                            />
                        </GridItem>
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <TextField
                                id="input-province"
                                disabled
                                fullWidth
                                label={t('appointment.new.customer.province')}
                                value={((customer && address) && province) || ''}
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        )
    }
}

CustomerForm.propTypes = {
    t: PropTypes.func,
    onGetCustomers: PropTypes.func,
    onFilledCustomer: PropTypes.func,
    value: PropTypes.object,
    disabled: PropTypes.bool,
    checkInvalid: PropTypes.bool,
}

export default withTranslation()(CustomerForm);
