import { AppointmentItem, ProcessingScheduleType, RecurrenceType } from "../domain";

import { ApiAppointmentsItemsFromMap } from "./api-appointments-items.from-map";

export class ApiAppointmentsFromMapper {
  fromMap(map: ApiAppointmentsItemsFromMap): AppointmentItem {
    const availableSchedule = new AppointmentItem({
      companyId: map.companyId,
      name: map.name,
      practiceTypeId: map.practiceTypeId,
      packageId: map.packageId,
      employeeTypeId: map.employeeTypeId,
      estimatedDuration: map.estimatedDuration,
      every: map.every,
      recurrenceType: map.recurrenceType as RecurrenceType,
      weekDays: map.weekDays,
      monthDay: map.monthDay,
      processingScheduleType: map.processingScheduleType as ProcessingScheduleType,
      quantityIntraday: map.quantityIntraday,
      practiceStatus: map.practiceStatus,
      partnershipName: map.partnershipName,
      partnership: map.partnership,
      observations: map.observations,
      idItemRequest: map.idItemRequest

    });
    return availableSchedule;
  }
}
