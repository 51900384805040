import { Theme, makeStyles } from '@material-ui/core';

import { primaryColor } from '../../../../assets/components/material-dashboard-pro-react';

export const useStyles = makeStyles((theme: Theme) => ({
  contentProgress: {
    marginTop: 10,
    width: '100%',
    maxWidth: 500,
  },
  label: {
    color: '#555',
    fontSize: '1rem',
    paddingLeft: 5,
  },
  customLinearProgress: {
    marginTop: theme.spacing(1),
    marginBottom: 40,
    width: '100%',
    borderRadius: 5,
    borderRight: `2px solid ${primaryColor[0]}`,
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: 'rgba(93, 196, 185, 0.3)',
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: primaryColor[0],
      borderRadius: 5,
      borderRight: '3px solid #eee',
    },
  },
}));
