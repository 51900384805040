import { Control, UseFormRegisterReturn } from 'react-hook-form'
import { DetailInfo, Question } from '../../interfaces/interface'

import NumberRating from '../Rating/NumberRating'
import RadioInputWithHookForm from '../RadioInputSurv/RadioInputWithHookForm'
import React from 'react'
import TextareaInputWithHookForm from '../TextField/TextareaInputWithHookForm'

interface Option {
  id: string
  label: string
}

interface InputFactoryProps {
  type: 'rating' | 'radio' | 'textarea'
  control: Control<any>
  registerResponse: UseFormRegisterReturn<string>
  question: Question
  clarification?: string
  options?: Option[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  detailInfo?: DetailInfo
}

const InputFactory: React.FC<InputFactoryProps> = ({
  type,
  control,
  registerResponse,
  question,
  clarification,
  options = [],
  detailInfo,
  onChange,
  ...rest
}) => {
  const inputMap = {
    rating: (
      <div>
        <NumberRating
          control={control}
          fieldName={registerResponse.name}
          {...rest}
        />
      </div>
    ),
    radio: (
      <div>
        <RadioInputWithHookForm
          control={control}
          fieldName={registerResponse.name}
          options={options}
          detailInfo={detailInfo}
          {...rest}
        />
      </div>
    ),
    textarea: (
      <div>
        <TextareaInputWithHookForm
          control={control}
          fieldName={registerResponse.name}
          text={question?.question}
          maxLength={question?.maxLength}
          {...rest}
        />
      </div>
    ),
  }

  return (
    <div style={{ marginBottom: '1.5rem', textAlign: 'center' }}>
      <label style={{ fontSize: '1rem', display: 'block', marginBottom: '0.5rem' }}>
        {question?.question}
      </label>
      {inputMap[type]}
      {clarification && (
        <p style={{ fontSize: '0.875rem', color: '#666', marginTop: '0.5rem' }}>
          {clarification}
        </p>
      )}
    </div>
  )
}

export default InputFactory
