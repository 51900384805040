import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import PinBlack from '@material-ui/icons/LocationOn';
import CustomDialog from '../../CustomDialog';
import Table from '../../Table/Table';
import config from '../../../config/config';
import DashboardApiInvoker from '../../../api/DashboardApiInvoker';

const dateFormat = config.getDateTimeSmallFormat();

const PopupSchedules = (props) => {
  const [dataTable, setDataTable] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const sortDate = (a, b) => {
    const a1 = moment(a, dateFormat).valueOf();
    const b1 = moment(b, dateFormat).valueOf();
    if (a1<b1) {
      return -1;
    }
    if (a1>b1) {
      return 1;
    }
    return 0;                 
  }

  const getDataTable = async (employeeId) => {
      setDataLoading(true);
      setDataTable([]);
      DashboardApiInvoker.postEmployeeDashboardSchedule({
          dateFrom: props.dateFrom,
          dateTo: props.dateTo,
          employeeId,
      }, (data) => {
          setDataLoading(false);
          formatDataTable(data)
      }, (err) => {
          setDataLoading(false);
          console.error(err);
      });
  }

  const formatDataTable = (data) => {
    let result = [];
    if (data && data.length>0) {
      const { t } = props;
      result = data.map(s => {
        return {
          appoinmentId: s.appoinmentId,
          scheduleId: s.scheduleId,
          scheduleDateTime: moment(s.scheduleDateTime).format(dateFormat),
          customer: `${s.customer.lastName} ${s.customer.firstName}`,
          speciality: s.practiceTypeGroupName,
          practice: s.practiceTypeName,
          scheduleStatus: t(`schedule.booking.state.${s.scheduleStatus}`),
          duration: s.scheduleActualDuration,
          punctuality: s.puntuality,
          companyQR: (!s.companyQR ? '-' : s.verifyQR ? 'S' : 'N'),
          verificated: s.patientIdentityChecks ? 'S' : 'N',
          verifyLocation: (s.verifyLocation ? 'S' : 'N'),
          address:
              <IconButton disabled={s.scheduleStatus !== 'DONE'} aria-label="pin" onClick={() => props.openMap(s)}>
                <PinBlack style={{ color: "#e91e63" }} />
            </IconButton>
        }
      });
      setDataTable(result);
    }
  }

  const close = () => {
    props.closePopup('openPopupSchedule');
  }

  useEffect(() => {
    if (props.open) {
      getDataTable(props.employeeId)
    }
  }, [props.open])
  
  const { t, open } = props;
  return (
    <CustomDialog
      maxWidth="lg"
      open={open}
      title={t('dashboard.table.visits')}
      onClose={() => close()}
    >
      <Table
        loading={dataLoading}
        tableHeaderColor="primary"
        sortable
        tableHead={[
            { Header: t('dashboard.table.visits.appoinment_id'), accessor: 'appoinmentId' },
            { Header: t('dashboard.table.visits.schedule_id'), accessor: 'scheduleId' },
            { Header: t('dashboard.table.visits.schedule_date_time'), accessor: 'scheduleDateTime', sortMethod: (a, b) => sortDate(a, b) },
            { Header: t('dashboard.table.visits.customer'), accessor: 'customer' },
            { Header: t('dashboard.table.visits.speciality'), accessor: 'speciality' },
            { Header: t('dashboard.table.visits.practice'), accessor: 'practice' },
            { Header: t('dashboard.table.visits.schedule_status'), accessor: 'scheduleStatus' },
            { Header: t('dashboard.table.visits.duration'), accessor: 'duration' },
            { Header: t('dashboard.table.visits.puntuality'), accessor: 'punctuality' },
            { Header: t('dashboard.table.visits.validation_qr'), accessor: 'companyQR' },
            { Header: t('dashboard.table.visits.addressverificated'), accessor: 'verifyLocation' },
            { Header: t('dashboard.table.visits.address'), accessor: 'address' },
        ]}
        tableData={dataTable}
        colorsColls={["primary"]}
    />
  </CustomDialog>
  );
};

PopupSchedules.propTypes = {
  t: PropTypes.func,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  closePopup: PropTypes.func,
  openMap: PropTypes.func,
  employeeId: PropTypes.number,
  open: PropTypes.bool,
}

export default PopupSchedules;
