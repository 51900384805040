import React from 'react'
import { Control, Controller } from 'react-hook-form'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from '../../../Grid/GridItem'
import customCheckboxRadioSwitch from './styles'
import TextareaInputWithHookForm from '../TextField/TextareaInputWithHookForm'
import { DetailInfo } from '../../interfaces/interface'

interface Option {
  id: string
  label: string
  disabled?: boolean
}

interface RadioInputWithHookFormProps {
  control: Control<any>
  fieldName: string
  options: Option[]
  label?: string
  disabled?: boolean
  detailInfo?: DetailInfo
}

const useStyles = makeStyles((theme) => customCheckboxRadioSwitch(theme))

const RadioInputWithHookForm: React.FC<RadioInputWithHookFormProps> = ({
  control,
  fieldName,
  options,
  label,
  disabled = false,
  detailInfo,
}) => {
  const classes = useStyles()

  return (
    <GridItem xs={12}>
      <FormControl component="fieldset">
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <Controller
          name={fieldName}
          control={control}
          render={({ field }) => (
            <>
              <RadioGroup
                {...field}
                value={field.value || ''}
                className={classes.radioGroup}
              >
                {options.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    label={option.label}
                    control={
                      <Radio
                        disabled={disabled || option.disabled}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                          disabled: classes.disabledCheckboxAndRadio,
                        }}
                      />
                    }
                  />
                ))}
              </RadioGroup>

              {field.value === 'yes' && detailInfo && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1rem',
                  }}
                >
                  <TextareaInputWithHookForm
                    control={control}
                    fieldName={detailInfo.fieldName}
                    text={detailInfo.question}
                    clarification={detailInfo.clarification}
                    maxLength={detailInfo?.maxLength}
                  />
                </div>
              )}
            </>
          )}
        />
      </FormControl>
    </GridItem>
  )
}

export default RadioInputWithHookForm
