import { Card, CardBody, CardFooter } from '../Card';
import {
    FilledInput,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import Button from '../CustomButtons/Button';
import Cookies from 'universal-cookie';
import CustomSweetAlert from '../CustomSweetAlert';
import { Key } from '../../icons';
import { PasswordValidator } from '../../handlers/password-validator';
import { PinInput } from 'react-input-pin-code';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';
import { browserHistory } from 'react-router';
import hmmLogo from '../../assets/img/login/logo-hmm.png';
import { isEqual, method } from 'lodash';
import pwdBy from '../../assets/img/glin-white.png';
import { withTranslation } from 'react-i18next';

const langMap = {"es": "es-ES", "en": "en-US", "pt": "pt-BR", "it": "it-IT", "fr": "fr-FR"}


const useStyles = makeStyles(() => ({
    validatePassList: {
        color: '#3C4858',
        fontSize: '0.875rem',
        lineHeight: 1.2,
        opacity: .7,
        paddingLeft: 12,
        '& li': {
            marginBottom: 8,
        },
    },
    forgotPass: {
        '& .auth-user-card': {
            background: 'white',
            '&.forgot-pass-card': {
                '& .card-title': {
                    marginBottom: 30,
                    textAlign: 'center',
                },
                '& .helper-text': {
                    marginTop: 15,
                    textAlign: 'center',
                },
            },
            '&.pass-card .card-title': {
                marginBottom: 15,
            },
            '& .card-title': {
                fontSize: '1rem',
            },
        },
    },
    cardBodyPin: {
        '& > div': {
            justifyContent: 'center',
        }
    }
}));

const initialValidPass = {
    isNewValid: true,
    isRepeatValid: true,
}

const ForgotPassword = (props) => {
    console.log("entro");
    const classes = useStyles();
    const { t, location } = props;
    const [hashParamters, setHashParameters] = useState(null);
    const [hashJson, setHashJson] = useState(null);

    let username,companyId,hash,openNewPass;
    if (location.state) {
        username = location.state.username;
        companyId = location.state.companyId;
        openNewPass = false;
    } else if (props.params.hash) {
        hash = props.params.hash;
        let params = {
            method: "POST",
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({hash: hash})
        }
        if (hashParamters == null) {
            fetch('/get/hash', params)
            .then(response => response.json())
            .then(response => {
                console.log(response);
                setHashParameters(hash);
                if (response.companyId != 0) {
                  console.log("aca" + companyId);
                  setHashJson(response);
              } else {
                openAlert('danger', t('restorePassword.error.link'));
                setTimeout(() => {
                    browserHistory.push('/login');
                }, 1500);
              }
              
            })
            .catch(error => {
              console.log(error);
            });
            openNewPass = true;
        }
        
    }


    const [pinValues, setPinValues] = useState(['', '', '', '']);
    const [code, setCode] = useState({
        isValid: true,
        messageInvalid: '',
        validated: '',
    });
    const [disabledNewCode, setDisabledNewCode] = useState(false);
    const [password, setPassword] = useState({
        isMatchPasswords: true,
        isSubmit: false,
        openNewPass: openNewPass,
        repeatValue: '',
        showRepeatValue: false,
        showValue: false,
        value: '',
    });
    const [validPassword, setValidPassword] = useState(initialValidPass);
    const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        color: 'success',
        message: '',
        open: false,
    });

    const getTimeoutNewCode = () => setTimeout(() => {
        setDisabledNewCode(false);
    }, 30000);

    useEffect(() => {
        setDisabledNewCode(true);
        /*
        if (!(location?.state)) {
            browserHistory.push('/login');
        }
            */
        getTimeoutNewCode();
        return(() => {
            setDisabledNewCode(false);
            setPinValues(['', '', '', '']);
            setCode({ isValid: true, messageInvalid: '', validated: '' });
        })
    }, []);

    const openAlert = (color, message) => {
        setAlert((prev) => ({ ...prev, color, message, open: true }));
        setTimeout(() => {
            setAlert((prev) => ({ ...prev, open: false }));
        }, 5000);
    }

    const isNum = (value) => {
        if (value === '') {
          return false;
        }
        return !isNaN(value)
    }

    const totalItems = pinValues.filter(item => isNum(item)).length;

    const getLangByCookies = () => {
		const cookies = new Cookies();
        let lang = cookies.get("lang");
		if (lang == null) {
			return langMap['es']
		} else {
			return langMap[lang] == null ? langMap['es']: langMap[lang];
		}
	}

    const getFetchParams = (method) => ({
        method,
        headers: new Headers({
            'Content-Type': 'application/json',
            'x-itlg-companyId': hashParamters == null ? companyId : hashJson.companyId,
            'Accept-Language': getLangByCookies(),
        }),
    });

    const handleValidatePin = async () => {
        const codePin = pinValues.join('');
        try {
            const params = getFetchParams('GET');
            console.log(params);
            const response = await fetch(`/keychange/validate/${codePin}/${username}`, params);
            if (response.status === 200) {
                setPassword((prev) => ({ ...prev, openNewPass: true }));
                setCode((prev) => ({ ...prev, validated: codePin }));
                return;
            } 
            if (response.status === 404) {
                const messageInvalid = t('forgotPass.invalidPin');
                setCode((prev) => ({ ...prev, messageInvalid, isValid: false }));
                return;
            }
            if (response.status === 409) {
                const messageInvalid = t('forgotPass.expiredPin');
                setCode((prev) => ({ ...prev, messageInvalid, isValid: false }));
                return;
            }
            // errors
            console.error('** error keychange code');
            setCode((prev) => ({ ...prev, messageInvalid: '', isValid: true }));
            setPinValues(['', '', '', '']);
            return;
        } catch (e) {
            console.error('** error keychange');
            openAlert('danger', t('common.errorService'));
            setPinValues(['', '', '', '']);
        }
    }

    const onAcceptSendNewCode = async () => {
        setShowAlert(false);
        try {
            const params = getFetchParams('POST');
            params['body'] = JSON.stringify({});

            const response = await fetch(`/keychange/${username}`, params);
            if (response.status === 200) {
                setDisabledNewCode(true);
                openAlert('success', t('forgotPass.alertMessageConfirm'));
                setPinValues(['', '', '', '']);
                setCode({ validated: '', messageInvalid: '', isValid: true });
                getTimeoutNewCode();
                return;
            }
        } catch (_) {
            console.error('** error keychange');
            openAlert('danger', t('common.errorService'));
        }
        setPinValues(['', '', '', '']);
    }
    
    const renderPinForm = () => {
        const borderColorInputs = code.isValid ? 'rgb(204,204,204)' : 'rgb(220,53,69)';
        return (
            <form
                className="auth-user-form"
                id="pin-form"
                onSubmit={handleValidatePin}
                onKeyDown={({key}) => key === 'Enter' && handleValidatePin()}
            >
                <Card className="auth-user-card forgot-pass-card pin-code-inputs">
                    <CardBody className={classes.cardBodyPin}>
                        <h4 className="card-title">{t('forgotPass.title')}</h4>
                        <PinInput
                            values={pinValues}
                            onChange={(value, index, values) => {
                                setPinValues(values);
                                setCode((prev) => ({ ...prev, messageInvalid: '', isValid: true }));
                            }}
                            placeholder=""
                            showState={code.isValid}
                            borderColor={borderColorInputs}
                            className="pin-inputs"
                            errorBorderColor="rgb(234, 87, 87)"
                            focusBorderColor="rgb(133, 79, 203)"
                            validBorderColor="rgb(76, 175, 80)"
                        />
                        <FormHelperText error={!code.isValid} className="helper-text">
                            {code.isValid ?
                                t('forgotPass.description', { email: username }):
                                code.messageInvalid
                            }
                        </FormHelperText>
                    </CardBody>
                    <CardFooter>
                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    id="button-validate"
                                    onClick={handleValidatePin}
                                    className="submit-button"
                                    variant="contained"
                                    fullWidth={true}
                                    disabled={totalItems < 4}
                                >
                                    {t('forgotPass.validate')}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="link-content">
                                    <Button
                                        id="button-code"
                                        simple
                                        className="link-button"
                                        onClick={() => setShowAlert(true)}
                                        disabled={disabledNewCode}
                                    >{t('forgotPass.noCode')}</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
            </form>
        )
    }

    const handleMatchPass = () => {
        const isValues = !!(password.value) && !!(password.repeatValue);
        if (isValues) {
            const isMatchPasswords = isEqual(password.value, password.repeatValue);
            setPassword((prev) => ({ ...prev, isMatchPasswords }));
            return;
        }
        setPassword((prev) => ({ ...prev, isMatchPasswords: true }));
    }

    const handlePassValue = (value, state) => {
        setPassword((prev) => ({ ...prev, [state]: value }));
    }

    const getErrorMessageSubmit = (response) => {
        let message =  t('common.errorService');
        if (response.status === 400) {
            message = t('validatePass.error');
        }
        if (response.status === 409) {
            message = t('forgotPass.expiredCode');
        }
        return message;
    }

    const handleSubmitPass = async () => {
        setPassword((prev) => ({ ...prev, isSubmit: true }));
        if (password.value && password.repeatValue) {
            if (!password.isMatchPasswords) {
                openAlert('warning', t('restorePassword.notMatchPass'));
                return;
            }
            try {

                const params = getFetchParams('GET');
                let response
                console.log(hash);
                if (hashParamters == null) {
                    
                    response = await fetch(`/keychange/change/${code.validated}/${username}/${encodeURIComponent(password.value)}`, params);
                } else {
                    response = await fetch(`/keychange/change/v2/${hash}/${encodeURIComponent(password.value)}`, params);
                }
                if (response.status !== 200) {
                    const message = getErrorMessageSubmit(response);
                    openAlert('danger', message);
                    return;
                }
                openAlert('success', t('restorePassword.success'));
                setTimeout(() => {
                    browserHistory.push('/login');
                }, 1500);
            } catch (e) {
                console.error('** error keychange change');
                openAlert('danger', t('common.errorService'));
            }
            return;
        }
        if (!password.value || !password.repeatValue) {
            openAlert('warning', t('common.messageWarning.fieldsComplete'));
        }
    }

    const checkInputsValidity = () => {
        const { repeatValue, value } = password;
        if (!value || !repeatValue) {
            return setIsDisabledSubmit(true);
        }
        const isNewPasswordValid = PasswordValidator(value);
        const isValidConfirmPass = PasswordValidator(repeatValue);
        const isDisabledSubmit = !(isNewPasswordValid && isValidConfirmPass);

        setValidPassword((prev) => ({ ...prev, isNewValid: isNewPasswordValid, isRepeatValid: isValidConfirmPass }));
        setIsDisabledSubmit(isDisabledSubmit);
        handleMatchPass();
    }
    
    const renderPassForm = () => {
        return (
            <form
                className="auth-user-form"
                id="pass-form"
                onSubmit={handleSubmitPass}
                onKeyDown={({key}) => key === 'Enter' && handleSubmitPass()}
            >
                <Card className="auth-user-card pass-card">
                    <CardBody>
                        <h4 className="card-title">{t('restorePassword.title')}</h4>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl variant="filled">
                                    <FilledInput
                                        id="password"
                                        inputProps={{
                                            placeholder: t('restorePassword.newPass')
                                        }}
                                        type={!password.showValue ? 'password' : 'text'}
                                        startAdornment={<InputAdornment position="start"><Key /></InputAdornment>}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setPassword((prev) => ({ ...prev, showValue : !password.showValue }))}
                                                    edge="end"
                                                >
                                                    {password.showValue ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={(event) => handlePassValue(event.target.value, 'value')}
                                        value={password.value}
                                        error={!password.value && password.isSubmit}
                                        onKeyUp={checkInputsValidity}
                                    />
                                </FormControl>
                                {(!validPassword.isNewValid && !!(password.value)) &&
                                    <FormHelperText error className="helper-text">
                                        {t('validatePass.error')}
                                    </FormHelperText>
                                }
                                <div className="helper-text">
                                    <ul className={classes.validatePassList}>
                                        <li>{t('validatePass.isCaseSensitive')}</li>
                                        <li>{t('validatePass.text1')}</li>
                                        <li>{t('validatePass.text2')}</li>
                                        <li>{t('validatePass.text3')}</li>
                                        <li>{t('validatePass.text4')}</li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="filled">
                                    <FilledInput
                                        id="repeatPassword"
                                        inputProps={{
                                            placeholder: t('restorePassword.repeatPass')
                                        }}
                                        type={!password.showRepeatValue ? 'password' : 'text'}
                                        startAdornment={<InputAdornment position="start"><Key /></InputAdornment>}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setPassword((prev) => ({ ...prev, showRepeatValue: !password.showRepeatValue }))}
                                                    edge="end"
                                                >
                                                    {password.showRepeatValue ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={(event) => handlePassValue(event.target.value, 'repeatValue')}
                                        value={password.repeatValue}
                                        error={!password.repeatValue && password.isSubmit}
                                        onKeyUp={checkInputsValidity}
                                    />
                                </FormControl>
                                {!password.isMatchPasswords &&
                                    <FormHelperText error className="helper-text">
                                        {t('restorePassword.notMatchPass')}
                                    </FormHelperText>
                                }
                            </Grid>
                        </Grid>
                    </CardBody>
                    <CardFooter>
                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleSubmitPass}
                                    className="submit-button"
                                    variant="contained"
                                    fullWidth={true}
                                    disabled={isDisabledSubmit}
                                    id="button-accept"
                                >
                                    {t('common.accept')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
            </form>
        )
    }
       
    return (
        <>
            <Snackbar
                place="tr"
                color={alert.color}
                message={alert.message}
                open={alert.open}
            />
            <div className="wrapper fp-wrapper">
                <div className="fullpage">
                    <div className="auth-user-head"></div>
                    <div className="auth-user-page">
                        <Grid container justify="center">
                            <Grid className="hmm-logo" item xs={12} sm={8}>
                                <img src={hmmLogo} alt="HMM Logo" />
                            </Grid>
                            <Grid item xs={12} sm={8} className={classes.forgotPass}>
                                {password.openNewPass ?
                                    renderPassForm() :
                                    renderPinForm()
                                }
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <a className="pwd-by-link" href="https://glin-technologies.com/" rel="noopener noreferrer" target="_blank" title="Glin">
                    <img src={pwdBy} alt="Glin" />
                </a>
            </div>
            {showAlert &&
                <CustomSweetAlert
                    type="warning"
                    onConfirm={() => onAcceptSendNewCode()}
                    onCancel={() => setShowAlert(false)}
                    confirmBtnCssClass="primary"
                    cancelBtnCssClass="danger"
                    cancelBtnText={t('common.cancel')}
                    confirmBtnText={t('common.accept')}
                    showCancel={true}
                    title={t('forgotPass.alertTile')}
                    message={<p>{t('forgotPass.alertMessage')}</p>}
                />
            }
        </>
    )
}

ForgotPassword.defaultProps = {
    location: {},
}
  

ForgotPassword.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object,
}

export default (withTranslation()(ForgotPassword));
