import { Button, CircularProgress } from '@material-ui/core'
import React, { FC } from 'react'
import ButtonSpinner from '../ButtonSpinner'
import CloseIcon from '@material-ui/icons/Close'
import CompletedSourveyScreen from './components/CompletedSurveyScreen/CompletedSurveyScreen'
import CustomDialog from '../CustomDialog'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import HeartIcon from '../../icons/HeartIcon/HeartIcon'
import InputFactory from './components/InputFactory/InputFactory'
import SurveyLinearProgress from './components/SurveyLinearProgress/SurveyLinearProgress'
import { useSurveys } from './hook/useSurveys'
import { useStyles } from './useStyles'

export interface SurveysProps {
  params: {
    email: string
  }
  location: {
    query: {
      companyId: string
      lang: string
    }
  }
}

const Surveys: FC<SurveysProps> = (props) => {
  const email = props?.params?.email
  const companyId = props?.location?.query?.companyId
  const lang = props?.location?.query?.lang
  const classes = useStyles()

  const {
    handleSubmit,
    onSubmit,
    goToNextSection,
    register,
    handleModalCancel,
    setShowModal,
    getValues,
    setError,
    goBack,
    error,
    control,
    sections,
    currentSectionIndex,
    progress,
    isNextButtonDisabled,
    isLoading,
    title,
    send,
    next,
    exit,
    showModal,
    confirm,
    cancel,
    messageModal,
    showFinishScreen,
    labelLoading,
    acceptText,
    isCompleted,
  } = useSurveys(email, companyId, lang)
  return (
    <GridContainer className={classes.gridContainer}>
      <button
        className={classes.closeButton}
        onClick={() => (showFinishScreen ? goBack() : setShowModal(true))}
      >
        <CloseIcon className={classes.closeIcon} />
      </button>
      {isCompleted && showFinishScreen ? (
        <CompletedSourveyScreen />
      ) : (
        <GridItem
          xs={12}
          style={{ textAlign: 'center' }}
          className="no-padding"
        >
          <GridItem xs={12} style={{ marginTop: 36 }}>
            <HeartIcon style={{ fontSize: 48 }} />
            <h2 className={classes.title}>{title}</h2>
          </GridItem>
          <GridItem xs={12} className={classes.gridItemCentered}>
            {isLoading || sections.length === 0 ? (
              <CircularProgress />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div key={sections[currentSectionIndex].section}>
                  <h3 className={classes.sectionTitle}>
                    {currentSectionIndex + 1}.{' '}
                    {sections[currentSectionIndex].section}
                  </h3>
                  {sections[currentSectionIndex].questions.map((question) => (
                    <div key={question.id}>
                      <InputFactory
                        type={question.type as 'rating' | 'radio' | 'textarea'}
                        control={control}
                        registerResponse={register(question.fieldName, {
                          required: true,
                        })}
                        question={question}
                        clarification={question.clarification}
                        options={question.options}
                        detailInfo={question.detailInfo}
                      />
                    </div>
                  ))}
                </div>

                <div className={classes.buttonContainer}>
                  {currentSectionIndex < sections.length - 1 ? (
                    <Button
                      variant="contained"
                      onClick={goToNextSection}
                      disabled={isNextButtonDisabled}
                      className={classes.button}
                    >
                      {next}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.button}
                    >
                      {send}
                    </Button>
                  )}
                </div>
              </form>
            )}
          </GridItem>
        </GridItem>
      )}
      {!isLoading && (
        <GridItem xs={12} className={classes.progressContainer}>
          <SurveyLinearProgress
            progress={progress}
            currentSectionIndex={currentSectionIndex + 1}
            totalSections={sections.length}
          />
        </GridItem>
      )}
      {showModal ? (
        <CustomDialog
          maxWidth="sm"
          open={showModal}
          title={exit}
          onClose={() => handleModalCancel()}
        >
          <p className={classes.modalMessage}>{messageModal}</p>
          <div className={classes.buttonContainerModal}>
            <Button color="primary" onClick={() => handleModalCancel()}>
              {cancel}
            </Button>

            <ButtonSpinner
              onClick={() => {
                const currentData = getValues()
                onSubmit(currentData)
                handleModalCancel()
              }}
              labelLoading={labelLoading}
              loading={isLoading}
              label={confirm}
              typeButton="submit"
              id="button-update"
            />
          </div>
        </CustomDialog>
      ) : null}
      {error?.error ? (
        <CustomDialog
          maxWidth="sm"
          open={error.error}
          title={error.title}
          onClose={() => {
            setError({ error: false, title: '', description: '' })
          }}
        >
          <p className={classes.modalMessage}>{error.description}</p>
          <div className={classes.buttonContainerModal}>
            <Button
              color="primary"
              onClick={() => {
                setError({ error: false, title: '', description: '' })
                goBack()
              }}
            >
              {acceptText}
            </Button>
          </div>
        </CustomDialog>
      ) : null}
    </GridContainer>
  )
}

export default Surveys
