import { dangerColor, grayColor, primaryColor } from "../../assets/components/material-dashboard-pro-react";

import { makeStyles } from "@material-ui/core";

export const useStylesConfirmUser = makeStyles(() => ({
  confirmUser: {
    minWidth: 320,
    maxWidth: '100%',
    margin: '0 auto',
    textAlign: 'center',
    '&.home_care $header img': {
      maxWidth: 135,
    },
    '&.professional $header img, &.driver $header img, &.patient $header img': {
      maxWidth: 195,
    },
    '&.primary $header, &.primary $footer, &.primary $contentPass': {
      backgroundColor: primaryColor[0],
    },
    '&.gray $header, &.gray $footer, &.gray $contentPass': {
      backgroundColor: grayColor[6],
    },
    '&.gray $contentButtonsStore a img': {
      backgroundColor: '#555',
      border: '1px solid #A6A6A6',
    },
    '&.primary.patient $contentButtonsStore a img': {
      backgroundColor: '#A6A6A6',
      border: '1px solid #A6A6A6',
    },
    '&.primary.professional $contentButtonsStore a img': {
      backgroundColor: '#353C40',
      border: '1px solid #A6A6A6',
    },
    '&.primary.driver $contentButtonsStore a img': {
      backgroundColor: '#353C40',
      border: '1px solid #A6A6A6',
    },
    fontSize: 16,
    '& .contentText': {
      '&.readyAccountStore': {
        '& strong': {
          fontWeight: 700,
          display: 'block',
          paddingBottom: 8,
        },
        '& span': {
          fontWeight: 300,
        }
      }
    },
  },
  header: {
    padding: '30px 15px !important',
    textAlign: 'center',
  },
  layout: {
    maxWidth: 600,
    margin: '0 auto',
  },
  progressContent: {
    minHeight: 'calc(100vh - 225px)',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  alignCenter: {
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 40,
    marginTop: 40,
    '& img#header': {
      width: '100%',
      margin: '20px 0',
    },
  },
  imagesScreen: {
    width: '100%',
    '& img': {
      width: '100%',
    },
  },
  readyAccountTop: {
    textAlign: 'left',
    padding: '25px',
    '& h4 ': {
      fontSize: 40,
      fontWeight: 700,
      padding: '20px 0',
    },
  },
  readyAccountMiddle: {
    padding: 35,
    '& h5': {
      fontSize: 24,
      fontWeight: 700,
      margin: '20px 0',
    },
    '& p': {
      lineHeight: 1.5,
      fontSize: 16,
      fontWeight: 400,
    },
  },
  contentPass: {
    color: 'white',
    padding: '30px 15px !important',
    textAlign: 'center',
    '& .label': {
      padding: '15px 30px',
      margin: '15px 0 8px',
      borderRadius: 4,
      fontSize: '0.875rem',
      display: 'inline-block',
      '&.error': {
        backgroundColor: '#e8b7b7',
        border: '1px solid',
        borderColor: dangerColor[0],
        color: dangerColor[0],
      }
    },
  },
  text: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 600
  },
  password: {
    fontSize: 56,
    fontWeight: 300,
    letterSpacing: 10,
  },
  contentButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    '& button': {
      margin: '0 10px',
      '& svg': {
        width: 27,
        height: 27,
      },
    },
  },
  contentButtonsStore: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    '& a': {
      display: 'inline-block',
      cursor: 'pointer',
      margin: '20px 10px 10px',
      '& img': {
        maxWidth: '100%',
        maxHeight: 48,
        borderRadius: 4,
      },
    },
  },
  footer: {
    backgroundColor: '#555',
    color: 'white',
    padding: '30px 15px !important',
    textAlign: 'center',
    '& .contentText': {
      fontSize: 14,
      fontWeight: 300,
      margin: 0
    },
  },
}));
