import React, { Component } from 'react';

import CustomDialog from '../../../CustomDialog';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import PropTypes from 'prop-types';
import SchedulerService from '../../../../containers/SchedulerService';
import SelectInput from '../../../SelectInput';
import { withTranslation } from 'react-i18next';

class ScheduleDetailModal extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            open: false,
            types: [{
                id: 1,
                value: t('Practice'),
            }, {
                id: 2,
                value: t('Package'),
            }],
            type: '',
            module: '',
            component: null,
            current: null,
            action: '',
            appointmentAction: '',
        }
    }

    getStateOnActionProps(next, currentModule) {
        const isCreateOrAssignOrEdit = ['create', 'assign_agenda', 'edit'].includes(next.actionAppointment);
        const isModuleOrPlan = next.current.module || next.current.plan;

        if (isCreateOrAssignOrEdit && !next.current.item) {
            next.current.item = { ...next.current, schedulePlan: next.current.schedulePlan };
        }

        if ((isModuleOrPlan && this.state.component == 'module') || !this.state.component) {
            if (next.current && next.action === 'edit' && !next.current.item) {
                next.current.item = { ...next.current, schedulePlan: next.current.schedulePlan };
            }
        }

        this.setState((prevState) => ({
            current: next.current,
            type: next.current.module ? 2 : 1,
            component: prevState.component ?? currentModule,
            appointmentAction: next.actionAppointment,
        }));
    }

    componentWillReceiveProps(next) {
        this.setState({ open: next.open });

        if (!next.current && next.action == 'create') {
            this.setState({ action: next.action, appointmentAction: '' });
        }
        if (next.current && next.action !== 'create') {
            const currentModule = next.current.module ? 'module' : 'plan';
            this.getStateOnActionProps(next, currentModule);
        }
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            component: null,
            current: null,
        });
        this.props.onCloseModal();
    }

    selectedType(value) {
        const component = value == 2 ? 'module' : 'plan';
        this.setState({ type: value, current: null, component });
    }

    createModule(value) {
        this.closeModal();
        this.props.onCreatedModule(value);
    }

    createPlan(value) {
        this.closeModal();
        this.props.onCreatedPlan(value);
    }

    updatePlan(value) {
        this.closeModal();
        this.props.onUpdatePlan(value);
    }

    render() {
        const { t, appointmentId, dates, customer, items, onGetModuleTypes, onGetPracticeTypes, appointmentStatus, isTransfer } = this.props;
        const { action, appointmentAction, component, current, open, type, types } = this.state;
        //const modalTitle = isTransfer ? "Configuración de Traslado" : t('appointment.new.schedule.modal.title')
        const modalTitle = t('appointment.new.schedule.modal.title')
        let size = 'sm';
        let colspan = 12;
        if (type) {
            size = 'md';
            colspan = 6;
        }
        return (
            <CustomDialog
                className="config-pm-modal"
                maxWidth={size}
                title={modalTitle}
                open={open}
                onClose={() => this.closeModal()}
            >
                <GridContainer className={component == 'module' ? 'ps-child modules-form dialog' : 'ps-child plan-form dialog'}>
                    <GridItem xs={colspan} className="componentType">
                        {!component &&
                            <SelectInput
                                id="schedule-type"
                                disabled={appointmentAction == 'assign_agenda' || appointmentAction == 'copy' || (appointmentAction == 'edit' && action !== 'create')}
                                label={t('appointment.new.schedule.modal.type')}
                                elements={types}
                                onSelectedValue={(value) => this.selectedType(value)}
                                value={type}
                            />
                        }
                    </GridItem>
                    {component == 'module' &&
                        <SchedulerService.ModulesService
                            onGetModuleTypes={onGetModuleTypes}
                            items={items}
                            onUpdatePlan={(value) => this.updatePlan(value)}
                            customer={customer}
                            dates={dates}
                            action={action}
                            appointmentAction={appointmentAction}
                            appointmentStatus={appointmentStatus}
                            appointmentId={appointmentId}
                            onSavedModules={(value) => this.createModule(value)}
                            current={current}
                        />
                    }
                    {component == 'plan' &&
                        <SchedulerService.PlanService
                            onGetPracticeTypes={onGetPracticeTypes}
                            items={items}
                            appointmentAction={appointmentAction}
                            appointmentStatus={appointmentStatus}
                            customer={customer}
                            action={action}
                            appointmentId={appointmentId}
                            dates={dates}
                            onUpdatePlan={(value) => this.updatePlan(value)}
                            onSavedPlan={(value) => this.createPlan(value)}
                            current={current}
                            //isTransfer={true}
                        />
                    }
                </GridContainer>
            </CustomDialog>
        )
    }
}

ScheduleDetailModal.defaultProps = {
    onGetModuleTypes: () => [],
    onGetPracticeTypes: () => [],
}

ScheduleDetailModal.propTypes = {
    t: PropTypes.func,
    onCloseModal: PropTypes.func,
    onCreatedModule: PropTypes.func,
    onCreatedPlan: PropTypes.func,
    onGetModuleTypes: PropTypes.func,
    onGetPracticeTypes: PropTypes.func,
    onUpdatePlan: PropTypes.func,
    appointmentId: PropTypes.number,
    dates: PropTypes.object,
    customer: PropTypes.object,
    items: PropTypes.array,
    appointmentStatus: PropTypes.string,
}

export default withTranslation()(ScheduleDetailModal);
