import React from 'react'
import Box from '@material-ui/core/Box'
import Rating from '@material-ui/lab/Rating'
import CheckIcon from '@material-ui/icons/Check'
import { useController, Control } from 'react-hook-form'

interface NumberRatingProps {
  control: Control
  fieldName: string
}

const NumberRating: React.FC<NumberRatingProps> = ({ control, fieldName }) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({
    name: fieldName,
    control,
  })

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Rating
        name={fieldName}
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue === value ? value : newValue)
        }}
        onBlur={onBlur}
        max={5}
        getLabelText={(currentValue) => `${currentValue}`}
        IconContainerComponent={({ value: currentValue }) => (
          <Box
            style={{
              border: '1px solid #5DC4B9',
              padding: '5px',
              width: '40px',
              height: '25px',
              textAlign: 'center',
              borderRadius:
                currentValue === 1
                  ? '20px 0 0 20px'
                  : currentValue === 5
                  ? '0 20px 20px 0'
                  : '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: currentValue === value ? '#C1EBE6' : 'white',
              position: 'relative',
              margin: 0,
            }}
          >
            {currentValue === value && (
              <CheckIcon
                style={{
                  marginRight: '5px',
                  color: 'green',
                  fontSize: '20px',
                }}
              />
            )}
            <span
              style={{
                color: currentValue === value ? 'green' : '#555',
                fontSize: '14px',
                position: 'relative',
                zIndex: 1,
              }}
            >
              {currentValue}
            </span>
          </Box>
        )}
        emptyIcon={<Box></Box>}
      />
    </Box>
  )
}

export default NumberRating
