import './Login.css';

import { Card, CardBody, CardFooter } from '../Card';
import {
    FilledInput,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    withStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useProfile, useTermsPolicyProfile } from '../../hooks';
import ChatBubble from '@material-ui/icons/ChatBubble';

import { ApiGetProfileFromMapper } from '../../core';
import BusinessIcon from '@material-ui/icons/Business';
import Button from '../CustomButtons/Button';
import CheckInput from '../CheckInput';
import Cookies from 'universal-cookie';
import { Key } from '../../icons';
import LoadProfile from './LoadProfile';
import Person from '@material-ui/icons/Person';
import ReCAPTCHA from "react-google-recaptcha";
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { browserHistory } from 'react-router';
import hmmLogo from '../../assets/img/login/logo-hmm.png';
import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import styles from '../../assets/components/customCheckboxRadioSwitch';
import { useTranslation } from 'react-i18next';
import Chat from '../Chat/index.js';
import config  from '../../config/config.js';
var CryptoJS = require("crypto-js");

const mapper = new ApiGetProfileFromMapper();
const langMap = { "es": "es-ES", "en": "en-US", "pt": "pt-BR", "it": "it-IT", "fr": "fr-FR" };
//mlg
const Login = () => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        loading: false,
        disableInteractions: false,
        checking: false,
        rememberMe: false,
        showPassword: false,
        realms: [],
        disabledRealm: false,
        realm: '',
        username: '',
        password: '',
        profile: {},
        isProfessional: false,
        isSubmitLogin: false,
        lang: 'es',
        isSubmitForgotPass: false,
        isChatVisible: false,
    });
    const [token, setToken] = useState(null);
    const [keyCaptcha, setKeyCaptcha] = useState(null);
    const [passphrase, setPassphrase] = useState(null);
    const [alert, setAlert] = useState({
        color: 'success',
        message: '',
        open: false,
        isChatVisible: false
    });

    const { saveProfileStorage } = useProfile();
    const { saveTermsPolicyOnProfile } = useTermsPolicyProfile();

    const fetchKeys = async () => {
        const response = await fetch('/getkeys/');
        const data = await response.json();
        setKeyCaptcha(data.captchaKey);
        setPassphrase(data.privateKey);
    }

    useEffect(() => {
        // code to run on component mount
        fetchKeys();
        const cookies = new Cookies();
        const lang = cookies.get("lang");
        if (lang != null) {
            setState((prev) => ({ ...prev, lang }));
            i18next.changeLanguage(lang);
        }
    }, []);

    const openAlert = (color, message) => {
        setAlert((prev) => ({
            ...prev,
            color,
            message,
            open: true,
        }));

        setTimeout(() => {
            setAlert((prev) => ({ ...prev, open: false }));
        }, 5000);
    }

    const formatRealms = (data) => data.map((d, idx) => ({
        id: idx + 1, name: d.realmName, value: d.realm, companyId: d.companyId,
    }));

    const getRealms = () => {
        const requestOptions = {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({ username: state.username.trim().toLowerCase() }),
        };

        fetch('/api/realms', requestOptions)
            .then(response => response.json())
            .then(data => {
                setState((prev) => ({ ...prev, disableInteractions: false, loading: false }));

                if (!data.error) {
                    const realms = formatRealms(data);
                    const realm = realms.length ? realms[0].id : '';
                    const disabledRealm = realms.length <= 1;
                    setState((prev) => ({ ...prev, realms, realm, disabledRealm }));
                    return;
                }
                openAlert('danger', t('login.bad_credentials'))
                setState((prev) => ({ ...prev, disableInteractions: false, loading: false }));
            })
            .catch(() => {
                setState((prev) => ({ ...prev, disableInteractions: false, loading: false }));
            });
    }

    const handleRealms = () => {
        if (state.isProfessional || !state.username) {
            return;
        }
        setState((prev) => ({ ...prev, disableInteractions: true, loading: true }));
        getRealms();
    }

    const getProfile = () => {
        const cookies = new Cookies();
        let lang = cookies.get("lang");
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': lang },
        };

        fetch('/api/profile', requestOptions)
            .then(response => {
                const cookiename = "sessionActive";
                const cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
                if (cookiestring != null) {
                    const key = decodeURIComponent(cookiestring.toString().replace(/^[^=]+./, ""));
                    localStorage.setItem('sessionId', key);
                    window.sessionStorage.setItem("sessionId", key);
                }
                if (response.status !== 200) {
                    setState((prev) => ({ ...prev, disableInteractions: false, loading: false }));
                    openAlert('danger', t('error.getProfile'));
                }
                return response.json();
            })
            .then(async profile => {
                if (!profile) {
                    return openAlert('danger', t('error.getProfile'));
                }
                if (!profile.active) {
                    setState((prev) => ({ ...prev, loading: false }));
                    return openAlert('danger', t('error.login.desactivado'));
                }
                const profileData = mapper.fromMap(profile);
                setState((prev) => ({ ...prev, profile: profileData }));
                saveTermsPolicyOnProfile({
                    userId: profileData.userId,
                    firstName: profileData.firstName,
                    lastName: profileData.lastName,
                    requiereTermsAccept: profileData.requiereTermsAccept,
                    requestTermsVersion: profileData.requestTermsVersion,
                });
                setTimeout(() => {
                    if (profileData.requiereTermsAccept) {
                        setState((prev) => ({ ...prev, disableInteractions: false }));
                        return;
                    }
                    setState((prev) => ({ ...prev, disableInteractions: false, loading: false }));
                    saveProfileStorage(profileData);
                    console.log(profileData);
                    browserHistory.push('/solicitudes');
                }, 3000);
            });
    }

    const getSelectedRealm = () => {
        if (!state.realms) {
            return null;
        }
        const realm = state.realms.find(r => r.id === state.realm);
        if (!realm) {
            return null;
        }
        return realm;
    }

    const encryptPassword = async () => {
        try {
            return CryptoJS.AES.encrypt(state.password, passphrase).toString();

        } catch (error) {
            console.error('** Error getEncryptedPass:', error);
            return null;
        }
    }

    const encryptToLoginPassword = async () => {
        if (!passphrase) {
            await fetchKeys();
        }
        return await encryptPassword();
    }

    const postToken = async () => {
        const re = getSelectedRealm();
        let realm = '';
        if (re != null) {
            realm = getSelectedRealm().value;
        }

        const username = state.username.trim().toLowerCase();
        const password = await encryptToLoginPassword();
        console.log("value" + " " + password);
        const requestOptions = {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                username,
                password,
                realm,
                profesional: state.isProfessional,
            }),
        };

        fetch('/api/auth/token', requestOptions)
            .then(response => (response.json()))
            .then(data => {
                if (data.error) {
                    openAlert('danger', t('login.bad_credentials'));
                    setState((prev) => ({
                        ...prev,
                        disableInteractions: false,
                        loading: false,
                    }));
                    return;
                }
                getProfile();
            })
            .catch(() => {
                openAlert('danger', t('login.bad_credentials'));
                setState((prev) => ({ ...prev, disableInteractions: false, loading: false }));
            });
    }

    const handleLogin = () => {
        setState((prev) => ({ ...prev, isSubmitLogin: true }));
        setState((prev) => ({ ...prev, disableInteractions: true, loading: true }));
        postToken();
    }

    const handleValue = (value, type) => {
        if (type == 'username') {
            const regex = config.regex_email;
            if (regex.test(value)) {
                return;
            }
        }
        if (type == 'password') {
            const regex = config.regex_password;
            if (regex.test(value)) {
                return;
            }
        }
        if (type === 'isProfessional') {
            const isLocalhost = ['localhost', '127.0.0.1', '::1'].includes(window.location.hostname);

            const cookies = new Cookies();
            cookies.set("profesionals", value, {
                httpOnly: false,
                secure: false,
                sameSite: isLocalhost ? 'Lax' : 'Strict' 
            });
        }
        setState((prev) => ({ ...prev, [type]: value }));
    }

    const changeLang = (event) => {
        setState((prev) => ({ ...prev, lang: event.target.value }));
        const { username, password, realm } = state;
        const isLocalhost = ['localhost', '127.0.0.1', '::1'].includes(window.location.hostname);

        const cookies = new Cookies();
        cookies.set("lang", event.target.value, {
            httpOnly:false,
            secure: false, 
            sameSite: isLocalhost ? 'Lax' : 'Strict' 
        });
        i18next.changeLanguage(event.target.value);
        setState({ username, password, realm });
        handleRealms();
        window.location.reload();
    }

    const renderLoginForm = () => (
        <React.Fragment>
            <div className="right">
                <select id="select-language" onChange={(item) => changeLang(item)} value={state.lang}>
                    <option value="es">Español</option>
                    <option value="en">Inglés</option>
                    <option value="pt">Portugués</option>
                </select>
            </div>
            <FormControl variant="filled">
                <FilledInput
                    id="username"
                    disabled={state.disableInteractions}
                    onBlur={() => handleRealms()}
                    inputProps={{
                        disabled: state.disableInteractions,
                        placeholder: t('login.user'),
                    }}
                    type="text"
                    startAdornment={<InputAdornment position="start"><Person /></InputAdornment>}
                    onChange={(event) => handleValue(event.target.value, 'username')}
                    error={(!state.username && state.isSubmitLogin) || (!state.username && state.isSubmitForgotPass)}
                    value={state.username}
                />
            </FormControl>

            {!state.isProfessional &&
                <FormControl>
                    <SelectInput
                        id="realm"
                        className="company"
                        placeholder={t('login.realm')}
                        disabled={state.disabledRealm}
                        elements={state.realms}
                        value={state.realm}
                        onSelectedValue={(value) => handleValue(value, 'realm')}
                        invalid={(!state.realm && state.isSubmitLogin) || (!state.realm && state.isSubmitForgotPass)}
                        error={!state.realm && state.isSubmitLogin}
                        isAdornedStart={true}
                        iconAdornedStart={BusinessIcon}
                        onGetOptionLabel={(option) => option.name || ''}
                        onGetOptionSelected={(option) => (option?.id) ? option?.id : null}
                    />
                </FormControl>
            }

            <FormControl variant="filled">
                <FilledInput
                    id="password"
                    disabled={state.disableInteractions}
                    inputProps={{
                        disabled: state.disableInteractions,
                        placeholder: t('login.password'),
                    }}
                    type={!state.showPassword ? 'password' : 'text'}
                    startAdornment={<InputAdornment position="start"><Key /></InputAdornment>}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setState((prev) => ({ ...prev, showPassword: !state.showPassword }))}
                                edge="end"
                            >
                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={(event) => handleValue(event.target.value, 'password')}
                    value={state.password}
                    error={!state.password && state.isSubmitLogin}
                />
            </FormControl>
            <div>
                <CheckInput
                    className="login-remember-me"
                    onChangeValue={() => handleValue(!state.isProfessional, 'isProfessional')}
                    checked={state.isProfessional}
                    label={t('login.profesional')}
                    labelPlacement="end"
                />
            </div>
        </React.Fragment>
    );

    const getLangByCookies = () => {
        const cookies = new Cookies();
        const lang = cookies.get('lang');
        if (lang == null) {
            return langMap['es'];
        }
        return langMap[lang] == null ? langMap['es'] : langMap[lang];
    }

    const goToForgotPass = async () => {
        setState((prev) => ({ ...prev, isSubmitLogin: false, isSubmitForgotPass: true }));
        if (!state.realm || !state.username) {
            openAlert('warning', t('common.messageWarning.fieldsComplete'));
            return;
        }

        const username = state.username;
        const realmSelect = getSelectedRealm();
        const realmName = realmSelect.code;
        const companyId = `${realmSelect.companyId}`;
        try {
            const params = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-itlg-companyId': companyId,
                    'Accept-Language': getLangByCookies()
                },
                body: JSON.stringify({}),
            };
            const response = await fetch(`/keychange/${username}`, params);
            if (response.status === 200) {
                browserHistory.push({
                    state: { username, realm: state.realm, realmName, companyId },
                    pathname: '/forgot-password',
                });
            }
            if (response.status === 400) {
                response.text().then((text) => {
                    try {
                        const t = JSON.parse(text);
                        openAlert('danger', t.detail);

                    } catch (error) {
                    }
                });
            }
            console.error('** error goToForgotPass1');
        } catch (_) {
            console.error('** error goToForgotPass');
            openAlert('danger', t('common.errorService'));
        }
    }

    const validateLogin = () => {
   
        const options = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'g-recaptcha-token': token
            })
        }
        fetch(`/recaptcha`, options)
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (!data.success) {
                    return openAlert('danger', t('login.is.robot'));
                }
                handleLogin();
            })
            .catch((_) => {
                openAlert('danger', t('login.is.robot'));
            });
    }

    const onVerify = (t) => {
        setToken(t);
    }

    const handleCloseTermsPolicy = ({ isAccept, error = {} }) => {
        if (error.isError) {
            localStorage.removeItem('terms_policy');
            setState((prev) => ({ ...prev, loading: false, disableInteractions: false, profile: {} }));
            openAlert('danger', error.message);
            return;
        }
        if (!isAccept) {
            localStorage.removeItem('terms_policy');
            setState((prev) => ({ ...prev, loading: false, disableInteractions: false, profile: {} }));
            openAlert('danger', t('termsPolicy.rejectedSuccess'));
            return;
        }

        const profile = { ...state.profile, requiereTermsAccept: false };
        setState((prev) => ({ ...prev, profile, disableInteractions: false }));
        saveProfileStorage(profile);
        openAlert('success', t('termsPolicy.acceptSuccess'));
        setTimeout(() => {
            browserHistory.push('/solicitudes');
        }, 1500);
    }

    const startChat = () => {
        setState((prev) => ({ ...prev, isChatVisible: !state.isChatVisible }));
    }
    return (
        <>
            {!keyCaptcha ? <div /> :
                <div className="wrapper login-wrapper">
                    <div className="fullpage">
                        <Snackbar
                            place="tr"
                            color={alert.color}
                            message={alert.message}
                            open={alert.open}
                        />
                        <div className="auth-user-head"></div>
                        <Button
                            id="button-signin"
                            onClick={startChat}
                            class="chat-floating">
                            <ChatBubble />
                        </Button>
                        <Chat login={true} isVisible={state.isChatVisible}/>
                        <Grid className="auth-user-page login" container>
                            <Grid item className="hmm-logo" xs={12} sm={8}>
                                <img src={hmmLogo} alt="HMM Logo" />
                            </Grid>
                            <Grid item className="login-content" xs={12} sm={8}>
                                <form
                                    className="auth-user-form"
                                    id="login-form"
                                    onSubmit={validateLogin}
                                    onKeyUp={({ key }) => {
                                        if (key === 'Enter') {
                                            handleLogin();
                                        }
                                    }}
                                    autoComplete="off"
                                >
                                    <Card login className="auth-user-card login-card">
                                        <CardBody>
                                            {
                                                state.loading && !isEmpty(state.profile)
                                                    ? <LoadProfile
                                                        data={{
                                                            userId: state.profile.userId,
                                                            firstName: state.profile.firstName,
                                                            lastName: state.profile.lastName,
                                                            requiereTermsAccept: state.profile.requiereTermsAccept,
                                                            requestTermsVersion: state.profile.requestTermsVersion,
                                                        }}
                                                        onCloseTermsPolicy={(data) => handleCloseTermsPolicy(data)}
                                                    />
                                                    : renderLoginForm()
                                            }
                                        </CardBody>
                                        {!state.loading ? (
                                            <CardFooter className="login-sign-in">
                                                <ReCAPTCHA sitekey={keyCaptcha} hl={state.lang} onChange={onVerify} />
                                                <Button
                                                    id="button-signin"
                                                    onClick={validateLogin}
                                                    className="submit-button"
                                                    variant="contained"
                                                    disabled={state.disableInteractions}
                                                >{t('login.signin')}</Button>

                                                {/* <div>
                                                    <CheckInput
                                                        className="login-remember-me"
                                                        onChangeValue={() => handleValue(!state.rememberMe, 'rememberMe')}
                                                        checked={state.rememberMe}
                                                        label={t('login.remember_me')}
                                                        labelPlacement="end"
                                                    />
                                                </div> */}
                                                <div className="link-content">
                                                    <Button
                                                        id="button-pass"
                                                        simple
                                                        className="link-button"
                                                        onClick={goToForgotPass}
                                                    >{t('login.forgotPass')}</Button>                                               </div>
                                            </CardFooter>
                                        ) : null}
                                    </Card>
                                </form>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </>
    )
}

export default withStyles(styles)(Login);
