import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    
  customLinearProgress: {
    backgroundColor: '#C1EBE6', 
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#5DC4B9', 
    },
  },
  button: {
    backgroundColor: '#5DC4B9',
    color: '#C1EBE6',
    padding: '8px 16px', 
    width: 'auto', 
    marginTop: '20px',
    display: 'inline-block', 
    '&:hover': {
      backgroundColor: '#4AB8A0',
    },
    '&:disabled': {
      backgroundColor: '#A3E0DA',
      color: '#FFF',
    },
  },
  buttonContainer: {
    marginTop: '36px',
    textAlign: 'right', 
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginTop: 15
  },
  closeIcon: {
    color: '#5DC4B9',
    fontSize: 24,
  },
  progressContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridContainer: {
    padding: 16,
    margin: 'auto',
    color: '#555',
    maxWidth: '500px',
     marginTop: '20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
    },
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 500,
    marginBottom: 42,
    color: '#555',
  },
  sectionTitle: {
    color: '#555',
    fontSize: '1.1rem',
    fontWeight: 500,
    marginBottom: 24,
    textAlign: 'left',
  },
  gridItemCentered: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
  },
  modalMessage: {
    textAlign: 'center',
    marginBottom: '16px',
  },
  buttonContainerModal: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));