import Button from '../../CustomButtons/Button';
import CustomDialog from '../../CustomDialog';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DownloadCloudIcon from '../../../icons/DownloadCloudIcon';

type List = {
  key: number;
  label: string;
  practice?: string;
  fileName?: string;
  file?: string;
}

interface ComponentProps {
  title: string;
  data: List[];
  open: boolean;
  onCloseModal: (show: boolean) => void;
}

const useStyles = makeStyles({
  dialogInfoPM: {
    paddingBottom: '0.9375rem !important',
  },
  items: {
    paddingTop: '2rem',
    minHeight: 50,
    '& li': {
      '& strong': {
        display: 'block',
        fontWeight: 500,
        fontSize: '1rem',
        marginBottom: 4,
      },
      '& + li': {
        marginTop: 12,
      }
    }
  },
  submitButton: {
    textAlign: 'center',
    paddingTop: '3rem',
  },
});

const InfoScheduleItemModal = ({ title, data, open, onCloseModal }: ComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderData = (): JSX.Element | JSX.Element[] => {
    const message = t('table.empty');
    if (data.length) {
      return data.map((e: List) =><> 
      {e.label?
        <li key={`obs-${e.key}`}>
          {e.practice && <strong>{e.practice}:</strong>}
          {e.label}
        </li>
        :<></>
      }
      
      {e.file ?
          <span style={{display:'flex', alignItems:'center', gap:'10px',marginTop:'12px'}}>
          <DownloadCloudIcon />
          <a href={e?.file} download={e.fileName} style={{textDecoration:'none',color:'#3C4858'}}>{e.fileName}</a>
        </span> 
        : <></>}

      </>);
    }
    return <li>{message}</li>;
  }

  return (
    <CustomDialog
      className={classes.dialogInfoPM}
      title={title}
      maxWidth="sm"
      open={open}
      onClose={() => onCloseModal(false)}
    >
      <ul className={classes.items}>
        {renderData()}
      </ul>
      <div className={classes.submitButton}>
        <Button color="primary" onClick={() => onCloseModal(false)}>{t('common.accept')}</Button>
      </div>
    </CustomDialog>
  )
}

InfoScheduleItemModal.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default InfoScheduleItemModal;
