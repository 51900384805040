import '../../../NewSchedule.css';

import React, { Component } from 'react';

import AppointmentApiInvoker from '../../../../../api/AppointmentApiInvoker';
import Button from '../../../../CustomButtons/Button'
import ButtonSpinner from '../../../../ButtonSpinner';
import CustomSweetAlert from '../../../../CustomSweetAlert';
import DateInput from '../../../../DateInput';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import ModalSuggestedProfessionals from './ModalSuggestedProfessionals';
import PropTypes from 'prop-types';
import SchedulePlan from '../../../../SchedulePlan';
import SelectInput from '../../../../SelectInput';
import TimePickerGCalendar from '../../../../TimePickerGCalendar';
import ValidationInput from '../../../../ValidationInput'
import config from '../../../../../config/config';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ModalSuggestedDrivers from './ModalSuggestedDrivers';
import ModalFileUpload from './ModalFileUpload';

const dateToServer = config.getDateToServer();

class Plan extends Component {
	constructor(props) {
		super(props)
		const { t } = props;

		this.state = {
			startTime: '10:00 am',
			endTime: '11:30 pm',
			startDate: '',
			plan: '',
			plans: [],
			recurrence: {},
			savePlan: false,
			id: 0,
			companyPracticesType: [],
			estimatedDuration: '',
			professionals: [],
			originalDayLength: 0,
			openSuggestedProfessionals: false,
			openSuggestedDrivers: false,
			busy: false,
			items_radio: [],
			selected_radio_service: 1,
			third_parties: [],
			third_parties_codes: [],
			lender: null,
			selector: [
				{ id: 1, value: t('appointment.new.schedule.lender.me'), component: true },
				{ id: 2, value: t('appointment.new.schedule.lender.third'), component: false }
			],
			invalidPlan: false,
			errorStartDateMsg: null,
			isErrorStartDate: false,
			isErrorEndDate: false,
			errorEndDateMsg: null,
			file:{},
			fileId:0,
			invalidNumDays: false
		}
	}

	setInvalidNumDays = (newValue) => {
		this.setState({ invalidNumDays: newValue });
	  };

	buildStateOnPracticeTypeId(schedulep, originalDayLength) {
		const { current } = this.props;
		const lender = this.isJSON(current.item.companyIdPartnership) ? current.item.companyIdPartnership.companyId : current.item.companyIdPartnership;
		return {
			plan: current.practiceTypeId,
			startDate: current.item.startDate,
			startTime: current.item.startTime,
			endDate: current.item.endDate,
			lender,
			endTime: current.item.endTime,
			recurrence: schedulep,
			schedulePlan: schedulep,
			appointmentItemRequestStatus: current.item.appointmentItemRequestStatus,
			observations: current.observations,
			originalDayLength,
			order: current.order,
			oldStartDate: current.item.startDate,
			itemRequestDetails: current.item.itemRequestDetails,
			file: current.item.file,
		}
	}

	buildStateOnPlan(current) {
		let data = { itemRequestDetails: current.itemRequestDetails };
		if (!current.specialty) {
			data = {
				...data,
				id: current.id,
				plan: current.plan.id,
				startDate: current.startDate,
				endDate: current.endDate,
				startTime: current.startTime,
				endTime: current.endTime,
				recurrence: current.recurrence,
				order: current.order,
			};
			return data;
		}
		data = {
			...data,
			id: current.plan.id,
			plan: current.plan.plan.id,
			startDate: current.plan.startDate,
			endDate: current.plan.endDate,
			startTime: current.plan.startTime,
			endTime: current.plan.endTime,
			recurrence: current.plan.recurrence,
		}
		return data;
	}

	buildRecurrenceWeekOrIntraday(schedulep) {
		let originalDaysLength = null;
		if (schedulep.recurrenceType === 'WEEK' || schedulep.recurrenceType === 'INTRADAY') {
			let weekRecurrence = [];
			const isArray = Array.isArray(schedulep.weekDays) && schedulep.weekDays.length;
			if (isArray) {
				weekRecurrence = schedulep.weekDays;
			} else {
				if (schedulep.weekDays.length > 0) {
					weekRecurrence = schedulep.weekDays.split(',');
				}
			}
			
			if (!isArray && schedulep.weekRecurrence) {
				let split = schedulep.weekDays.split(',');
				weekRecurrence = split;
			}
			schedulep.weekRecurrence = weekRecurrence;
			originalDaysLength = schedulep.weekRecurrence.length;

		}
		return { plan: schedulep, originalDaysLength };
	}

	getInitialState() {
		const { appointmentAction, current } = this.props;

		if (appointmentAction === 'assign_agenda' || appointmentAction === 'edit' || appointmentAction === 'create' || appointmentAction === 'copy') {
			AppointmentApiInvoker.getFile(current.appointmentItemRequestId, (data) => {

				const fileEdit = {}
				fileEdit.name=data[0].name
				fileEdit.date=data[0].dateUpload
				fileEdit.base64=data[0].file
				fileEdit.type=data[0].extension
				this.setState({file:fileEdit})
				this.setState({fileId:data[0].appointmentItemRequestResourcesId})
			})

			if (current?.practiceTypeId) {
				let schedulep = current.item.schedulePlan;
				let originalDayLength = 0;
				if (schedulep) {
					const { plan, originalDaysLength } = this.buildRecurrenceWeekOrIntraday(schedulep);
					schedulep = plan;
					originalDayLength = originalDaysLength;
					if (schedulep.monthDay) {
						schedulep.monthRecurrence = schedulep.monthDay;
					}
				}
				const data = this.buildStateOnPracticeTypeId(schedulep, originalDayLength);
				this.setState(data, () => {
					this.setPartnershipDefault();
					this.getPartnership(current.practiceTypeId);
				});
			}
			return;
		} 
		if (current?.plan) {
			const data = this.buildStateOnPlan();
			this.setState(data);
		}
	}

	componentDidMount() {
		this.getPartnership(null);
		const { practiceTypes } = this.props;

		if (practiceTypes) {
			this.setState({ plans: this.formatPracticeTypes(practiceTypes) });
		} else {
			this.getPracticeTypes();
		}
		this.getCompanyPracticeTypes();
		this.getInitialState();
		this.fillSuggested();
	}

	isJSON(str) {
		return !!(str?.companyId);
	}
	
	componentWillReceiveProps(next) {
		if (next.practiceTypes) {
			this.setState({ plans: this.formatPracticeTypes(next.practiceTypes) })
		}
		if (next.professionals) {
			this.setState({ professionals: this.formatProfessionals(next.professionals) })
		}
		this.fillSuggested();
	}

	handleValueRecurrence = (value) => {
		let newEndDateValue = value?.endDate;
		if (value?.endDate) {
			this.checkDates(null, value?.endDate);
		}
		if (value?.finish !== '3') {
			newEndDateValue = null;
			this.setState({ errorEndDateMsg: '', isErrorEndDate: false });
		}
		return newEndDateValue;
	}

	handleValue(value, state) {
		const { endDate, changedStartDate } = this.state;
		let newEndDate = endDate;
		let newChangedStartDate = changedStartDate;
		if (state === 'recurrence') {
			newEndDate = this.handleValueRecurrence(value);
		}
		if (state === 'startDate') {
			this.checkDates(value);
			newChangedStartDate = this.state.oldStartDate !== moment(value).format(dateToServer);
		}
		if (state == 'plan') {
			this.getPartnership(value);
		}
		this.setState({ [state]: value, endDate: newEndDate, changedStartDate: newChangedStartDate }, () => {
			this.fillSuggested();
		});
	}

	checkDates = (startDateValue = null, endDateValue = null) => {
		const { dates, t } = this.props;
		const { startDate, endDate } = this.state;
		const appointmentStartDate = moment(dates.startDate).format(dateToServer);
		const appointmentEndDate = moment(dates.endDate).format(dateToServer);
		const practiceStartDate = moment(startDateValue ?? startDate).format(dateToServer);
		let isErrorStartDate = false;
		let errorStartDateMsg = '';
		let isErrorEndDate = false;;
		let errorEndDateMsg = '';
		if (endDate || endDateValue) {
			const practiceEndDate = moment(endDateValue ?? endDate).format(dateToServer);
			if (practiceEndDate < practiceStartDate) {
				errorEndDateMsg = t('newschedule.practice.validate.end.date.before.start.date');
				isErrorEndDate = true;
			}
			if (practiceEndDate < appointmentStartDate || practiceEndDate > appointmentEndDate) {
				errorEndDateMsg = t('newschedule.practice.validate.end.date.out.range');
				isErrorEndDate = true;
			}
			this.setState({ isErrorEndDate, errorEndDateMsg });
		}

		if ((practiceStartDate < appointmentStartDate) || (practiceStartDate > appointmentEndDate)) {
			errorStartDateMsg = t('newschedule.practice.validate.start.date.out.range');
			isErrorStartDate = true;
		}
		this.setState({ isErrorStartDate, errorStartDateMsg });
		return !isErrorStartDate && !isErrorEndDate;
	}

	fillSuggested = () => {
		const { itemRequestDetails, recurrence, plan } = this.state;

		if (plan) {
			const items = itemRequestDetails ?? [];
			const result = [];
			const topv = recurrence && recurrence.recurrenceType === 'INTRADAY' && recurrence.quantityIntraday
				? recurrence.quantityIntraday : 1;
			for (let idx = 0; idx < topv; idx++) {
				if (idx >= items.length) {
					const row = {};
					row['packageDetailId'] = null;
					row['practiceTypeId'] = this.state.plan;
					row['suggestedEmployeeId'] = null;
					row['filerByZone'] = false;
					row['order'] = idx + 1;
					result.push(row);
				} else {
					result.push(items[idx]);
				}
			}
			this.setState({ itemRequestDetails: result });
		}
	}

	mapPlan(id) {
		let result = this.state.plans.find(p =>  p.id === id);
		return result;
	}

	hasInvalidRecurrenceIntraday = (recurrence) => {
		const plan = this.mapPlan(this.state.plan);
		const isQuantityInvalid = recurrence.quantityIntraday < 1 || (plan?.estimatedDuration || 0) * recurrence.quantityIntraday > 1440;
		const isWeekRecurrenceInvalid = !recurrence.weekRecurrence || recurrence.weekRecurrence === '';
		return isQuantityInvalid || isWeekRecurrenceInvalid;
	}

	isAnInvalidPlan = () => {
		const { recurrence, endDate, startTime, endTime, startDate, plan, lender } = this.state;
		const hasInvalidRecurrenceType = recurrence && (
			!recurrence.recurrenceType ||
			!(recurrence.recurrenceType === 'DAY' || recurrence.recurrenceType === 'WEEK' || recurrence.recurrenceType === 'MONTH' || recurrence.recurrenceType === 'INTRADAY')
		);

		const hasInvalidWeek = (recurrence && recurrence.recurrenceType === 'WEEK' && recurrence.weekRecurrence && !recurrence.weekRecurrence.length);

		const hasInvalidMonth = (recurrence && recurrence.recurrenceType === 'MONTH' && (!recurrence.monthRecurrence || parseInt(recurrence.monthRecurrence) < 1 || parseInt(recurrence.monthRecurrence) > 31));

		const hastInvalidFinish = recurrence.finish ?
			(!recurrence.finish || (recurrence.finish && recurrence.finish === '2' && recurrence.finishOcurrences < 1)) : false;

		const hasInvalidFinishEndDate = recurrence.finish === '3' && (!recurrence.endDate && !endDate);

		if (recurrence.recurrenceType === 'INTRADAY') {
			this.hasInvalidRecurrenceIntraday(recurrence);
		}

		const isInvalid = (
			(recurrence && recurrence.every < 1) ||
			!startTime ||
			!endTime ||
			!startDate ||
			!plan ||
			!lender ||
			isEmpty(recurrence) ||
			hasInvalidRecurrenceType ||
			hasInvalidWeek ||
			hasInvalidMonth ||
			hastInvalidFinish ||
			hasInvalidFinishEndDate
		);
		return isInvalid;
	}

	requestedPlan = () => {
		const result = [];

		if (Array.isArray(this.state.itemRequestDetails)) {
			return this.state.itemRequestDetails;
		}

		if (this.state.itemRequestDetails) {
			const va = this.state.itemRequestDetails;
			if (!va.practiceTypeId || va.practiceTypeId === '')
				va.practiceTypeId = this.state.plan;
			result.push(va);
		}
		return result;
	}

	getItemRequestDetails = () => {
		const isDataArray = Array.isArray(this.state.itemRequestDetails);
		const itemRequestDetails = isDataArray
		? this.state.itemRequestDetails.map((i) => ({
			...i,
			practiceTypeId: this.state.plan,
		}))
		: [this.state.itemRequestDetails];
		return itemRequestDetails;
	}

	buildAppointmentItemRequestId = (item) => {
		const itemRequestDetails = this.getItemRequestDetails();
		let weekRecurrence = '';
		if (this.state.recurrence.weekRecurrence) {
			weekRecurrence = this.state.recurrence.weekRecurrence.toString();
		}

		let endType = this.state.recurrence.endType;
		if (this.state.recurrence.finish) {
			const isNotOcurrences = this.state.recurrence.finish === '1' ? 'DATE' : null;
			endType = this.state.recurrence.finish === '2' ? 'OCURRENCES' : isNotOcurrences;
		}
		let endDate = null;
		if (this.state.recurrence.finish === '3') {
			endDate = moment(this.state.recurrence.endDate || this.state.endDate).format(dateToServer);
			endType = 'DATE';
		}

		let estimatedDuration = 1;
		if (this.state.plan) {
			const plan = this.mapPlan(this.state.plan);
			estimatedDuration = plan?.estimatedDuration ?? 1;
		}

		let recurrenceType = this.state.recurrence.recurrenceType;
		let processingScheduleType = 'SCHEDULE';
		const processingStartTimes = null;
		let processingDurationTime = null;
		let every = this.state.recurrence.every;

		if (this.state.recurrence.recurrenceType === 'INTRADAY') {
			recurrenceType = 'WEEK';
			processingScheduleType = 'INTRADAY';
			processingDurationTime = estimatedDuration * this.state.recurrence.quantityIntraday;
			every = 1;
		}
		let lender = null;
		if (this.state.lender) {
			lender = { 'companyId': this.state.lender };
		}
		let statusItemOld = this.state.appointmentItemRequestStatus;
		if (statusItemOld == 'REJECT') {
			statusItemOld = "CREATED";
		}
		
		const newItem = {
			appointmentItemRequestId: item.appointmentItemRequestId,
			practiceTypeId: this.state.plan,
			startTime: moment(this.state.startTime, 'hh:mm a').format('HH:mm'),
			endTime: moment(this.state.endTime, 'hh:mm a').format('HH:mm'),
			endDate,
			startDate: moment(this.state.startDate).format(dateToServer),
			order: this.state.order,
			observations: this.state.observations,
			companyIdPartnership: lender,
			serviceProvider: item.serviceProvider,
			appointmentItemRequestStatus: this.props.appointmentAction == 'copy' ? 'CREATED' : statusItemOld,
			itemRequestDetails,
			schedulePlan: {
				...this.state.schedulePlan,
				endType,
				recurrenceType,
				processingScheduleType,
				processingStartTimes: processingStartTimes,
				processingDurationTime,
				every,
				ocurrences: this.state.recurrence.ocurrences ?? this.state.recurrence.finishOcurrences,
				finishOcurrences: this.state.recurrence.finishOcurrences,
				weekDays: weekRecurrence,
				weekRecurrence: weekRecurrence,
				monthDay: this.state.recurrence.monthRecurrence,
				monthRecurrence: this.state.recurrence.monthRecurrence,
			},
		}
		return newItem;
	}

	sendFile (body)  {
		AppointmentApiInvoker.postSaveFile(body, (res) => {
		  console.log('Respuesta del servicio:', res);
		});
	};

	deleteFile (id)  {
		AppointmentApiInvoker.putFile(id, (res) => {
		console.log('Respuesta del servicio:', res);
		});
	};

	onSavePlanEditCopy() {
		if (this.isAnInvalidPlan()) {
			return this.setState({ savePlan: true, busy: true });
		}

		const { items, current } = this.props;

		const newItems = items.filter((f) => f.appointmentItemRequestId === current.appointmentItemRequestId).map((item) => {
			return this.buildAppointmentItemRequestId(item);
		});

		//caso 1. eliminar y no guardar otro archivo
		if(!this.state.file){
			this.deleteFile(this.state.fileId);
		}
		//caso 2. Editar el archivo existente/nuevo y guardarlo
		else if(!this.state.file.base64.startsWith('https')){
			const { name, type, date, base64 } = this.state.file;
            const [day, month, year, hourRaw, minute, period] = date.split(/\/|\s|:/);

            const hour = period.toLowerCase() === 'p. m.' && hourRaw !== '12'
              ? (parseInt(hourRaw) + 12).toString()
              : hourRaw === '12' && period.toLowerCase() === 'a. m.'
              ? '00'
              : hourRaw;

            const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`;
          
            const body = {
				name: name || 'file',
				extension: type.split('/')[1].toUpperCase(),
				file: base64,
				appointmentItemRequestId: current.appointmentItemRequestId,
				dateUpload: formattedDate,
				...(this.state.fileId !== 0 && { appointmentItemRequestResourcesId: this.state.fileId }) // Solo añade si fileId es distinto de 0
			  };

            // Enviar el objeto creado al servicio
            this.sendFile(body);
		}
		//caso 2. No tiene archivo y generamos uno nuevo
		this.props.onUpdatePlan(newItems);
		
	}

	onSavePlanError = () => {
		let error = false;

		// Validamos antes que todos los reg esten okey, porque sino el close se rompe.
		this.props.items.map(() => {
			if (this.state.recurrence.weekRecurrence) {
				if (this.state.recurrence.weekRecurrence.length !== this.state.originalDayLength) {
					error = true;
				}
			}
		});
		return error;
	}

	buildItemsOnSavePlanAssingAgenda = (item, error) => {
		if (item.practiceTypeId === this.props.current.item.practiceTypeId && item.order === this.props.current.order) {
			item.startDate = this.state.startDate;
			item.startTime = moment(this.state.startTime, 'hh:mm a').format('HH:mm');
			item.endTime = moment(this.state.endTime, 'hh:mm a').format('HH:mm');
			item.observations = this.state.observations;

			if (this.state.itemRequestDetails) {
				if (Array.isArray(this.state.itemRequestDetails)) {
					item.itemRequestDetails = this.state.itemRequestDetails;
				} else {
					item.itemRequestDetails = [this.state.itemRequestDetails];
				}
			} else {
				item.itemRequestDetails = [];
			}
			item.schedulePlan = this.state.schedulePlan;

			if (this.state.recurrence.weekRecurrence) {
				if (this.state.recurrence.weekRecurrence.length !== this.state.originalDayLength) {
					error = true;
				}
				item.schedulePlan.weekDays = this.state.recurrence.weekRecurrence.toString();
			}
			item.schedulePlan.processingScheduleType = 'SCHEDULE';
			if (item.schedulePlan.recurrenceType === 'INTRADAY') {
				item.schedulePlan.recurrenceType = 'WEEK';
				item.schedulePlan.processingScheduleType = 'INTRADAY';
				item.schedulePlan.every = 1;
			}
			if (this.state.recurrence.monthRecurrence) {
				item.schedulePlan.monthDay = this.state.recurrence.monthRecurrence;
			}
			return item;
		} else {
			item.schedulePlan.processingScheduleType = 'SCHEDULE';
			if (item.schedulePlan.recurrenceType === 'INTRADAY') {
				item.schedulePlan.recurrenceType = 'WEEK';
				item.schedulePlan.processingScheduleType = 'INTRADAY';
				item.schedulePlan.every = 1;
			}
			return item;
		}
	}

	onSavedPlanState = (recurrence) => {
		let endDate = null;
		if (recurrence.finish === '3') {
			endDate = moment(recurrence.endDate || this.state.endDate).format(dateToServer);
		}
		const recurrenceMap = {
			...this.state.recurrence,
			startDate: moment(this.state.recurrence.startDate).format(dateToServer),
			endDate,
		}
		return {
			id: this.state.id,
			plan: this.getPlan(this.state.plan),
			itemRequestDetails: this.requestedPlan(),
			startDate: moment(this.state.startDate).format(dateToServer),
			endDate,
			companyIdPartnership: this.state.lender,
			appointmentItemRequestStatus: 'CREATED',
			startTime: moment(this.state.startTime, 'hh:mm a').format('HH:mm'),
			endTime: moment(this.state.endTime, 'hh:mm a').format('HH:mm'),
			recurrence: recurrenceMap,
			observations: this.state.observations,
			file:this.state.file
		}
	}

	savePlan(force) {
		this.setState({ busy: true });
		if (!force && (this.props.appointmentAction === 'assign_agenda')) {
			if (this.state.changedStartDate &&
				((this.state.recurrence.endType && this.state.recurrence.endType !== 'OCURRENCES') ||
					(this.state.recurrence.finishOcurrences != null && this.state.recurrence.finishOcurrences == ''))) {
				this.setState({ showAlertChangeDate: true, busy: false });
				return;
			}
		}
		if (this.props.appointmentAction === 'assign_agenda') {
			if (this.isAnInvalidPlan()) {
				return this.setState({ savePlan: true });
			}
	
			const error = this.onSavePlanError();
			if (error) {
				return;
			}

			const items = this.props.items.map((item) => {
				return this.buildItemsOnSavePlanAssingAgenda(item, error);
			});
			this.props.onUpdatePlan(items);
			return;
		}

		if (this.props.appointmentAction === 'edit' || this.props.appointmentAction === 'copy') {
			this.onSavePlanEditCopy();
			return;
		}

		if (!this.isAnInvalidPlan()) {
			const { recurrence } = this.state;
			let estimatedDuration = 1;
			if (this.state.plan) {
				const planObj = this.mapPlan(this.state.plan);
				estimatedDuration = planObj.estimatedDuration;
			}
			if (this.state.recurrence.finish) {
				const isNotOcurrences = this.state.recurrence.finish === '1' ? 'DATE' : null;
				let endType = (this.state.recurrence.finish === '2' ? 'OCURRENCES' : isNotOcurrences);
				if (recurrence.finish === '3') {
					endType = 'DATE';
				}
				recurrence.endType = endType;
			} else if (!this.state.recurrence.finish) {
				if (this.state.recurrence.ocurrences !== '') {
					recurrence.finish = '2';
				} else {
					recurrence.finish = '1';
				}
			}

			recurrence.processingScheduleType = 'SCHEDULE';
			if (recurrence.recurrenceType === 'INTRADAY') {
				recurrence.processingDurationTime = estimatedDuration * this.state.recurrence.quantityIntraday;
				recurrence.every = 1;
				recurrence.processingScheduleType = 'INTRADAY';
			}
			
			this.setState({ savePlan: false, recurrence }, () => {
				const plan = this.onSavedPlanState(recurrence);
				this.props.onSavedPlan(plan);
			});
			return;
		}
		this.setState({ savePlan: true, busy: true });

	}

	getPracticeTypes() {
		this.props.onGetPracticeTypes();
	}

	formatPracticeTypes = (practiceTypes) => {
		const result = practiceTypes.map(pt => {
			return {
				id: pt.practiceTypeId,
				value: pt.name,
				estimatedDuration: pt.estimatedDuration,
			}
		})
		return result;
	}

	setPartnership = (data) => {
		const partnership = data.map(p => ({
			id: p.companyIdPartnership.companyId,
			value: p.companyIdPartnership.name,
			code: p.companyIdPartnership.partnerCode.toString(),
		}));

		const id = parseInt(localStorage.getItem('itlg_default_company_id'));
		const value = localStorage.getItem('itlg_default_company_name');
		const code = id.toString();
		const partnershipArray = [{ id, value, code }, ...partnership];

		return partnershipArray;
	}

	getPartnership = (practiceTypeId) => {
		if (practiceTypeId == null) {
			return;
		}
		this.setState({ third_parties: [] });
		AppointmentApiInvoker.getCompanyPartnership(practiceTypeId, data => {
			const third_parties = this.setPartnership(data);
			const third_parties_codes = third_parties.map(tp => ({
				id: tp.id,
				value: tp.code
			}));
			const { lender } = this.state;
			const newLender = !lender && third_parties.length ? third_parties[0].id : lender;
    this.setState({ third_parties, third_parties_codes, lender: newLender });
		}, () => {
			this.setState({ third_parties: [], third_parties_codes: [] });
		});
	}

	setPartnershipDefault = () => {
		const { lender, third_parties } = this.state;
		const newLender = !lender && third_parties.length ? third_parties[0].id : lender;
		this.setState({ lender: newLender });
	}

	getCompanyPracticeTypes = () => {
		AppointmentApiInvoker.getPracticeTypes(data => {
			this.setState({ companyPracticesType: this.formatPracticeTypes(data) });
		}, (e) => {
			this.setState({ companyPracticesType: [] });
		});
	}

	formatProfessionals = (professionals) => professionals.map(pt => ({
		id: pt.personId,
		value: `${pt.lastName} ${pt.firstName}`,
	}));

	getPlan = (id) => this.state.plans.find((p) => p.id === id);

	asignProfessionals = () => this.setState({ openSuggestedProfessionals: true });
	asignDrivers = () => this.setState({ openSuggestedDrivers: true });

	setInvalidPlan = (value) => this.setState({ invalidPlan: value });

	showButtonAssignProf = () => {
		const { appointmentAction } = this.props;
		const { lender, plan, recurrence } = this.state;
		const companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
		let isMe = false;
		let companyPartnerShip = parseInt(localStorage.getItem('company_partnership_id'));
		if (companyPartnerShip == null) {
			companyPartnerShip = companyId;
		}
		if (lender == companyPartnerShip) {
			isMe = true;
		}
		return plan && recurrence.recurrenceType && ((lender == companyId) || (isMe && appointmentAction === 'assign_agenda'));
	}

	isDisabledAgenda = (appointmentAction) => {
		let disabledAgenda = appointmentAction === 'assign_agenda';
		if (!disabledAgenda && appointmentAction === 'edit') {
			if (this.props.current.appointmentItemRequestStatus !== 'CREATED') {
				let companyPartnerShip = localStorage.getItem('company_partnership_id');
				if (this.props.current.appointmentItemRequestStatus === 'REJECT' && companyPartnerShip != null)
					disabledAgenda = true;
			}
		}
		if (!disabledAgenda && appointmentAction === 'edit' && this.props.current.appointmentItemRequestStatus == 'APROVEED') {
			disabledAgenda = true;
		}
		return disabledAgenda;
	}

	render() {
		const { t, appointmentAction, customer, dates, items, appointmentStatus } = this.props;
		const { busy, companyPracticesType, errorStartDateMsg, plan, plans, savePlan, invalidPlan, itemRequestDetails, lender, openSuggestedProfessionals, startDate, startTime, endTime, endDate, showAlertChangeDate, recurrence, third_parties, third_parties_codes, file } = this.state;
		const selectedPlan = plans.filter(f => (f.id === plan))[0];
		const selectedDuration = plans.filter(f => (f.id === plan))[0];
		const showAssignProf = this.showButtonAssignProf();
		const disabledAgenda = this.isDisabledAgenda(appointmentAction);
		const estimatedValue = `${moment.utc(moment.duration(parseInt(selectedDuration?.estimatedDuration), 'minutes').asMilliseconds()).format('HH:mm')} ${t('estimated_minutes')}`; 
		const isDisabledLender = selectedPlan == undefined || disabledAgenda;
		return (
			<GridContainer className="form-practice-container">
				<GridItem xs={12} sm={6}>
					<GridItem xs={12} className="no-padding">
						<SelectInput
							id="plan"
							className="no-padding"
							disabled={appointmentAction === 'assign_agenda' || disabledAgenda}
							label={t("appointment.new.schedule.practice.practice")}
							elements={companyPracticesType}
							value={plan}
							onSelectedValue={(value) => this.handleValue(value, 'plan')}
							invalid={!plan && savePlan}
							errorText={!plan && savePlan ? t('error.required') : ''}
						/>
					</GridItem>

					<GridItem xs={12} className="no-padding">
						{(plan) &&
							<ValidationInput
								id="estimated-label"
								text={t('estimated_label')}
								value={estimatedValue}
								disabled
							/>
						}
					</GridItem>
					{appointmentAction !== 'assign_agenda' &&
						<GridItem xs={12} className="no-padding">
							<h4 className="padding-horizontal">{t('appointment.new.schedule.practice.lender')}</h4>
							<SelectInput
								id="lender"
								className="no-padding"
								label={t('appointment.new.schedule.practice.lender')}
								elements={third_parties}
								disabled={isDisabledLender}
								value={lender}
								onSelectedValue={(value) => this.handleValue(value, 'lender')}
							/>

							<SelectInput
								id="lender"
								className="no-padding"
								label={t('appointment.new.schedule.practice.lender.provider.code')}
								elements={third_parties_codes}
								disabled={isDisabledLender}
								value={lender}
								onSelectedValue={(value) => this.handleValue(value, 'lender')}
							/>
						</GridItem>
					}
					
				</GridItem>

				<GridItem xs={12} sm={6}>
					<GridItem xs={12} className="base-font availability-label">
						<h4 className="padding-horizontal">{t('appointment.new.schedule.practice.availability')}</h4>
					</GridItem>
					<GridItem xs={12} className="date-input-grid date-size">
							<DateInput
								id="practice-start"
								text={t('appointment.new.schedule.practice.start_date')}
								minDate min={dates ? dates.startDate : null}
								max={dates ? dates.endDate : null}
								value={startDate}
								disabled={disabledAgenda}
								onChangeValue={(value) => this.handleValue(value, 'startDate')}
								error={this.state.isErrorStartDate || (!startDate && savePlan)}
								errorText={this.state.isErrorStartDate ? errorStartDateMsg : (!startDate && savePlan) ? t('error.required') : ''}
							/>
					</GridItem>
					
					<GridContainer>
							<GridItem xs={12} md={6} className="start-time plan">
								<TimePickerGCalendar
									name="practice_start_time"
									text={t('appointment.new.schedule.practice.start_time')}
									value={startTime}
									onSelectedValue={(value) => this.handleValue(value, 'startTime')}
									defaultValue="12:00 am"
									invalid={!startTime}
									errorText={!startTime ? t('error.required') : ''}
								/>
							</GridItem>

							<GridItem xs={12} md={6} className="end-time plan">
								<TimePickerGCalendar
									name="practice_end_time"
									text={t('appointment.new.schedule.practice.end_time')}
									value={endTime}
									onSelectedValue={(value) => this.handleValue(value, 'endTime')}
									defaultValue="11:30 pm"
									invalid={!endTime}
									errorText={!endTime ? t('error.required') : ''}
								/>
							</GridItem>
					</GridContainer>
				</GridItem>

				<GridItem xs={12} className="no-padding plan-content">
					<SchedulePlan
						className="schedule-plan-component"
						appointmentAction={appointmentAction}
						appointmentStatus={appointmentStatus}
						practiceDuration={selectedDuration?.estimatedDuration}
						current={recurrence}
						endDate={endDate}
						dates={dates}
						disabledAgenda={disabledAgenda}
						indexArrayDays={0}
						onCreatedRecurrence={(value) => this.handleValue(value, 'recurrence')}
						onCreatedObservations={(value) => this.handleValue(value, 'observations')}
						observations={this.state.observations}
						savePlan={savePlan}
						invalidPlan={(value) => this.setInvalidPlan(value)}
						insideModule={false}
						setInvalidNumDays={ this.setInvalidNumDays}
						errorEndDate={{ isError: this.state.isErrorEndDate, message: this.state.errorEndDateMsg }}
					/>
				</GridItem>

				
				<GridItem xs={12} className="no-padding plan-content">
					<ModalFileUpload
						onSelectedValue={(value) => this.handleValue(value, 'file')}
						file={file}
						endDate={endDate}
					/>
				</GridItem>

				<GridItem className="schedule-form-submit plan" xs={12}>
						
							
							<Button id="button-employees" disabled={!showAssignProf} color="primary" simple onClick={() => this.asignProfessionals()}>
								{t('suggested.assignProfessionals')}
							</Button>

						
						<ButtonSpinner
							id="button-save"
							onClick={() => this.savePlan(false)}
							disabled={busy || this.isAnInvalidPlan() || invalidPlan || this.state.isErrorStartDate || this.state.isErrorEndDate || this.state.invalidNumDays}
							label={t('appointment.new.schedule.practice.save')}
							labelLoading={t('appointment.new.schedule.practice.saving')}
							loading={busy}
							typeButton="submit"
							color="primary"
						/>
				</GridItem>
	
				<ModalSuggestedProfessionals
					open={openSuggestedProfessionals}
					appointmentAction={appointmentAction}
					items={items}
					customer={customer}
					lender={this.state.lender}
					recurrence={recurrence ?? []}
					onCloseModal={() => this.setState({ openSuggestedProfessionals: false })}
					onConfirmModal={(value) => this.setState({ itemRequestDetails: value, openSuggestedProfessionals: false })}
					practiceType={plan && selectedPlan}
					itemRequestDetails={itemRequestDetails}
					onChangeValue={(value, state, i) => this.handleValue(value, state, i)}
				/>
				<ModalSuggestedDrivers
					open={this.state.openSuggestedDrivers}
					appointmentAction={appointmentAction}
					items={items}
					customer={customer}
					lender={this.state.lender}
					recurrence={recurrence ?? []}
					onCloseModal={() => this.setState({ openSuggestedDrivers: false })}
					onConfirmModal={(value) => this.setState({ itemRequestDetails: value, openSuggestedDrivers: false })}
					practiceType={plan && selectedPlan}
					itemRequestDetails={itemRequestDetails}
					onChangeValue={(value, state, i) => this.handleValue(value, state, i)}
				/>

				{showAlertChangeDate ?
					<CustomSweetAlert
						type="warning"
						title={t('appointment.edit.changequantityschedule.title')}
						onConfirm={() => this.savePlan(true)}
						confirmBtnCssClass="btn primary"
						confirmBtnText={t('common.accept')}
						onCancel={() => this.setState({ showAlertChangeDate: false })}
						cancelBtnCssClass="btn danger"
						cancelBtnText={t('cancel')}
						showCancel={true}
						message={
							<div className="schedule-errors-list">
								<span className="schedule-errors-item">
									{t('appointment.edit.changequantityschedule.message')}
								</span>
								<span className="schedule-errors-item">
									{t('appointment.edit.changequantityschedule.question')}
								</span>
							</div>
						}
					/>
					: <div />
				}
			</GridContainer>
		)
	}
}

Plan.defaultProps = {
	dates: {},
}

Plan.propTypes = {
	t: PropTypes.func,
	appointmentAction: PropTypes.string,
	current: PropTypes.object,
	dates: PropTypes.object,
	onSavedPlan: PropTypes.func,
	practiceTypes: PropTypes.array,
	onUpdatePlan: PropTypes.func,
	onGetPracticeTypes: PropTypes.func,
	customer: PropTypes.object,
	items: PropTypes.array,
	appointmentStatus: PropTypes.string,
}

export default withTranslation()(Plan);
