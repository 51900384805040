import { AppointmentItem as Item, RecurrenceType } from '../../../core/appointments';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadCloudIcon from '../../../icons/DownloadCloudIcon';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';



type DaysObject = {
  [key: string]: string;
};

const days: DaysObject = {
  'L': 'monday',
  'M': 'tuesday',
  'X': 'wednesday',
  'J': 'thursday',
  'V': 'friday',
  'S': 'saturday',
  'D': 'sunday',
}

interface AppointmentItemProps {
  item: Item;
  companyPartnership:number;
}


const AppointmentItem = ({ item, companyPartnership }: AppointmentItemProps) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<any>(null)
  
  useEffect(() => {
    AppointmentApiInvoker.getFile(item.idItemRequest, (data: any) => {
      
      setFile(data[0])
    })
  }, [])
  

  const getQuantityIntraday = (): string => {
    if (!item?.quantityIntraday) {
      return '';
    }
    const quantityIntradayCount = item?.quantityIntraday > 1 ? '_plural' : '';
    const translateKeyQuantity = `label.recurrence.intraday.numberAppo${quantityIntradayCount}`;
    return `${t(translateKeyQuantity, { number: item?.quantityIntraday })}`;
  }

  const getRecurrenceDescription = (): string  => {
    if (!item?.every) {
      return '';
    }
    const everyCount = item?.every > 1 ? 'every_plural' : 'every';
    const everyText = t(`label.recurrence.${item?.recurrenceType?.toLowerCase()}.${everyCount}`, { every: item.every });
    const quantityIntraday = getQuantityIntraday();
    const recurrenceDescription = quantityIntraday || everyText;
    return recurrenceDescription;
  }

  const getRecurrenceEvery = () => {
    if (!item?.every) {
      return {
        recurrenceDescription: null,
        recurrenceMonthDays: null,
      }
    }

    const recurrenceDescription = getRecurrenceDescription();
    const isMonth = item.recurrenceType === RecurrenceType.MONTH && item?.monthDay;
    const recurrenceMonthDays =  isMonth ?
      t(`label.recurrence.${item?.recurrenceType?.toLowerCase()}.day`, { monthDay: item.monthDay })
      : null;

    return {
      recurrenceDescription,
      recurrenceMonthDays,
    }
  }

  const getRecurrence = (): JSX.Element | undefined => {
    if (!item.recurrenceType || item.recurrenceType === 'null') {
      return;
    }
    const type = item?.processingScheduleType === 'INTRADAY' ? item?.processingScheduleType :  item.recurrenceType;
    const recurrence = t(`label.recurrence.${type.toLowerCase()}`);
    const { recurrenceDescription, recurrenceMonthDays } = getRecurrenceEvery();
    return (
      <>
        <span>{recurrence}</span>
        {recurrenceDescription && <span>{recurrenceDescription}</span>}
        {recurrenceMonthDays && <span>{recurrenceMonthDays}</span>}
      </>
    );
  }

  const sortDays = (dDays: string[]): string[] => {
    const keysArray = Object.keys(days);
    return dDays.sort((a, b) => {
      const indexA = keysArray.indexOf(a);
      const indexB = keysArray.indexOf(b);
      if (indexA === -1) {
        return 1;
      }
      if (indexB === -1) {
        return -1;
      }
      return indexA - indexB;
    });
  }

  const getDayByKey = (key: string): string => days[key];
  
  const getStatus = (): JSX.Element => {
    const label = t('status');
    const status = t(`status.appointmentItemRequest.${item.practiceStatus}`);
    return <span><strong>{label}:</strong> {status}</span>;
  }

  const getFiles = (): JSX.Element => {
    const fileName = file?.name;
    const htmlContent = (file?.name && (companyPartnership === null ||item.partnership == companyPartnership) ) ? 
                          <span style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <DownloadCloudIcon />
                            <a href={file?.file} download={fileName} style={{textDecoration:'none',color:'#3C4858'}}>
                              {fileName}
                            </a>
                            </span> : 
                            <></>;
    return htmlContent;
  }

  const onLender = (partnership?: number): boolean => {
    const companyPartnerShip = localStorage.getItem('company_partnership_id');
    const isLender = (companyPartnerShip != null) && (parseInt(companyPartnerShip) != partnership);
    return isLender;
  }

  const getPartnership = (): JSX.Element => {
    let partnershipName = item.partnershipName;
    const isLender = onLender(item.partnership);
    if (isLender) {
      partnershipName = t('common.other');
    }
    const label = t('label.provider');
    return  <span><strong>{label}:</strong> {partnershipName}</span>;
  }

  const getDays = (): JSX.Element | undefined => {
    const { weekDays, recurrenceType } = item;
    const isRecurrence = recurrenceType && (recurrenceType === RecurrenceType.DAY || recurrenceType === RecurrenceType.MONTH);
    if (!weekDays || isRecurrence) {
      return;
    }
    const arr: string[] = weekDays.split(',');
    const uniqueDays = Array.from(new Set(arr));
    const orderDays = sortDays(uniqueDays);
    const stringDays = orderDays.map((d: string) => t(`day.${getDayByKey(d)}`)).join(', ');
    const label = t('label.recurrence.days', { days: stringDays })
    return <span>{label}</span>;
  }
  
  const getObservations = (): JSX.Element | undefined => {
    if (!item?.observations) {
      return;
    }
    if (onLender(item.partnership)) {
      return;
    }
    const labelObservations = t('label.observations');
    return <span><strong className="label">{labelObservations}:</strong> {item?.observations}</span>;
  }

  return (
    <li>
      <strong>{item.name}</strong>
      <p className="text-muted">
        {getRecurrence()}
        {getDays()}
        {getStatus()}
        {getPartnership()}
        {getObservations()}
        {getFiles()}
      </p>
    </li>
  )
}

AppointmentItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default AppointmentItem;
