import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './Chat.css';


class Chat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chatUrl: '',
        };
    }
    componentDidMount() {
        this.fetchChatUrl();
    }
    
    fetchChatUrl = async () => {

        const requestOptions = {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
          
         };
        let r = await fetch('/chat-url', requestOptions)
        .then((response) => {
            // Verificar si la respuesta es exitosa
            if (!response.ok) {
                console.error('Error en la respuesta:', response.statusText);
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            this.setState({ chatUrl: data.url });
        })
        .catch((error) => {
            console.error('Error al obtener la URL del chat:', error);
        });
    };
    

    render() {
        const { isVisible } = this.props;
        const { chatUrl } = this.state;

        return (
            <div
                style={{
                    zIndex: 9999,
                    position: 'fixed',
                    display: isVisible ? 'block' : 'none',
                    border: '2px solid rgba(0, 0, 0, 0.05)',
                    top: 15,
                    right: 5,
                    height: '85%',
                    width: '25%',
                }}
                id="chat-container"
            >
           <iframe
                    width='100%'
                    height='100%'
                    src={chatUrl || 'about:blank'} // Mostrar un iframe vacío si chatUrl no está disponible
                    id="chat-iframe"
                ></iframe>
            </div>
        );
    }
}

export default withTranslation()(Chat);
