import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'calc(100vh - 120px)',
    margin: theme.spacing(2, 'auto'),
    maxWidth: 400,
    padding: theme.spacing(2, 3),
  },
  containerMessage: {
    textAlign: 'center',
  },
  heartIcon: {
    fontSize: '3rem',
    margin: theme.spacing(2, 0),
  },
  contentMessage: {
    color: '#555',
    fontSize: '2rem',
    padding: theme.spacing(2, 5),
    '& strong': {
      display: 'block',
      fontSize: '3rem',
    },
    '& span': {
      display: 'block',
      fontSize: '1.5rem',
    },
    '& small': {
      display: 'block',
      fontSize: '2rem',
    },
  },
}));
