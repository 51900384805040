import { createStyles, Theme } from '@material-ui/core/styles';
import { blackColor } from '../../../../assets/components/material-dashboard-pro-react';

const customCheckboxRadioSwitch = (theme: Theme) => 
  createStyles({
    checkRoot: {
      padding: theme.spacing(1),
    },
    radioRoot: {
      padding: theme.spacing(1),
    },
    checkboxAndRadio: {
      position: 'relative' as const,
      display: 'flex',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    checkboxAndRadioHorizontal: {
      display: 'flex',
      alignItems: 'center',
    },
    radioUnchecked: {
      width: "16px",
      height: "16px",
      color: "transparent !important",
      border: `1px solid #5DC4B9`, 
      borderRadius: "50%",
      backgroundColor: "transparent", 
    },
    radioChecked: {
      width: "16px",
      height: "16px",
      border: `1px solid #5DC4B9`, 
      borderRadius: "50%",
      backgroundColor: "#C1EBE6", 
    },
    radio: {
      color: `#C1EBE6 !important`, 
    },
    disabledCheckboxAndRadio: {
      "& $radioUnchecked, & $radioChecked": {
        borderColor: blackColor,
        opacity: "0.26",
        color: blackColor,
      },
    },
    radioGroup: {
      display: 'flex',
      justifyContent: 'center', 
      flexDirection: 'column', 
      alignItems: 'center', 
    },
  });

export default customCheckboxRadioSwitch;
