import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { surveyForms } from '../constants'
import { FormKey, Section, SurveyResponse } from '../interfaces/interface'
import { useTranslation } from 'react-i18next'

export const useSurveys = (email: string, companyId: string, lang: string) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0)
  const [isNextButtonDisabled, setIsNextButtonDisabled] =
    useState<boolean>(true)
  const [personSurveyId, setPersonSurveyId] = useState<number>()
  const [sections, setSections] = useState<Section[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [showFinishScreen, setShowFinishScreen] = useState<boolean>(false)
  const [error, setError] = useState({
    error: false,
    title: '',
    description: '',
  })

  const { t } = useTranslation()
  const title = t('survey.title')
  const send = t('survey.send')
  const next = t('table.next')
  const exit = t('appointment.tools.exit.tittle')
  const confirm = t('survey.button.confirm')
  const cancel = t('common.cancel')
  const messageModal = t('survey.message.confirm')
  const labelLoading = t('common.saving')
  const questions = sections.flatMap((section) => section.questions)
  const errorServiceText = t('common.errorService')
  const titleError = t('common.error')
  const acceptText = t('common.accept')

  const defaultValues = questions.reduce((acc, question) => {
    acc[question.fieldName] = question.type === 'rating' ? null : ''
    return acc
  }, {} as { [key: string]: any })

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
    getValues,
  } = useForm({
    defaultValues,
  })
  const watchAllFields = watch()

  useEffect(() => {
    const fetchResponses = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(`/persons/survey/${email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-itlg-companyId': companyId,
          },
        })
        if (!response.ok) {
          setError({
            error: true,
            title: titleError,
            description: errorServiceText,
          })
          throw new Error(`Error: ${response.status} ${response.statusText}`)
        }
        const data: SurveyResponse[] = await response.json()

        if (!data || data.length === 0) {
          setError({
            error: true,
            title: titleError,
            description: 'No se encontró información de la encuesta',
          })
          throw new Error('No se encontró data de encuesta')
        }
        setPersonSurveyId(data[0].personSurveyId)
        const surveyData = data[0]
        const matchingSections =
          surveyForms[surveyData.surveyVersion as FormKey]
        if (!matchingSections) {
          setError({
            error: true,
            title: titleError,
            description:
              'No se encontró un formulario correspondiente a la versión de la encuesta',
          })
          console.warn(
            'No se encontró un formulario correspondiente a la versión de la encuesta'
          )
          return
        }

        setSections(matchingSections)

        const userResponses = surveyData.responseList.reduce(
          (acc, responseItem) => {
            const questionType = questions.find(
              (q) => q.fieldName === responseItem.codeQuestion
            )?.type

            acc[responseItem.codeQuestion] =
              questionType === 'rating'
                ? Number(responseItem.value) || null
                : responseItem.value
            return acc
          },
          {} as { [key: string]: any }
        )

        questions.forEach((question) => {
          const userResponse = userResponses[question.fieldName]
          setValue(
            question.fieldName,
            userResponse !== undefined ? userResponse : ''
          )

          if (question.type === 'radio' && question.detailInfo) {
            const detailResponse = userResponses[question.detailInfo.fieldName]
            setValue(
              question.detailInfo.fieldName,
              detailResponse !== undefined ? detailResponse : ''
            )
          }
        })
      } catch (error) {
        setError({
          error: true,
          title: titleError,
          description: errorServiceText,
        })
        console.error('Error al obtener las respuestas:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchResponses()
  }, [setValue, email, sections])

  useEffect(() => {
    if (
      !sections.length ||
      currentSectionIndex < 0 ||
      currentSectionIndex >= sections.length
    ) {
      setIsNextButtonDisabled(true)
      return
    }
    const currentQuestions = sections[currentSectionIndex].questions
    const currentQuestionFieldNames = currentQuestions.map((q) => q.fieldName)

    currentQuestionFieldNames.forEach((fieldName) => {
      register(fieldName)
    })

    const validateSection = async () => {
      let isValid = await trigger(currentQuestionFieldNames)

      const currentValuesValid = currentQuestions.every((question) => {
        const value = watch(question.fieldName)

        if (question.type === 'rating') {
          return (
            value !== null &&
            value !== '' &&
            typeof value === 'number' &&
            !isNaN(value)
          )
        }

        if (value === null || value === '') {
          return false
        }

        if (question.type === 'radio' && question.detailInfo) {
          const detailValue = watch(question.detailInfo.fieldName)
          return value === 'yes'
            ? detailValue !== null && detailValue !== ''
            : true
        }

        return true
      })

      if (
        currentSectionIndex === sections.length - 1 &&
        isValid &&
        currentValuesValid
      ) {
        setIsCompleted(true)
      } else {
        setIsCompleted(false)
      }
      setIsNextButtonDisabled(!isValid || !currentValuesValid)
    }

    validateSection()
  }, [currentSectionIndex, sections, register, trigger, watchAllFields])

  const goToNextSection = () => {
    if (currentSectionIndex < sections.length - 1) {
      setCurrentSectionIndex((prev) => prev + 1)
    }
  }

  const goToPreviousSection = () => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex((prev) => prev - 1)
    }
  }
  const goBack = () => {
    const webView = (window as any).ReactNativeWebView
    if (webView && isCompleted) {
      webView.postMessage(JSON.stringify({ type: 'goBackComplete' }))
    } else if (webView && !isCompleted) {
      webView.postMessage(JSON.stringify({ type: 'goBackInprogress' }))
    }
  }

  const progress = ((currentSectionIndex + 1) / sections.length) * 100

  const onSubmit = async (data: any) => {
    const responseDtoList = Object.keys(data).map((key) => ({
      codeQuestion: key,
      value: data[key] || '',
    }))

    const dataToSend = {
      personSurveyId: personSurveyId,
      responseDtoList: responseDtoList,
      completeSurvey: isCompleted,
    }

    try {
      setIsLoading(true)
      const response = await fetch('/persons/survey/reponse', {
        method: 'PATCH',
        body: JSON.stringify(dataToSend),
        headers: {
          'Content-Type': 'application/json',
          'x-itlg-companyId': companyId,
        },
      })

      if (!response.ok) {
        setError({
          error: true,
          title: titleError,
          description: errorServiceText,
        })
        throw new Error('Error en la respuesta del servidor')
      } else {
        const result = await response.json()
        if (result && isCompleted) {
          setShowFinishScreen(true)
        } else {
          goBack()
        }
      }
    } catch (error) {
      setError({
        error: true,
        title: titleError,
        description: errorServiceText,
      })
      console.error('Error al enviar los datos:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleModalCancel = () => {
    setShowModal(false)
  }

  return {
    control,
    progress,
    sections,
    isNextButtonDisabled,
    currentSectionIndex,
    isLoading,
    title,
    send,
    next,
    exit,
    showModal,
    confirm,
    cancel,
    messageModal,
    showFinishScreen,
    isCompleted,
    labelLoading,
    error,
    acceptText,
    handleSubmit,
    onSubmit,
    register,
    goToNextSection,
    goToPreviousSection,
    handleModalCancel,
    setShowModal,
    getValues,
    setError,
    goBack,
  }
}
