import { connect } from 'react-redux';
import {
  getAppointments,
  deleteAppointment,
  submitAppointment,
  selectAppointment,
  selectAppointmentList,
  selectAppointmentObject,
  getSchedulesAppointment,
  SaveSchedulesAppointment,
  cleanAppointments,
} from '../actions/appointments';
import {
  fetchPatologies,
  fetchComplexities,
  fetchBarthels
} from '../actions/records';
import Appointments from '../components/Appointments';
import AppointmentsPractice from '../components/Appointments/index_practice';
import AppointmentDetail from '../components/Appointments/AppointmentDetail';
import AppointmentCancel from '../components/Appointments/AppointmentCancel/AppointmentCancel';
import { saveSchedule } from '../actions/newSchedule';
import Filters from '../components/Appointments/Filters';

const mapStateToProps = (state) => {
  return {
    appointments: state.appointments,
    patologies: state.records.patologies,
    complexities: state.records.complexities,
    barthels: state.records.barthels,
    selectedAppointment: state.appointments.selectedAppointment,
    selectedSchedules: state.appointments.selectedSchedules,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onGetComplexities: (complexities) => {
      dispatch(fetchComplexities(complexities));
    },
    onGetPatologies: (patologies) => {
      dispatch(fetchPatologies(patologies));
    },
    onCleanAppointments: () => {
      dispatch(cleanAppointments());
    },
    onGetBarthels: (barthels) => {
      dispatch(fetchBarthels(barthels));
    },
    onGetAppointments: appointments => {
      dispatch(getAppointments(appointments));
    },
    onSelectAppointment: appointmentId => {
      dispatch(selectAppointment(appointmentId));
    },
    onSelectAppointmentList: appointmentId => {
      dispatch(selectAppointmentList(appointmentId));
    },
    onGetSchedulesAppointment: schedules => {
      dispatch(getSchedulesAppointment(schedules));
    },
    onSaveSchedulesAppointment: schedule => {
      dispatch(SaveSchedulesAppointment(schedule));
    },
    onSelectAppointmentObject: appointment => {
      dispatch(selectAppointmentObject(appointment));
    },
    onDeleteAppointment: appointmentId => {
      dispatch(deleteAppointment(appointmentId));
    },
    onSubmitAppointment: appointment => {
      dispatch(submitAppointment(appointment));
    },
    onSaveBookings: () => {
      dispatch(saveSchedule());
    },
  }
}

const AppointmentsService = connect(
  mapStateToProps,
  mapDispatchToProps
)(Appointments);

const AppointmentsPracticeService = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentsPractice);

const AppointmentDetailService = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentDetail);

const AppointmentCancelService = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentCancel);

export const FiltersService = connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters);

export default { AppointmentsService, AppointmentsPracticeService, AppointmentDetailService, AppointmentCancelService };
