import { getColor, initialAlertWarning } from '../../data';

import SchedulerInvoker from '../../../../api/SchedulerInvoker';
import _ from 'lodash';
import config from '../../../../config/config';
import moment from 'moment';
import { useState } from 'react';

const dateTimeSmallFormat = config.getDateTimeSmallFormat();
const dateToServer = config.getDateToServer();

const useCalendarStep = (clearInformation, handleChange, handleNext, showSnackbarNotification, values, t) => {
  const {
    bookings, bookingsError, oevents, practicesType, professionalsSelected, schedulesSelectedData,
    events,
    bookingChanged,
    currentEvent,
  } = values;

  const [openDetail, setOpenDetail] = useState(false);
  const [updateErrors, setUpdateErrors] = useState(null);
  const [status, setStatus]  = useState(null);

  const formatPracticeName = (practiceTypeId, genericName) => {
    const result = practicesType.filter(pt => pt.practiceTypeId === practiceTypeId);
    if (result.length > 0) {
      return result[0]['name'];
    } else {
      return genericName;
    }
  }

  const addBooking = (book, id) => !book.includes(id) && book.push(id);

  const reduceBookingsOk = (dt, book) => dt.reduce((prev, parent) => {
    const bookingData = parent.bookings.filter(f => f.status !== 'ERROR').map((e) => {
      addBooking(book, parent.appointmentBookingId);
      return {
        id: e.scheduleBookingId,
        title: `${moment(e.scheduleDateTime).format('hh:mm a')} - ${e.label}`,
        allDay: false,
        time_display: `${moment(e.scheduleDateTime).format('hh:mm a')} - ${moment(e.scheduleEndDateTime).format('hh:mm a')}`,
        time_start: moment(e.scheduleDateTime).format('hh:mm a'),
        start: new Date(e.scheduleDateTime),
        end: new Date(e.scheduleEndDateTime),
        color: getColor(e),
        employeeId: e.employeeId,
        practiceTypeId: 1,
        status: !e.status ? 'OK' : e.status,
        appointmentBookingId: parent.appointmentBookingId,
        scheduleDateTime: e.scheduleDateTime,
      };
    });
    return prev.concat(bookingData);
  }, []);

  const reduceBookingsList = (dt, book, type) => dt.reduce((prev, parent) => {
    const dataFilter = (f) => type === 'OK' ? (f.status != 'ERROR') : (f.status === 'ERROR');
    const bookingData = parent.bookings.filter(f => dataFilter(f)).map((e) => {
      addBooking(book, parent.appointmentBookingId);
      let message = "";
      if (e.messageError != null) {
        let values = e.messageError.split("/");
        message = t('common.scheduleNumber') + ":" + values[0] + " " +
                  t('common.startTime') + " :" + values[1] + " " +
                  t('common.endTime') + " :" + values[2] + " ";
      }
      return {
        customer: `${parent.customerLastName} ${parent.customerFirstName}`,
        appointmentId: parent.appointmentId,
        scheduleId: e.originalSchedulerId,
        scheduleDateTime: moment(e.scheduleDateTime).format(dateTimeSmallFormat),
        location: e.locationName,
        geographicZone: e.geographicZoneName,
        messageError: message,
        practiceTypeName: (e.practiceTypeId ? formatPracticeName(e.practiceTypeId, e.practiceTypeName) : ''),
      };
    });
    return prev.concat(bookingData);
  }, []);

  const formatTableDataCalendar = (data) => {
    let eventsData = [];
    let notAssignedData = [];
    let AssignedDataOk = [];

    let bookingsOk = [];
    let bookingsErr = [];

    if (data?.length) {
      eventsData = reduceBookingsOk(data, bookingsOk);
      notAssignedData = reduceBookingsList(data, bookingsErr, 'ERROR');
      AssignedDataOk = reduceBookingsList(data, bookingsOk, 'OK');
    }

    const oresultData = data.reduce((newArray, element) => {
      newArray.push(element);
      return newArray;
    }, []);

    handleChange('events', eventsData);
    handleChange('oevents', oresultData);
    handleChange('bookings', bookingsOk);
    handleChange('bookingsError', bookingsErr);
    handleChange('schedulesNotAssigned', notAssignedData);
    handleChange('schedulesAssigned', AssignedDataOk);
    handleChange('loadingTable', false);
  }

  const calculateReassignment = (prevStep = false) => {
    const bodySchedules = schedulesSelectedData.map((d) => {
      const scheduleDate = d.scheduleDateTimeUpdate ? d.scheduleDateTimeUpdate : d.scheduleDateTime;
      const scheduleTime = d.scheduleTimeUpdate ? moment(d.scheduleTimeUpdate, 'hh:mm a').format('HH:mm:ss') : moment(d.scheduleTime, 'HH:mm').format('HH:mm:ss');
      const scheduleDateTime = `${moment(scheduleDate).format(dateToServer)} ${scheduleTime}`;
      return {
        scheduleId: d.scheduleId,
        scheduleDateTime,
      }
    });
    const body = { scheduleIds: bodySchedules, personIds: professionalsSelected };
    handleChange('loadingevents', true);

    SchedulerInvoker.scheduleReasign(body, (data) => {
        formatTableDataCalendar(data);
        handleChange('data_saved', false);
        handleChange('loadingevents', false);
        prevStep && handleNext(true);
      }, (error) => {
        const { message, statusText } = error;
        const notificationMessage = typeof message === "string" ? message : statusText;
        showSnackbarNotification(notificationMessage, 'danger');
        handleChange('data_saved', false);
        handleChange('loadingevents', false);
      }
    );
  }

  const handleDeleteBookings = (bookingsDel) => {
    if (bookingsDel && bookingsDel.length > 0) {
      SchedulerInvoker.deleteBookingsByArray(bookingsDel, () => {
          handleChange('events', []);
          handleChange('bookings', []);
          handleChange('bookingChanged', []);
          handleChange('showConfirmSave', false);
        }, (error) => {
          handleChange('showConfirmSave', false);
          const { message, statusText }  = error;
          const notificationMessage = typeof message === "string" ? message : statusText;
          showSnackbarNotification(notificationMessage, 'danger');
        }
      );
      handleChange('hasChangedSchedules', false);
      handleChange('events', oevents);
      handleChange('showConfirmUndo', false);
    }
  }

  const handleUndoChanges = (bookingsDel) => {
    handleDeleteBookings(bookingsDel);
    calculateReassignment();
    showSnackbarNotification(t('common.undoMessageConfirm'), 'success');
  }

  const formatUpdateAppointment = (data, appointment) => {
    return data.forEach((b, i) => {
      if (b.id === currentEvent.id) {
        let item = appointment.bookings[0];
        data[i] = {
          id: item.scheduleBookingId,
          title: `${moment(item.scheduleDateTime).format('hh:mm a')} - ${item.label}`,
          allDay: false,
          time_display: `${moment(item.scheduleDateTime).format('hh:mm a')} - ${moment(item.scheduleEndDateTime).format('hh:mm a')}`,
          time_start: moment(item.scheduleDateTime).format('hh:mm a'),
          start: new Date(item.scheduleDateTime),
          end: new Date(item.scheduleEndDateTime),
          color: getColor(item.group),
          employeeId: item.employeeId,
          status: item.status,
          errorDetail: item.status === 'ERROR' || item.status === 'WARNING' 
            ? item.errorDetail : '',
          type: item.booking || item.scheduleBookingId
            ? 'booking' : 'schedule',
          scheduleDateTime: item.scheduleDateTime,
        }
      }
    });
  }

  const updateAppointment = (appointment) => {
    let result = [...events];
    setUpdateErrors(null);
    setStatus(null);

    if (appointment?.status) {
      if (appointment.status !== 'ERROR') {
        if (appointment.appointmentBookingId) {
          const bchanged = [...bookingChanged];
          if (!bchanged.includes(appointment.appointmentBookingId)) {
            bchanged.push(appointment.appointmentBookingId);
            handleChange('bookingChanged', bchanged);
          }
        }
        formatUpdateAppointment(result, appointment);
        setOpenDetail(false);
        handleChange('events', _.orderBy(result, ['start'], ['asc']));
        handleChange('hasChangedSchedules', true);
      } else {
        setUpdateErrors(appointment.errors);
        setStatus(appointment.status);
      }
    } else {
      // Handling Unexpected errors
      setUpdateErrors([{ detail: appointment.message, statusError: 'ERROR' }]);
      setStatus('ERROR');
    }
  }

  const handleOnSelectedEvent = (event) => {
    setOpenDetail(true);
    setUpdateErrors(null);
    handleChange('currentEvent', event);
  }

  const eventColors = (event) => {
    let backgroundColor = 'event-';
    backgroundColor = event.color
      ? `${backgroundColor}${event.color}`
      : `${backgroundColor}default`;
    return {
      className: backgroundColor,
    }
  }

  const confirmReassignment = () => {
    handleChange('busyButton', true);
    bookings.forEach(bookingId => {
      return SchedulerInvoker.postSaveBookings(
        bookingId,
        () => {
          const index = bookings.indexOf(bookingId);
          if (index !== -1) bookings.splice(index, 1);

          if (bookings.length === 0) {
            clearInformation();
            showSnackbarNotification(t('reassignAppointments.saveSuccess'), 'success');
          }
        }, (error) => {
          const { message, statusText }  = error;
          handleChange('busyButton', false);
          handleChange('alertWarning', initialAlertWarning);
          const notificationMessage = typeof message === "string" ? message : statusText;
          showSnackbarNotification(notificationMessage, 'danger');
        }
      );
    });
    handleDeleteBookings(bookingsError);
  }

  return {
    calculateReassignment,
    confirmReassignment,
    eventColors,
    handleDeleteBookings,
    handleOnSelectedEvent,
    handleUndoChanges,
    updateAppointment,
    openDetail,
    setOpenDetail,
    updateErrors,
    status,
  }
}

export default useCalendarStep;
