import { Control, useController } from 'react-hook-form'
import React, { useState } from 'react'

import GridContainer from '../../../Grid/GridContainer'
import GridItem from '../../../Grid/GridItem'
import { TextField } from '@material-ui/core'
import config from '../../../../config/config'

interface TextareaInputProps {
  control: Control<any>
  fieldName: string
  text: string
  disabled?: boolean
  errorText?: string
  invalid?: boolean
  clarification?: string
  maxLength?: number
}

const TextareaInputWithHookForm: React.FC<TextareaInputProps> = ({
  control,
  fieldName,
  text,
  disabled = false,
  errorText,
  invalid = false,
  clarification,
  maxLength,
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
  })

  const [localErrorText, setLocalErrorText] = useState<string | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    onChange(inputValue)
    if (config.regex_normal.test(inputValue)) {
      setLocalErrorText(
        'Error: el valor ingresado no cumple con el formato permitido.'
      )
    } else if (maxLength && inputValue.length > maxLength) {
      setLocalErrorText(
        `Error: el valor excede el máximo de ${maxLength} caracteres.`
      )
    } else {
      setLocalErrorText(null)
    }
  }

  return (
    <GridContainer style={{ justifyContent: 'center' }}>
      <GridItem xs={12} className="no-padding">
        <TextField
          multiline
          fullWidth
          disabled={disabled}
          error={invalid || Boolean(localErrorText)}
          placeholder={text}
          minRows={4}
          helperText={localErrorText || errorText}
          value={value}
          onChange={handleChange}
          margin="none"
          variant="outlined"
          inputProps={{
            maxLength,
          }}
          InputLabelProps={{
            shrink: false,
          }}
          style={{
            backgroundColor: '#f9f9f9',
          }}
        />
        {clarification && (
          <p
            style={{ fontSize: '0.85rem', color: '#666', marginTop: '0.5rem' }}
          >
            {clarification}
          </p>
        )}
      </GridItem>
    </GridContainer>
  )
}

export default TextareaInputWithHookForm
