import { CircularProgress, Grid } from '@material-ui/core'
import {
  ConfirmUserBadRequestError,
  ConfirmUserError,
  ConfirmUserNotFoundError,
  ConfirmUserUsecase,
} from '../../core'
import {
  ImagesData,
  StoreLinksData,
  TextsData,
  UserType,
  images,
  storeLinks,
  texts,
} from './constants'
import React, { FC, useEffect, useState } from 'react'
import { RemoveRedEye, VisibilityOff } from '@material-ui/icons'
import { SnackbarColor, SnackbarProps } from '../../util/interfaces'

import Button from '../CustomButtons/Button'
import { DIContainer } from '../../Root'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PropTypes from 'prop-types'
import Snackbar from '../Snackbar/Snackbar'
import appstore from '../../assets/img/appstore.png'
import clsx from 'clsx'
import googleplay from '../../assets/img/googleplay.png'
import { initialValueSnackbar } from '../../util/constants'
import { useStylesConfirmUser } from './useStylesConfirmUser'
import { useTranslation } from 'react-i18next'

type Params = {
  hash: string
}

type Location = {
  pathname: string
}
type Props = {
  params: Params
  location: Location
}

const ConfirmUser: FC<Props> = ({ params, location }) => {
  const classes = useStylesConfirmUser()
  const { t } = useTranslation()

  const confirmUsecase = DIContainer.get<ConfirmUserUsecase>(ConfirmUserUsecase)

  const [loading, setLoading] = useState<boolean>(false)
  const [hidden, setHidden] = useState<boolean>(true)
  const [userType, setUserType] = useState<UserType | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const [textsScreen, setTextsScreen] = useState<TextsData | undefined>(
    undefined
  )
  const [imagesScreen, setImagesScreen] = useState<ImagesData | undefined>(
    undefined
  )
  const [storesLinks, setStoresLinks] = useState<StoreLinksData | undefined>(
    undefined
  )
  const [layoutColor, setLayoutColor] = useState<string>('primary')

  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar)

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true })
    setTimeout(() => {
      setSnackbar(initialValueSnackbar)
    }, 5000)
  }

  const getDataErrors = (dataError: ConfirmUserError): void => {
    if (dataError instanceof ConfirmUserNotFoundError) {
      return setErrorMessage(t('confirmPass.notFoundError'))
    }
    if (dataError instanceof ConfirmUserBadRequestError) {
      const message = dataError.error
      return setErrorMessage(message)
    }
    return setErrorMessage(t('confirmPass.error'))
  }

  const getCreateUserPassword = async (): Promise<void> => {
    const indexOfCompany = params.hash.lastIndexOf('-')
    const companyId = params.hash.substring(indexOfCompany + 1)

    setLoading(true)
    const result = await confirmUsecase.execute(params.hash, companyId)
    if (result.isLeft()) {
      const dataError = result.getLeft()
      getDataErrors(dataError)
      setLoading(false)
      return
    }

    const dataResult = result.getRight()
    setPassword(dataResult)
    setLoading(false)
  }

  const getUserTypeFromUrl = (pathname: string): UserType => {
    switch (true) {
      case pathname.includes(UserType.PATIENT):
        return UserType.PATIENT
      case pathname.includes('home-care'):
        return UserType.HOME_CARE
      case pathname.includes(UserType.DRIVER):
        return UserType.DRIVER
      default:
        return UserType.PROFESSIONAL
    }
  }

  const getTextsScreen = (type: UserType): void => {
    const originalTexts = texts[type]
    const readyAccount = originalTexts?.readyAccount ?? ''
    const translatedTexts = {
      title: t(originalTexts.title),
      welcome: t(originalTexts?.welcome),
      readyAccountText: t(originalTexts.readyAccountText),
      readyAccountPass: t(originalTexts.readyAccountPass),
      readyAccountStore: t(originalTexts.readyAccountStore),
      readyAccountBy: t(originalTexts.readyAccountBy),
      ...(type !== 'professional' && { readyAccount: t(readyAccount) }),
    }
    setTextsScreen(translatedTexts)
  }

  const getImagesScreen = (type: UserType): void => {
    const imagesData = images[type]
    setImagesScreen(imagesData)
  }

  const getStoresLinks = (type: UserType): void => {
    const storesData = storeLinks[type]
    setStoresLinks(storesData)
  }

  const getLayoutColor = (type: UserType): void => {
    let color = 'primary';

    if (type === UserType.HOME_CARE) {
      color = 'gray';
    }
    setLayoutColor(color);
  }

  useEffect(() => {
    const userByUrl = getUserTypeFromUrl(location.pathname)
    setUserType(userByUrl)
    setLayoutColor(userByUrl)
    getTextsScreen(userByUrl)
    getLayoutColor(userByUrl)
    getImagesScreen(userByUrl)
    getStoresLinks(userByUrl)
    getCreateUserPassword()
    return () => {
      setErrorMessage('')
      setPassword('')
      setTextsScreen(undefined)
      setImagesScreen(undefined)
      setStoresLinks(undefined)
      setLayoutColor('primary')
    }
  }, [params, location])

  const handleEye = (): void => {
    setHidden((prev) => !prev)
    openSnackbar(SnackbarColor.warning, t('confirm.readyAccount.pass.message'))
  }

  const handleCopy = async (): Promise<void> => {
    if (!password) {
      return
    }
    if ('clipboard' in navigator) {
      openSnackbar(SnackbarColor.inherit, t('confirmPass.copy'))
      return await navigator.clipboard.writeText(password)
    }
  }

  const getPassword = (): string => {
    if (!password) {
      return ''
    }

    if (hidden) {
      const hiddenPass = password.replace(/./g, 'X')
      return hiddenPass
    }
    return password
  }

  return (
    <div className={clsx(classes.confirmUser, layoutColor, userType)}>
      <div className={classes.header}>
        <img src={imagesScreen?.logo} alt="logo" />
      </div>
      {loading ? (
        <Grid container className={classes.progressContent}>
          <Grid item xs={12}>
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container className={classes.layout}>
            <Grid item xs={10} sm={12} md={12}>
              <Grid item container>
                <Grid item xs={12} className={classes.readyAccountTop}>
                  <h4>{textsScreen?.title}</h4>
                  <p>{textsScreen?.welcome}</p>
                </Grid>
                <Grid item xs={12} className={classes.imagesScreen}>
                  <img id="header" src={imagesScreen?.header} alt="header"  />
                </Grid>
                <Grid item xs={12} className={classes.readyAccountMiddle}>
                  {textsScreen?.readyAccount && (
                    <h5>{textsScreen?.readyAccount}</h5>
                  )}
                  <p>{textsScreen?.readyAccountText}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.contentPass}>
            <p className={classes.text}>{textsScreen?.readyAccountPass}</p>
            {errorMessage ? (
              <p className="label error">{errorMessage}</p>
            ) : (
              <>
                <span className={classes.password}>{getPassword()}</span>
                <div className={classes.contentButtons}>
                  <Button
                    id="btn-eye"
                    simple
                    justIcon
                    onClick={() => handleEye()}
                  >
                    {hidden ? <VisibilityOff /> : <RemoveRedEye />}
                  </Button>
                  <Button
                    id="btn-copy"
                    simple
                    justIcon
                    onClick={() => handleCopy()}
                  >
                    <FileCopyIcon />
                  </Button>
                </div>
              </>
            )}
          </div>
          <Grid container className={classes.alignCenter}>
            <Grid item xs={10} sm={8}>
              {textsScreen?.readyAccountStore && (
                <p
                  className="contentText readyAccountStore"
                  dangerouslySetInnerHTML={{
                    __html: textsScreen?.readyAccountStore,
                  }}
                />
              )}
              <div className={classes.contentButtonsStore}>
                <a
                  id="link-appstore"
                  href={storesLinks?.appstore}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appstore} alt="appstore" />
                </a>
                <a
                  id="link-googleplay"
                  href={storesLinks?.googleplay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={googleplay} alt="googleplay" />
                </a>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      <div className={classes.footer}>
        {textsScreen?.readyAccountBy && (
          <p
            className="contentText"
            dangerouslySetInnerHTML={{ __html: textsScreen?.readyAccountBy }}
          />
        )}
      </div>
      <Snackbar
        place="bc"
        color={snackbar.color}
        message={snackbar.message}
        open={snackbar.open}
      />
    </div>
  )
}

ConfirmUser.propTypes = {
  params: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default ConfirmUser
