import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardIcon from '../Card/CardIcon';
import Button from '../CustomButtons/Button';
import EditIcon from '@material-ui/icons/Edit';
import ValidationInput from '../ValidationInput';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import ApiInvoker from '../../api/ApiInvoker';
import RecordsApiInvoker from '../../api/RecordsApiInvoker';
import ButtonSpinner from '../ButtonSpinner';
import './EditTool.css';

class EditTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltypes: [],
            types: [],
            condition: [],
            suppliers: [],
            supplierNames: [],
            supplierCodes: [],
            selectedCondition: '',
            selectedSupplierName: '',
            selectedSupplierCode: '',
            tooltype: '',
            detail: '',
            serialNumber: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            save: false,
            refresh: true,
            busy: false,
        }
    }

    componentWillMount() {
        if (this.props.tooltypes?.length > 0) {
            const tooltypes = this.formatToolTypes(this.props.tooltypes)
            this.setState({ tooltypes, types: this.props.tooltypes });
        } else {
            this.getToolTypesData();
        }
        this.getToolsConditionData();
        this.getToolSuppliersData();
        this.findTool();
    }

    componentWillReceiveProps(next) {
        if (next.tooltypes) {
            const tooltypes = this.formatToolTypes(next.tooltypes)
            this.setState({
                tooltypes,
                types: next.tooltypes,
            });
        }
    }

    formatToolTypes(toolTypes) {
        const result = toolTypes.map(d => {
            return {
                id: d.toolTypeId,
                value: d.name + ' - ' + d.toolTypeCode
            }
        });
        return result;
    }

    getToolTypesData() {
        ApiInvoker.getToolTypes(data => {
            this.props.onGetToolTypes(data);
        }, null);
    }

    getToolSuppliersData() {
        ApiInvoker.getToolSuppliers(data => {
            if(data) {
                const suppliers = this.formatSuppliers(data);
                this.setState({
                    suppliers,
                    supplierNames: suppliers.map(s => ({ id: s.id, value: s.name })),
                    supplierCodes: suppliers.map(s => ({ id: s.id, value: s.code }))
                })
            }
        }, null);
    }

    formatSuppliers(data) {
        return data.map(d => ({
            id: d.productSuppliersId,
            name: d.name,
            code: d.code
        }));
    }

    findToolType(id) {
        let type = this.state.types.find(f => f.toolTypeId === id);
        return type;
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        })

        setTimeout(() => {
            this.setState({ alertOpen: false })
            if (color === 'success') {
                browserHistory.push(`/tools`)
            }
        }, 5000);
    }

    saveTool() {
        this.setState({ save: true, busy: true });
        if (this.state.tooltype) {
            let toolData = {
                toolId: this.state.toolId,
                toolType: this.findToolType(this.state.tooltype),
                serialNumber: this.state.serialNumber,
                detail: this.state.detail,
                condition: {
                    recordId: this.state.selectedCondition
                },
                companyId: this.state.companyId,
            };
    
            // Comprueba si se ha seleccionado un proveedor
            if (this.state.selectedSupplierName) {
                toolData.productSuppliers = {
                    productSuppliersId: this.state.selectedSupplierName
                };
            } else {
                toolData.productSuppliers = null;
            }
    
            ApiInvoker.postTool(toolData, data => {
                this.props.onEditTool(data)
                this.openAlert('success', this.props.t('tools.edit.success'))
            }, err => {
                console.error('Error on save', err);
                this.openAlert('danger', err.message);
                this.setState({ busy: false });
            })
        } else {
            this.openAlert('warning', this.props.t('tools.edit.warning'));
            this.setState({ busy: false });
        }
    }

    getToolsConditionData() {
        RecordsApiInvoker.getToolsCondition(data => {
            if(data) {
                this.setState({
                    condition: this.formatCondition(data)
                })
            }
        }, null)
    }
    
    formatCondition(data){
        const {t} = this.props;

        if(data){
            return data.map((d) => ({
                id: d.id,
                value: t(`tools.table.condition.${d.name}`)
            }));
        }
    }

    handleValue(value, state) {
        this.setState({ [state]: value, refresh: false });
        
        if (state === 'selectedSupplierName' || state === 'selectedSupplierCode') {
            if (value === '') {
                this.setState({
                    selectedSupplierName: '',
                    selectedSupplierCode: ''
                });
            } else {
                const supplier = this.state.suppliers.find(s => s.id === value);
                if (supplier) {
                    this.setState({
                        selectedSupplierName: supplier.id,
                        selectedSupplierCode: supplier.id
                    });
                }
            }
        }
    }

    findTool() {
        ApiInvoker.getTool(this.props.routeParams.id, tool => {
            this.setState({
                detail: tool.detail,
                serialNumber: tool.serialNumber,
                toolId: tool.toolId,
                tooltype: tool.toolType.toolTypeId,
                companyId: tool.companyId,
                selectedCondition: tool.condition?.recordId,
                selectedSupplierName: tool.productSuppliers?.productSuppliersId,
                selectedSupplierCode: tool.productSuppliers?.productSuppliersId,
            })
        }, null);
    }

    back() {        
        browserHistory.goBack();
    }

    render() {
        const { t } = this.props;
        return (
            <GridContainer className="edit-tool">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <EditIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('tools.edit.title')}</h3>
                        </CardHeader>
                        <CardBody className="edit-tool-form">
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <SelectInput label={t('tools.edit.tooltype')} elements={this.state.tooltypes} onSelectedValue={(value) => this.handleValue(value, 'tooltype')} value={this.state.tooltype} invalid={(!this.state.tooltype || this.state.tooltype === '') && this.state.save} errorText={t('error.required')} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ValidationInput id="regular" text={t('tools.edit.serialNumber')} type="text" onChangeValue={(value) => this.handleValue(value, 'serialNumber')} 
                                        value={this.state.serialNumber} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ValidationInput text={t('tools.edit.detail')} type="text" onChangeValue={(value) => this.handleValue(value, 'detail')} value={this.state.detail} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <SelectInput label={t('tools.edit.condition')} elements={this.state.condition} onSelectedValue={(value) => this.handleValue(value, 'selectedCondition')} value={this.state.selectedCondition}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <SelectInput label={t('tools.new.title.name.proveedor')} elements={[{ id: '', value: '' }, ...this.state.supplierNames]} onSelectedValue={(value) => this.handleValue(value, 'selectedSupplierName')} value={this.state.selectedSupplierName}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <SelectInput label={t('tools.new.title.code.proveedor')} elements={[{ id: '', value: '' }, ...this.state.supplierCodes]} onSelectedValue={(value) => this.handleValue(value, 'selectedSupplierCode')} value={this.state.selectedSupplierCode}/>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                        <CardFooter className="new-tool-submit padding-horizontal">
                            <Button id="button-cancel" onClick={this.back.bind(this)}>
                                {t('tools.new.cancel')}
                            </Button>

                             <ButtonSpinner
                                id="button-save"
                                onClick={() => this.saveTool()}
                                disabled={this.state.busy}
                                label={t('tools.edit.save')}
                                labelLoading={t('tools.edit.saving')}
                                loading={this.state.busy}
                                typeButton="submit"
                                color="primary"
                            />
                        </CardFooter>
                    </Card>
                    <Snackbar
                        place="tr"
                        color={this.state.alertColor}
                        message={this.state.alertMessage}
                        open={this.state.alertOpen}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

EditTool.propTypes = {
    t: PropTypes.func.isRequired,
    onGetToolTypes: PropTypes.func.isRequired,
    onEditTool: PropTypes.func.isRequired,
    tooltypes: PropTypes.array,
    routeParams: PropTypes.object,
}

export default withTranslation()(EditTool);