import './EditEmployee.css';

import { Add, Person } from '@material-ui/icons';
import { Address as AddressIcon, Briefcase, Location as GlobeLocation, QrCode } from '../../icons';
import { CircularProgress, TextField } from '@material-ui/core';
import React, { Component } from 'react';

import Address from '../Address/address';
import AddressService from '../../containers/AddressService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CustomDialog from '../CustomDialog';
import DateInput from '../DateInput';
import EmployeeType from './EmployeeType/EmployeeType';
import EmployeesService from '../../containers/EmployeesService';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import Phone from '../Phone';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneService from '../../containers/PhoneService';
import PropTypes from 'prop-types';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import ValidationInput from '../ValidationInput';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import config from '../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateToServer = config.getDateToServer();

class EditEmployee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentTypes: [],
            documents: [],
            genderTypes: [],
            nationalities: [],
            name: '',
            lastName: '',
            document: '',
            documentType: '',
            licenseNumber: '',
            gender: '',
            nationality: '',
            email: '',
            birthDate: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            personId: '',
            openAddress: false,
            openPhone: false,
            openEmployeeType: false,
            openEmployeeGeographic: false,
            openFinanciers: false,
            deleteAlert: false,
            address: null,
            phone: null,
            employeeType: null,
            employeeGeographic: null,
            save: false,
            dataChanged: false,
            refresh: true,
            mode: this.props.location?.state?.mode ?? '',
            loading: false,
            isAddress: false,
            isLoadingAddress: true,
        }
    }

    componentWillMount()  {                
        this.getNationalities(this.findEmployee);
        this.getGenderTypes();
        
        if (this.props.documentTypes && this.props.documentTypes.length > 0) {
            const documentTypes = this.formatDocumentTypes(this.props.documentTypes)
            this.setState({
                documentTypes,
                documents: this.props.documentTypes,
            });
            return;
        }
        this.getDocumentTypes();
    }

    componentWillReceiveProps(next) {
        if (next.documentTypes) {
            const documentTypes = this.formatDocumentTypes(next.documentTypes);
            this.setState({
                documentTypes,
                documents: next.documentTypes,
            });
        }
    }

    componentWillUnmount(){
       this.props.onUnmountEmployee();
    }

    formatDocumentTypes(documentTypes) {
        const result = documentTypes.map(d => {
            return {
                id: d.idTypeId,
                name: `${d.name} - ${d.type}`,
            }
        });
        return result;
    }
    
    getGenderTypes() {
        PersonApiInvoker.getGenderTypes(data => {
            this.setGenderTypesState(data);
        }, null);
    }

    getDocumentTypes() {
        PersonApiInvoker.getIdTypes(data => {
            this.props.onGetDocumentTypes(data);
        }, null);
    }

    findDocumentType(id) {
        const type = this.state.documents.find(d => d.idTypeId === id);
        return type || {};
    }

    setGenderTypesState(genderTypes) {
        const { t } = this.props;
        const genderTypesMap = {
            UNDEFINED: t('customers.new.gender.undefined'),
            MALE: t('customers.new.gender.male'),
            FEMALE: t('customers.new.gender.female'),
        }
        const result = genderTypes.map(genderType => {
            return {
                id: genderType,
                name: genderTypesMap[genderType],
            };
        });

        this.setState({ genderTypes: result });
    }

    getNationalities(findCustomer) {
        PersonApiInvoker.getNationalities(jsonResponse => {
            this.setState({
                nationalities: jsonResponse.map(nationalityItem => {
                    return {
                        id: nationalityItem.nationalityId,
                        name: nationalityItem.name
                    }
                })
            }, findCustomer);
        }, findCustomer);
    }

    findNationality() {
        const nationalityObj = _.find(this.state.nationalities, nationalityItem => {
            return nationalityItem.id === this.state.nationality.id;
        });

        return {
            name: nationalityObj.name,
            nationalityId: nationalityObj.id,
        };
    }

    loadNationality = (employee) => {
        const employeeNationalityId = _.get(employee.nationality, 'nationalityId' ,'');
        const nationalityObj = _.find(this.state.nationalities, nationalityItem => {
            return nationalityItem.id === employeeNationalityId;
        });

        return nationalityObj;
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    validEmail = (value) => {
        if(value.length === 0) return true;
        const emailRex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRex.test(value);
    }

    postEmployee() {
        const { t } = this.props;
        const idType = this.findDocumentType(this.state.documentType);
        const data = {
            personId: this.state.personId,
            idType,
            id: this.state.document,
            birthdate: this.state.birthDate ? moment(this.state.birthDate).format("YYYY-MM-DD") : null,
            sex: this.state.gender,
            email: this.state.email,
            nationality: this.findNationality(),
            firstName: this.state.name,
            lastName: this.state.lastName,
            metadata: { licenseNumber: this.state.licenseNumber },
            verificationId: 0,
            qr: '',
            avatarUrl: '',
        }
    
        PersonApiInvoker.postEmployee(data, () => {
            this.setState({ dataChanged: false, loading: false });
            this.openAlert('success', t('employee.new.save.success'));
        }, (error) => {
            const message = error.message ?? error;
            this.openAlert('danger', message + '');
            this.setState({ loading: false });
        });
    }

    saveEmployee() {
        this.setState({ save: true, loading: true });
        const { t } = this.props;
        const requiredFields = this.state.name && this.state.lastName
        && this.state.document && this.state.documentType !== '0'
        && this.state.email && this.state.birthDate && this.state.licenseNumber
        && this.state.nationality && this.state.gender;

        if (requiredFields && this.validEmail(this.state.email)) { 
            this.postEmployee();
            return;
        }
        const messageEmail = !this.validEmail(this.state.email) ? t('message.invalidEmail') : '';
        const messageRequired = !requiredFields ? t('appointment.new.requiredFields') : '';
        this.openAlert('warning', `${ messageRequired } ${ messageEmail }`);
        this.setState({ loading: false });
    }

    handleValue(value, state) {
        this.setState({ [state]: value, dataChanged: true, refresh: false });
    }

    findEmployee() {
        PersonApiInvoker.getEmployee(this.props.routeParams.id, (employee) => {        
            this.props.onGetEmployee(employee);
            this.setState({
                name: employee.firstName,
                lastName: employee.lastName,
                birthDate: employee.birthdate,
                gender: employee.sex,
                email: employee.email,
                nationality: this.loadNationality(employee),
                document: employee.id || '',
                documentType: employee.idType.idTypeId,
                licenseNumber: employee.metadata?.licenseNumber ?? null,
                personId: employee.personId,
            });
        }, null);
    }

    createAddress() {
        this.setState({ openAddress: true, address: null });
    }

    createType() {
        this.setState({ openEmployeeType: true, employeeType: null });
    }

    createGeographic() {
        this.setState({ openEmployeeGeographic: true, employeeGeographic: null });
    }

    createPhone() {
        this.setState({ openPhone: true, phone: null });
    }

    editAddress(address) {
        this.setState({ openAddress: true, address });
    }

    editPhone(phone) {
        this.setState({ openPhone: true, phone });
    }

    filledAddress = (address) =>  {
        const { isAddress, isLoadingAddress } = address;
        this.setState({ isAddress, refresh: false, isLoadingAddress });
    }
    
    render() {
        const maxDate = moment().format(dateToServer);
        const minDate = moment().subtract(120, 'years').format(dateToServer);
        const { t, onDeleteItem } = this.props;
        const { alertColor, alertMessage, alertOpen, dataChanged, document, documentType, documentTypes, 
            email, gender, genderTypes, isAddress, isLoadingAddress, lastName, licenseNumber, loading,
            mode, nationalities, nationality, name, personId, refresh, save,
        } = this.state;

        return (
            <GridContainer className="edit-customer employee">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <Person />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('employee.new.profileData')}</h3>
                        </CardHeader>
                        {!personId ?
                            <div style={{
                                display: 'flex',
                                height: '200px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <CircularProgress />
                            </div>:
                            <>
                                <CardBody className="edit-customer-form">
                                    <form>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <ValidationInput
                                                    text={t('employee.new.last_name')}
                                                    onChangeValue={(value) => this.handleValue(value, 'lastName')}
                                                    value={lastName}
                                                    disabled={mode === 'view'}
                                                    invalid={lastName === '' && save}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <ValidationInput
                                                    className={(mode === 'view') ? 'viewmode' : ''}
                                                    text={t('employee.new.first_name')}
                                                    onChangeValue={(value) => this.handleValue(value, 'name')}
                                                    value={name}
                                                    disabled={mode === 'view'}
                                                    invalid={name === '' && save}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6}>
                                                {mode !== "view" ?
                                                    <Autocomplete
                                                        className="nationality-autocomplete select-input"
                                                        options={nationalities}
                                                        getOptionLabel={option => option.name}
                                                        getOptionSelected={option => option.id}
                                                        renderInput={params => (
                                                            <TextField {...params} label={t('employee.new.nationality')} fullWidth />
                                                        )}
                                                        onChange={(event, value) => this.handleValue(value, 'nationality')}
                                                        value={nationality}
                                                    /> :
                                                    <ValidationInput
                                                        className="viewmode"
                                                        text={t('employee.new.nationality')}
                                                        value={nationality.name}
                                                        disabled="true"
                                                    />}
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6}>
                                                {mode !== "view" ?
                                                    <SelectInput
                                                        label={t('employee.new.gender')}
                                                        elements={genderTypes}
                                                        value={gender}
                                                        onSelectedValue={(value) => this.handleValue(value, 'gender')}
                                                        invalid={gender === '' && save}
                                                        onGetOptionSelected={option => option.id}
                                                    />
                                                    :
                                                    <ValidationInput
                                                        className="viewmode"
                                                        text={t('employee.new.gender')}
                                                        value={genderTypes.find(item=> item.id === gender)?.name || ''}
                                                        disabled="true"
                                                    />}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <SelectInput
                                                    label={t('employee.new.id_type')}
                                                    elements={documentTypes}
                                                    onSelectedValue={(value) => this.handleValue(value, 'documentType')}
                                                    value={documentType} disabled={mode === 'view'}
                                                    invalid={documentType === '' && save}
                                                    onGetOptionSelected={option => option.id}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <ValidationInput
                                                    text={t('employee.new.id')}
                                                    type="text"
                                                    onChangeValue={(value) => this.handleValue(value, 'document')}
                                                    value={document} disabled={mode === 'view'}
                                                    invalid={document === '' && save}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} >
                                                <ValidationInput
                                                    text={t('employee.new.licenseNumber')}
                                                    type="text"
                                                    onChangeValue={(value) => this.handleValue(value, 'licenseNumber')}
                                                    value={licenseNumber} disabled={mode === 'view'}
                                                    invalid={licenseNumber === '' && save}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <DateInput text={t('employee.new.birth_date')}
                                                    minDate
                                                    min={minDate}
                                                    max={maxDate}
                                                    onChangeValue={(value) => this.handleValue(value, 'birthDate')}
                                                    disabled={mode === 'view'}
                                                    value={this.state.birthDate}
                                                    invalid={this.state.birthDate ? this.state.birthDate <= maxDate : !this.state.birthDate && save}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <ValidationInput
                                                    text={t('employee.new.email')}
                                                    type="email"
                                                    onChangeValue={(value) => this.handleValue(value, 'email')}
                                                    value={email}
                                                    disabled={true}
                                                    invalid={(email === '' && save) || !this.validEmail(email)}
                                                />
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <p className="required-text">
                                                    <small>*</small> {t('employee.new.required')}
                                                </p>
                                            </GridItem>
                                        </GridContainer>
                                    </form>
                                </CardBody>
                                {(mode !== 'view') &&
                                    <CardFooter className="edit-customer-submit flex-end">
                                    {(this.state.mode !== 'view') &&
                                        <ButtonSpinner
                                            onClick={() => this.saveEmployee()}
                                            disabled={!dataChanged || loading}
                                            label={t('employee.new.save')}
                                            labelLoading={t('employee.new.saving')}
                                            loading={loading}
                                            typeButton="submit"
                                            color="primary"
                                            id="button-save"
                                        />
                                    }
                                    </CardFooter>
                                }
                            </>
                        }
                    </Card>
                </GridItem>
                <GridItem xs={12} className="specialities-practices-view">
                    <Card className="employee-specialities-practices">
                        <CardHeader icon>
                            <CardIcon color="secondary">
                                <Briefcase />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('employee.specialities.practices')}</h3>
                        </CardHeader>
                        <CardBody>
                            <EmployeeType
                                componenMode={mode}
                                onDeleteItem={onDeleteItem}
                                personId={personId}
                                refresh={refresh}
                                onEditAddress={(address) => this.editAddress(address)}
                            />
                        </CardBody>
                        {(mode !== 'view') &&
                            <CardFooter className="content-button-add flex-end">
                                <Button id="button-add-type" className="edit-customer-create" color="primary" onClick={() => this.createType()}>
                                    <Add />
                                </Button>
                            </CardFooter>
                        }
                    </Card>

                    <Card className="employee-geographic-zones">
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <GlobeLocation />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('employee.new.GeographicsZones')}</h3>
                        </CardHeader>
                        <CardBody>
                            <EmployeesService.EmployeesGeographicForm componenMode={mode} personId={personId} refresh={refresh} />
                        </CardBody>
                        {(mode !== 'view') &&
                            <CardFooter className="content-button-add flex-end">
                                <Button id="button-add-zone" className="edit-customer-create" color="primary" onClick={() => this.createGeographic()}>
                                    <Add />
                                </Button>
                            </CardFooter>
                        }
                    </Card>

                    <Card className="employee-addresses">
                        <CardHeader icon>
                            <CardIcon color="secondary">
                                <AddressIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('employee.new.Addresses')}</h3>
                        </CardHeader>
                        <CardBody>
                            <Address
                                componenMode={mode}
                                personId={personId}
                                refresh={refresh}
                                onEditAddress={(address) => this.editAddress(address)}
                                onFilledAddress={(address) => this.filledAddress(address)}

                            />
                        </CardBody>
                        {(mode !== 'view') &&
                            <CardFooter className="content-button-add flex-end">
                                <Button id="button-add-address" className="edit-customer-create" color="primary" disabled={isAddress || isLoadingAddress} onClick={() => this.createAddress()}>
                                    <Add /> {t('employee.new.Address')}
                                </Button>
                            </CardFooter>
                        }
                    </Card>

                    <Card className="employee-phones">
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <PhoneIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('employee.new.Phones')}</h3>
                        </CardHeader>
                        <CardBody>
                            <Phone
                                componenMode={mode}
                                personId={personId}
                                refresh={refresh}
                                onEditPhone={(phone) => this.editPhone(phone)}
                                personType="employee"
                            />
                        </CardBody>
                        {(mode !== 'view') &&
                            <CardFooter className="content-button-add flex-end">
                                <Button id="button-add-phone" className="edit-customer-create" color="primary" onClick={() => this.createPhone()}>
                                    <Add /> {t('employee.new.Phone')}
                                </Button>
                            </CardFooter>
                        }
                    </Card>

                    <Snackbar
                        place="tr"
                        color={alertColor}
                        message={alertMessage}
                        open={alertOpen}
                    />

                    {personId &&
                        <CustomDialog
                            fullWidth title={t('employee.specialities.practices')}
                            open={this.state.openEmployeeType} onClose={() => this.setState({ employeeType: null, openEmployeeType: false })}
                        >
                            <EmployeesService.EmployeesTypeService personId={personId} edit={this.state.employeeType} onTypeSubmited={() => this.setState({ employeeType: null, openEmployeeType: false, refresh: true })} />
                        </CustomDialog>
                    }

                    {personId &&
                        <CustomDialog
                            title={t('employee.new.GeographicsZones')}
                            open={this.state.openEmployeeGeographic}
                            onClose={() => this.setState({ employeeGeographic: null, openEmployeeGeographic: false })}
                        >
                            <EmployeesService.EmployeesGeographicModal  personId={personId} edit={this.state.employeeGeographic} onGeographicSubmited={() => this.setState({ employeeGeographic: null, openEmployeeGeographic: false, refresh: true })} />
                        </CustomDialog>
                    }

                    {personId &&
                        <CustomDialog
                            title={t('employee.new.modal.address')}
                            open={this.state.openAddress}
                            onClose={() => this.setState({ address: null, openAddress: false })}
                        >
                            <AddressService edit={this.state.address} personId={personId} onAddressSubmited={() => this.setState({ address: null, openAddress: false, refresh: true })} />
                        </CustomDialog>
                    }

                    {personId &&
                        <CustomDialog
                            title={t('employee.new.modal.phone')}
                            open={this.state.openPhone}
                            onClose={() => this.setState({ phone: null, openPhone: false })}
                        >
                            <PhoneService
                                edit={this.state.phone}
                                personId={personId}
                                onPhoneSubmited={() => this.setState({ phone: null, openPhone: false, refresh: true })}
                                personType={'employee'}
                            />
                        </CustomDialog>
                    }
                </GridItem>

                <GridItem xs={12} className="text-center">
                    <Button id="button-back" className="centerButton" onClick={() => browserHistory.push(`/profesionales`)}>
                        {t('employee.new.back')}
                    </Button>
                </GridItem>
            </GridContainer>
        )
    }
}

EditEmployee.defaultProps = {
}

EditEmployee.propTypes = {
    t: PropTypes.func,
    onGetDocumentTypes: PropTypes.func.isRequired,
    onGetEmployee: PropTypes.func,
    location: PropTypes.object,
    routeParams: PropTypes.object,
    documentTypes: PropTypes.array,
    onUnmountEmployee: PropTypes.func,
    onDeleteItem: PropTypes.func,
}

export default withTranslation()(EditEmployee);
