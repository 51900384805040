import './SchedulePlan.css';

import React, { Component } from 'react';

import DateInput from '../DateInput';
import DayPicker from '../DayPicker';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import RadioInput from '../RadioInput';
import SelectInput from '../SelectInput';
import TextareaInput from '../TextareaInput';
import ValidationInput from '../ValidationInput';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from 'react-i18next';
import config from '../../config/config.js';

class SchedulePlan extends Component {
  constructor(props) {
    super(props)
    const { t } = this.props;

    this.state = {
      recurrenceTypes: [
        { id: 'DAY', value: t('recurrenceTypes.daily'), component: null },
        { id: 'WEEK', value: t('recurrenceTypes.weekly') },
        { id: 'MONTH', value: t('recurrenceTypes.monthly') },
        { id: 'INTRADAY', value: t('recurrenceTypes.intraday') },
      ],
      recurrenceTypesTransfer: [
        { id: 'DAY', value: t('recurrenceTypes.daily'), component: null },
        { id: 'WEEK', value: t('recurrenceTypes.weekly') },
        { id: 'MONTH', value: t('recurrenceTypes.monthly') }
      ],
      finishOptions: [
        { id: '1', value: t('finishOptions.finish'), component: false },
        { id: '3', value: t('finishOptions.inDate'), component: true },
        { id: '2', value: t('finishOptions.afterTo'), component: true },
      ],
      recurrenceType: '',
      quantity: 1,
      quantityIntraday: null,
      every: 1,
      finish: '1',
      finishDate: '',
      finishOcurrences: '',
      monthRecurrence: '',
      weekRecurrence: [],
      invalidSelection: false,
      observations: '',
    }
  }

  componentDidMount() {
    const { current, endDate, observations } = this.props;
    if (current && !isEmpty(current)) {
      this.setState({
        recurrenceType: current.recurrenceType,
        quantity: current.quantity ?? 1,
        every: current.every,
        finish: current.finish,
        finish2: this.getCurrentFinish(current),
        finishOcurrences: current.finishOcurrences,
        monthRecurrence: current.monthRecurrence,
        endDate,
        quantityIntraday: current?.quantityIntraday ?? 1,
        weekRecurrence: current.weekRecurrence || [],
        observations,
      });
    }
  }

  getCurrentFinish(current) {
    const isOcurrences2 = current.endType === 'OCURRENCES' ? '2' : null;
    const isNotCurrentFinish = current?.endType === 'DATE' ? '1' : isOcurrences2;
    return current?.finish ?? isNotCurrentFinish;
  }

  setStateOnUpdate(current, practiceDuration, observations) {
    const defaultQuantityIntraday = practiceDuration ? 1440 / practiceDuration : 1;
    this.setState({
      recurrenceType: current.recurrenceType,
      quantity: current.quantity ?? 1,
      every: current.every,
      finish: current.finish,
      finish2: this.getCurrentFinish(current),
      finishOcurrences: current.finishOcurrences,
      monthRecurrence: current.monthRecurrence,
      quantityIntraday: current?.quantityIntraday ?? defaultQuantityIntraday,
      weekRecurrence: current?.weekRecurrence ?? current?.weekDays ?? [],
      observations,
    });
  }

  componentDidUpdate(prevProps) {
    const { savePlan, current, practiceDuration, observations } = this.props;
    if (prevProps.endDate && !this.state.endDate) {
      this.setState({ endDate: prevProps.endDate });
    }

    if (savePlan && prevProps.savePlan !== savePlan && this.validateFields(this.state)) {
      this.createRecurrence();
      this.props.onCreatedObservations(this.state.observations);
    }

    if (current && prevProps.current !== current) {
      this.setStateOnUpdate(current, practiceDuration, observations);
    }
  }

  handleValue(value, state) {
    if (state == 'observations') {
      let regex = config.regex_normal;
      if (regex.test(value) ) {
        return;
      }
    }
    //
    if (state === 'finish') {
      this.setState({ finishOcurrences: '' });
    }
    const { weekRecurrence, quantityIntraday } = this.state;
    let newWeekRecurrence = weekRecurrence;
    if (state === 'weekRecurrence') {
      newWeekRecurrence = value;
    }
    if (state === 'recurrenceType' && value === 'INTRADAY' && (newWeekRecurrence == null || newWeekRecurrence.length <= 0)) {
      newWeekRecurrence = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
    }
    let newQuantityIntraday = quantityIntraday;
    if (state === 'quantityIntraday') {
      newQuantityIntraday = value;
    }
    if (state === 'recurrenceType' && value === 'INTRADAY') {
      const calculateQuantity = Math.floor(1440 / this.props.practiceDuration);
      newQuantityIntraday = !this.props.practiceDuration ? 0 : calculateQuantity;
    }

    this.setState({ [state]: value, weekRecurrence: newWeekRecurrence, quantityIntraday: newQuantityIntraday  }, () => {
      this.createRecurrence();
      if (state === 'observations') {
        this.props.onCreatedObservations(value);
      }
    });
  }

  validateFields(fields) {
    if (fields.recurrenceType) {
      if (fields.recurrenceType === 'DAY') {
        return fields.quantity > 0;
      }
      if (fields.recurrenceType === 'WEEK') {
        if (!fields.weekRecurrence || fields.weekRecurrence.length < 1) {
          return false;
        }
      }
      if (fields.recurrenceType === 'MONTH') {
        if (!fields.monthRecurrence || (fields.monthRecurrence && fields.monthRecurrence < 1) || (fields.monthRecurrence && fields.monthRecurrence > 31)) {
          return false;
        }
      }
      if ((fields.recurrenceType !== 'INTRADAY' && !fields.every) || (fields.recurrenceType !== 'INTRADAY' && fields.every && fields.every < 1)) {
        return false;
      }

      if ((fields.recurrenceType === 'INTRADAY' && !fields.quantityIntraday) || (fields.recurrenceType === 'INTRADAY' && fields.quantityIntraday && fields.quantityIntraday.length < 1)) {
        return false;
      }
    } else {
      return false;
    }

    if (fields.finish === '2') {
      if (!fields.finishOcurrences || (fields.finishOcurrences && fields.finishOcurrences < 1)) {
        return false;
      }
    }

    if (fields.finish === '3') {
      if (!fields.finishDate) {
        return false;
      }
    }
    return true;
  }

  createRecurrence() {
    this.props.onCreatedRecurrence({
      recurrenceType: this.state.recurrenceType,
      every: this.state.every || '',
      endDate: this.state.endDate,
      quantityIntraday: this.state.quantityIntraday,
      finish: this.state.finish ?? '1',
      finishOcurrences: this.state.finishOcurrences ?? '',
      finishDate: this.state.finishDate ?? '',
      monthRecurrence: this.state.monthRecurrence ?? '',
      weekRecurrence: this.state.weekRecurrence,
    });
  }

  getRecurrenceType = (type) => {
    const { t } = this.props;
    switch (type) {
      case 'DAY':
        return t('recurrenceTypes.day');
      case 'WEEK':
        return t('recurrenceTypes.week');
      case 'MONTH':
        return t('recurrenceTypes.month');
      default:
        break;
    }
  }

  renderComponentFinish = (idComp, disabledComp) => {
    const { t, dates, practiceStartDate, errorEndDate } = this.props;
    const { finish, finishOcurrences, endDate } = this.state;
    const startDate = dates?.startDate ?? null;
    const minDate = practiceStartDate ?? startDate;
    const onInvalidValue = finish === '2' && finishOcurrences < 1;

    switch (idComp) {
      case '2': {
        const error2 = (onInvalidValue) ? t('error.number-min-1') : '';
        return (
          <GridItem
            xs={7}
            className="schedule-plan-group ocurrences-group no-padding"
            onClick={!disabledComp && (() => this.setState({ finish: '2' }))}
          >
            <ValidationInput
              id="input-quantity"
              type="number"
              name="finishOcurrences"
              placeHolder={`${t('label.quantityOccurrences')} *`}
              value={finish === '2' && finishOcurrences}
              onChangeValue={(value) => this.handleValue(value, 'finishOcurrences')}
              invalid={(finish === '2' && !finishOcurrences) || (onInvalidValue)}
              errorText={(finish === '2' && !finishOcurrences) ? t('error.required') : error2}
              disabled={finish !== '2' || disabledComp}
            />
          </GridItem>
        );
      }
      case '3':
        return (
          <GridItem xs={7} className={`${finish !== '3' && 'disabled'} schedule-plan-group finishDate-group no-padding`}>
            <DateInput
              id="datetime-practice-end"
              text={t('appointment.new.schedule.practice.end_date')}
              minDate
              min={minDate}
              max={dates?.endDate ?? ''}
              value={endDate}
              onChangeValue={(value) => this.handleValue(value, 'endDate')}
              error={finish === '3' && !endDate || errorEndDate.isError}
              errorText={errorEndDate.isError ? errorEndDate.message : (finish === '3' && !endDate) ? t('error.required') : ''}
              disabled={finish !== '3' || disabledComp}
            />
          </GridItem>
        );
      default:
        break;
    }
  }

  setErrorTextQuantIntraday = (field, recurrenceType, qIntraday) => {
    const { t, insideModule } = this.props;
    if (insideModule) {
      return '';
    } else {
      const errorQuantity = (field < 1 && t('error.number-min-1'))|| (recurrenceType === 'INTRADAY' && field < 1) ? t('error.number-min-1-max', { maxNum: qIntraday }): '';
      return !field ? t('error.required') : errorQuantity;
    }
  }

  setErrorTextRecType = (recurrenceType, practiceDuration) => {
    const { t, insideModule } = this.props;
    if (!insideModule) {
      const isNotRecurrenceType = (recurrenceType === 'INTRADAY' && practiceDuration === null) ? t('error.practiceRequired') : '';
      return !recurrenceType ? t('error.required') : isNotRecurrenceType;
    }
  }

  setInvalidSelection = (value) => this.setState({ invalidSelection: value }, () => {
    this.props.invalidPlan( this.setState({ invalidPlan: value }))
  });

  getWeekRecurrence() {
    let weekRecurrence = this.state.weekRecurrence;

    if (weekRecurrence && !Array.isArray(weekRecurrence) && weekRecurrence !== '') {
      weekRecurrence = weekRecurrence.split(',');
    }
    return weekRecurrence;
  }

  getRenderVars() {
    const { t, appointmentAction, practiceDuration } = this.props;
    const { quantityIntraday } = this.state;
    const disabledComponent = appointmentAction === 'assign_agenda';
    const errorpicker = this.props.errorpicker ?? t('error.required');
    const weekRecurrence = this.getWeekRecurrence();
    const getQuantIntraday = practiceDuration && (1440 / practiceDuration);
    const quantIntradayValue = practiceDuration && !quantityIntraday ? this.setState({ quantityIntraday: getQuantIntraday }) : quantityIntraday;
    const arrayWeek = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
    const weekRecurrenceValue = weekRecurrence ?? arrayWeek;
    return {
      disabledComponent, errorpicker, getQuantIntraday, quantIntradayValue, weekRecurrence, weekRecurrenceValue,
    }
  }

  isUserLender = () => {
    const companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
    let companyPartnershipId = localStorage.getItem('company_partnership_id') ? parseInt(localStorage.getItem('company_partnership_id')) : null;
    if (companyPartnershipId == null) {
			companyPartnershipId = companyId;
		}
    return companyId !== companyPartnershipId;
  }

  isDisabledObservations() {
    const { appointmentAction, appointmentStatus } = this.props;
    const isNewPracticeOnAppointment = appointmentStatus === 'SCHEDULES_ASSIGNED' && !appointmentAction;
    if (isNewPracticeOnAppointment) {
      return false;
    }
  
    const createStatus = undefined;
    const enabledStatuses = [createStatus, 'CREATED', 'PARTIAL_ASSIGNED'];
    const observationsEnabled = enabledStatuses.includes(appointmentStatus);
    return !observationsEnabled;
  }

  getErrorInvalidMonthRecurrence() {
    const { t } = this.props;
    const { monthRecurrence } = this.state;
    const isError =  monthRecurrence < 1 || monthRecurrence > 31;
    return isError ? t('error.number-min-1-max-31') : '';
  }

  render() {
    const { t, appointmentAction, disabledAgenda, indexArrayDays, insideModule, practiceDuration, savePlan, isTraslado,fullWidth } = this.props;
    let { recurrenceType, recurrenceTypes,recurrenceTypesTransfer, every, monthRecurrence, quantityIntraday, finish, finishOptions, observations } = this.state;
    const {
      disabledComponent, errorpicker, getQuantIntraday, quantIntradayValue, weekRecurrence, weekRecurrenceValue,
    } = this.getRenderVars();
    const errorInvalidMonthRecurrence = this.getErrorInvalidMonthRecurrence();
    const errorInvalidEvery = every < 1 ? t('error.number-min-1') : '';
    const disabledObservations = this.isDisabledObservations() || this.isUserLender();
    if (observations == null || observations == undefined) {
      observations = "";
    }
    return (
      <GridContainer className="schedule-plan no-padding">
        <GridItem xs={12} sm={ this.props.onlyDate || fullWidth ? 12 :6} className={ this.props.onlyDate ? "no-padding" : ""}>
          {!this.props.onlyDate ?
            <GridItem className="base-font" xs={12}>
              <h4>{t('schedule.plan.repeat')}</h4>
            </GridItem>
          :<></>
          }
          <GridContainer>
            <GridItem xs={12} sm={6} className="schedule-plan-group no-padding">
              <GridItem xs={12} className="no-padding">
                <SelectInput
                  id="select-plan-repeat"
                  label={`${t('schedule.plan.repeat.type')} *`}
                  elements={isTraslado ? recurrenceTypesTransfer :recurrenceTypes}
                  value={recurrenceType}
                  invalid={(!recurrenceType && savePlan) /*|| (recurrenceType === 'INTRADAY' && practiceDuration === null)*/}
                  errorText={this.setErrorTextRecType(recurrenceType, practiceDuration)}
                  disabled={disabledComponent || insideModule || disabledAgenda}
                  onSelectedValue={(value) => this.handleValue(value, 'recurrenceType')}
                />
              </GridItem>
            </GridItem>
            
            <GridItem xs={12} sm={6} className="schedule-plan-group">
              <GridItem xs={recurrenceType === 'INTRADAY' ? 12 : 9} className="no-padding">
                {recurrenceType === 'INTRADAY' ?
                  <ValidationInput
                    id="input-plan-day"
                    text={t('schedule.plan.repeat.day')}
                    type="number"
                    step="1"
                    name="quantityIntraday"
                    value={quantIntradayValue}
                    invalid={!quantityIntraday || quantityIntraday < 1 || quantityIntraday > getQuantIntraday}
                    errorText={this.setErrorTextQuantIntraday(quantityIntraday, recurrenceType, getQuantIntraday)}
                    disabled={disabledComponent || insideModule || disabledAgenda}
                    onChangeValue={(value) => this.handleValue(value, 'quantityIntraday')}
                  />
                  :
                  <ValidationInput
                    id="input-plan-number"
                    text={t('schedule.plan.repeat.each')}
                    type="number"
                    name="every"
                    value={every}
                    invalid={every < 1 || !every}
                    errorText={!every ? t('error.required') : errorInvalidEvery}
                    disabled={disabledComponent || insideModule || disabledAgenda}
                    onChangeValue={(value) => this.handleValue(value, 'every')}
                  />
                }
              </GridItem>
              {recurrenceType !== 'INTRADAY' &&
                <GridItem xs={3} className="no-padding">
                  <span className="suffix-label">
                    {this.getRecurrenceType(recurrenceType)}
                  </span>
                </GridItem>
              }
            </GridItem>
            <GridItem xs={12} id="daypicker" className="schedule-plan-group days-selector">
              {(recurrenceType === 'WEEK' || recurrenceType === 'INTRADAY')
                && (
                  <DayPicker
                    xs={9}
                    label={t('recurrenceTypes.weekly.dayPickerLabel')}
                    appointmentAction={appointmentAction}
                    value={weekRecurrenceValue}
                    insideModule={insideModule}
                    indexArrayDays={indexArrayDays}
                    disabled={disabledAgenda}
                    onChange={(value) => this.handleValue(value, 'weekRecurrence')}
                    invalidSelection={(value) => this.setInvalidSelection(value)}
                    checkCounterDays={disabledAgenda}
                    invalid={!weekRecurrence?.length && savePlan}
                    errorText={errorpicker}
                    setInvalidNumDays={this.props.setInvalidNumDays}
                  />
                )
              }
              {recurrenceType === 'MONTH' && (
                <GridItem xs={12} className="schedule-plan-group no-padding">
                  <GridItem xs={6} className="no-padding">
                    <ValidationInput
                      id="input-plan-month"
                      text={`${t('schedule.plan.repeat.month')} *`}
                      type="number"
                      onChangeValue={(value) => this.handleValue(value, 'monthRecurrence')}
                      value={monthRecurrence}
                      disabled={disabledAgenda}
                      invalid={!monthRecurrence || monthRecurrence < 1 || monthRecurrence > 31}
                      errorText={!monthRecurrence ? t('error.required') : errorInvalidMonthRecurrence}
                    />
                  </GridItem>
                </GridItem>
              )}
            </GridItem>
            { !this.props.onlyDate ?
            <GridItem xs={12} className="schedule-plan-observation no-padding">
              <TextareaInput
                id="textarea-observation"
                text={t('label.observation')}
                value={observations}
                onChangeValue={(e) => this.handleValue(e, 'observations')}
                disabled={disabledObservations}
              />
            </GridItem>
            : <></>
            }
          </GridContainer>
        </GridItem>
        {!insideModule && !this.props.onlyDate ?
          <GridItem xs={12} sm={6} className="base-font schedule-plan-finish">
            <h4 className="padding-horizontal">{t('schedule.plan.finish')}</h4>
            <RadioInput
              id="radio-finish"
              onChangeValue={(value) => this.handleValue(value, 'finish')}
              elements={finishOptions}
              value={finish ?? '1'}
              components={(id, disabled) => this.renderComponentFinish(id, disabled)}
              inputProps={{ disabled: (disabledComponent || disabledAgenda) }}
            />
          </GridItem>
          : <div />
        }
      </GridContainer>
    )
  }
}

SchedulePlan.defaultProps = {
  dates: {},
  practiceDuration: null,
}

SchedulePlan.propTypes = {
  t: PropTypes.func,
  onCreatedRecurrence: PropTypes.func,
  onCreatedObservations: PropTypes.func,
  savePlan: PropTypes.bool,
  current: PropTypes.object,
  dates: PropTypes.object,
  practiceDuration: PropTypes.number,
  invalidPlan: PropTypes.func,
  endDate: PropTypes.string,
  practiceStartDate: PropTypes.string,
  insideModule: PropTypes.bool,
  appointmentAction: PropTypes.string,
  indexArrayDays: PropTypes.array,
  errorpicker: PropTypes.string,
  disabledAgenda: PropTypes.bool,
  observations: PropTypes.string,
  appointmentStatus: PropTypes.string,
  errorEndDate: PropTypes.object,
  setInvalidNumDays: PropTypes.func,
}

export default withTranslation()(SchedulePlan);
